import React, { Component, Fragment} from 'react';
export default class PlanComperison extends Component {
    render()
    {
        return(
           <Fragment>
             <section className="plan-comparison">
              <h2 className="hidden-xs">Plan Comparison</h2>
              <h2 className="visible-xs">Plan Summary</h2>
              <h4 className="hidden-xs">Home is behind, the world ahead and there are many paths to tread through shadows to the edge.</h4>
            <div className="container">
              <div className="comparison-card">
                <div className="top-row myrow">
                  <div className="col col1 hidden-sm hidden-xs">
                      Not sure which one suits you the best?
                      <a href="/xpatquote/1" className="btn btn-brand">Get Quote</a>
                  </div>
                  <div className="col col2">
                    <h3>Bronze</h3>
                    <a href="/xpatquote/1" className="btn btn-default">Buy Now</a>
                  </div>
                  <div className="col col3">
                      <h3>Silver</h3>
                      <a href="/xpatquote/1" className="btn btn-default">Buy Now</a>
                  </div>
                  <div className="col col4">
                      <h3>Gold</h3>
                      <a href="/xpatquote/1" className="btn btn-default">Buy Now</a>
                  </div>
                  <div className="col col5">
                      <h3>Platinum</h3>
                      <a href="/xpatquote/1" className="btn btn-default">Buy Now</a>
                  </div>
                </div>

                <div className="myrow rowbold">
                  <div className="col col1">
                    Overall annual benefits
                  </div>
                  <div className="col col2 bronze">
                    $ 500, 000
                  </div>
                  <div className="col col3 silver">
                    $ 600, 000
                  </div>
                  <div className="col col4 gold">
                      $ 700, 000
                  </div>
                  <div className="col col5 platinum">
                    $ 800, 000
                  </div>
                </div>
                <div className="myrow">
                  <div className="main-row">
                    <div className="col col1">
                        Parent and Baby Care  <a className="row-toggle" href="javascript:void(0)"><img src="assets/images/icons/select-arrow.svg" alt="" /></a>
                    </div>
                    <div className="col col2 disabled bronze">
                        Not covered
                    </div>
                    <div className="col col3 silver">
                        TBD <button type="button" className="my-tooltip" data-toggle="tooltip" data-placement="top" title="Tooltip on top">i</button>
                    </div>
                    <div className="col col4 gold">
                        $ 500, 000
                    </div>
                    <div className="col col5 platinum">
                      $ 500, 000
                    </div>
                  </div> 
                  {/* ................ */}
                  <div className="inner-row" style={{display: 'none'}}>
                    <div className="row-under">
                      <div className="col col1">
                          Preventive dental care
                      </div>
                      <div className="col col2 bronze">
                          Paid in full
                      </div>
                      <div className="col col3 silver">
                          Paid in full
                      </div>
                      <div className="col col4 gold">
                          Paid in full
                      </div>
                      <div className="col col5 platinum">
                          Paid in full
                      </div>
                    </div> 
                    <div className="row-under colored">
                        <div className="col col1">
                            Major restorative dental treatment
                        </div>
                        <div className="col col2 bronze">
                            70% refund per period of cover
                        </div>
                        <div className="col col3 silver">
                            70% refund per period of cover
                        </div>
                        <div className="col col4 gold">
                            70% refund per period of cover
                        </div>
                        <div className="col col5 platinum">
                            70% refund per period of cover
                        </div>
                      </div> 
                  </div> 
                  {/* <!--inner-row--> */}
                </div>
                {/* <!--myrow--> */}
                <div className="myrow">
                  <div className="main-row">
                    <div className="col col1">
                      Inpatient & Daypatient <a className="row-toggle" href="javascript:void(0)"><img src="assets/images/icons/select-arrow.svg" alt="" /></a>
                    </div>
                    <div className="col col2 bronze">
                      $ 500, 000
                    </div>
                    <div className="col col3 silver">
                      $ 500, 000
                    </div>
                    <div className="col col4 gold">
                        $ 500, 000
                    </div>
                    <div className="col col5 platinum">
                      $ 500, 000
                    </div>
                  </div> 
                  {/* ................ */}
                  <div className="inner-row" style={{display: 'none'}}>
                    <div className="row-under">
                      <div className="col col1">
                          Preventive dental care
                      </div>
                      <div className="col col2 bronze">
                          Paid in full
                      </div>
                      <div className="col col3 silver">
                          Paid in full
                      </div>
                      <div className="col col4 gold">
                          Paid in full
                      </div>
                      <div className="col col5 platinum">
                          Paid in full
                      </div>
                    </div> 
                    <div className="row-under colored">
                        <div className="col col1">
                            Major restorative dental treatment
                        </div>
                        <div className="col col2 bronze">
                            70% refund per period of cover
                        </div>
                        <div className="col col3 silver">
                            70% refund per period of cover
                        </div>
                        <div className="col col4 gold">
                            70% refund per period of cover
                        </div>
                        <div className="col col5 platinum">
                            70% refund per period of cover
                        </div>
                      </div> 
                  </div> 
                  {/* <!--inner-row--> */}
                </div>
                {/* <!--myrow--> */}

                <div className="myrow">
                    <div className="main-row">
                      <div className="col col1">
                        Inpatient & Daypatient <a className="row-toggle" href="javascript:void(0)"><img src="assets/images/icons/select-arrow.svg" alt="" /></a>
                      </div>
                      <div className="col col2 bronze">
                        $ 500, 000
                      </div>
                      <div className="col col3 silver">
                        $ 500, 000
                      </div>
                      <div className="col col4 gold">
                          $ 500, 000
                      </div>
                      <div className="col col5 platinum">
                        $ 500, 000
                      </div>
                    </div> 
                    {/* ................ */}
                    <div className="inner-row" style={{display: 'none'}} >
                      <div className="row-under">
                        <div className="col col1">
                            Preventive dental care
                        </div>
                        <div className="col col2 bronze">
                            Paid in full
                        </div>
                        <div className="col col3 silver">
                            Paid in full
                        </div>
                        <div className="col col4 gold">
                            Paid in full
                        </div>
                        <div className="col col5 platinum">
                            Paid in full
                        </div>
                      </div> 
                      <div className="row-under colored">
                          <div className="col col1">
                              Major restorative dental treatment
                          </div>
                          <div className="col col2 bronze">
                              70% refund per period of cover
                          </div>
                          <div className="col col3 silver">
                              70% refund per period of cover
                          </div>
                          <div className="col col4 gold">
                              70% refund per period of cover
                          </div>
                          <div className="col col5 platinum">
                              70% refund per period of cover
                          </div>
                        </div> 
                        <div className="row-under colored">
                            <div className="col col1">
                                Major restorative dental treatment
                            </div>
                            <div className="col col2 bronze">
                                70% refund per period of cover
                            </div>
                            <div className="col col3 silver">
                                70% refund per period of cover
                            </div>
                            <div className="col col4 gold">
                                70% refund per period of cover
                            </div>
                            <div className="col col5 platinum">
                                70% refund per period of cover
                            </div>
                        </div> 
                    </div>
                     {/* <!--inner-row--> */}
                  </div>
                  {/* <!--myrow--> */}
              </div> 
              {/* <!--comparison-card--> */}
            </div>
          </section>

          <section className="get-quote">
            <div className="text">
                <p>Not sure which one suits you the best?</p>
                <a href="javascript:void(0)" className="btn-brand"> Get Quote</a>
            </div>
          </section>
          
          <section className="questions"></section>
           </Fragment>
               
        );
    }
}