import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur, handleChangeRadio } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
export default class XPQ5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'currently_live_usa' : '',
            'street' : '',
            'city' : '',
            'zipcode' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ currently_live_usa : localStorageData[this.props.questionNum].currently_live_usa});
            this.setState({ street : localStorageData[this.props.questionNum].street});
            this.setState({ city : localStorageData[this.props.questionNum].city});
            this.setState({ zipcode : localStorageData[this.props.questionNum].zipcode});
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = {
                'currently_live_usa' : this.state.currently_live_usa,
                'street' : this.state.street,
                'city' : this.state.city,
                'zipcode' : this.state.zipcode
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleNo = () =>{
        this.setState({currently_live_usa : 'No'});
        let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = {
                'currently_live_usa' : 'No'
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
    }
    handleYes = () => {
        this.setState({currently_live_usa : 'Yes'});
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner">
                        <h2 className="main">Do you currently live in the USA?</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                {this.validator.message('currently_live_usa', this.state.currently_live_usa, 'required')}
                            </div>
                            <div className="small-width-container">
                                <div className="form-group small-width width-xs">
                                    <a className={this.state.currently_live_usa === 'No' ? 'btn btn-default' : 'btn btn-primary'}  onClick={this.handleNo}>No</a>
                                </div>
                                <div className="form-group small-width width-xs">
                                    <a className={this.state.currently_live_usa === 'Yes' ? 'btn btn-default' : 'btn btn-primary'} onClick={this.handleYes}>Yes</a>
                                </div>
                            </div>
                            {this.state.currently_live_usa === 'Yes' ? 
                            <Fragment>
                                <div className="hidden-md inner"></div>
                                <h2 className="main">What is your address in the USA?</h2>
                                <div className="small-width-container">
                                    <div className="form-group small-width">
                                        <label>Street</label>
                                        <input type="text" className="form-control" value={this.state.street} onChange={this.handleChange} autoFocus={true} name="street" placeholder="Street" autoComplete="off"></input>
                                    </div>
                                    <div className="form-group small-width">
                                        <label>City</label>
                                        <input type="text" className="form-control" value={this.state.city} onChange={this.handleChange} name="city" placeholder="City" autoComplete="off"></input>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Zipcode</label>
                                    <input type="text" className="form-control" value={this.state.zipcode} onChange={this.handleChange} name="zipcode" placeholder="Zipcode" autoComplete="off"></input>
                                </div>
                                <div className="clearfix"></div>
                                <NextButton btntext="Next"/>
                                <div className="space_section"></div>
                            </Fragment>
                        : ''}
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
} 