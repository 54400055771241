import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
export default class XPQ9 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'smoke' : '',
            'how_many_smoke' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum] && localStorageData[this.props.questionNum].smoke) {
            this.setState({ smoke: localStorageData[this.props.questionNum].smoke });
            this.setState({ how_many_smoke: localStorageData[this.props.questionNum].how_many_smoke });
        }
    }
    handleSubmit = (event) => {
        if(event){
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 
                'smoke': this.state.smoke,
                'how_many_smoke' : this.state.how_many_smoke
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleNo = () =>{
        this.setState({smoke : 'No'});
        let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = {
                'smoke' : 'No'
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
    }
    handleYes = () => {
        this.setState({smoke : 'Yes'});
    }

    handleHowManySmoke(number, event)
    {
        if(number === '1')
            this.setState({how_many_smoke : 'Less than 20 per day'});
        else
            this.setState({how_many_smoke : 'More than 20 per day'});
        
    }


    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
            <div className="quote-page">
                <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                <div className="inner">
                    <h2 className="main">Have you smoked, used tobacco or nicotine replacement products in the last 12 months?</h2>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            {this.validator.message('smoke', this.state.smoke, 'required')}
                        </div>
                        <div className="small-width-container">
                            <div className="form-group small-width">
                                <a className={this.state.smoke === 'No' ? 'btn btn-default' : 'btn btn-primary'}  onClick={this.handleNo}>No</a>
                            </div>
                            <div className="form-group small-width">
                                <a className={this.state.smoke === 'Yes' ? 'btn btn-default' : 'btn btn-primary'} onClick={this.handleYes}>Yes</a>
                            </div>
                        </div>
                        {this.state.smoke === 'Yes' ? 
                        <Fragment>
                            <div className="space_section"></div>
                            <h2 className="main">How many per day?</h2>
                            <div className="small-width-container">
                                <div className="form-group small-width">
                                    <a className={this.state.how_many_smoke === 'Less than 20 per day' ? 'btn btn-default male plr-0' : 'btn btn-primary male plr-0'} onClick={this.handleHowManySmoke.bind(this,'1')}>Less than 20 per day</a>
                                </div>
                                <div className="form-group small-width">
                                    <a className={this.state.how_many_smoke === 'More than 20 per day' ? 'btn btn-default female plr-0' : 'btn btn-primary female plr-0'} onClick={this.handleHowManySmoke.bind(this,'2')}>More than 20 per day</a>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <NextButton btntext="Next"/>
                            <div className="space_section"></div>
                        </Fragment>
                    : ''}
                    </form>
                </div>
            </div>
            </Fragment>
        );
    }
} 