import React, { Component, Fragment} from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
export default class XPQ10 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'quote_any_else' : '',
            'no_of_relation' : 0,
            'relation' : '',
            'countries' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));

        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_countries_ajax',
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            if (result.data.success === true){
                this.setState({countries : result.data.countries});
            }
        })
        .catch(error => this.setState({ error: error.message }));

        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ quote_any_else: localStorageData[this.props.questionNum].quote_any_else });
            this.setState({no_of_relation : localStorageData[this.props.questionNum].no_of_relation });
            const length = localStorageData[this.props.questionNum].no_of_relation;
            for(var i=0; i < length; i++)
            {

                const first_name = 'first_name['+ i +']';
                const last_name = 'last_name['+ i +']';
                const dob = 'dob['+ i +']';
                const relation_name = 'relation[' + i + ']';
                const nationality = 'nationality['+ i +']';
                const destination_country = 'destination_country['+ i +']';

                this.setState({ [first_name] : localStorageData[this.props.questionNum][first_name]});
                this.setState({ [last_name] : localStorageData[this.props.questionNum][last_name]});
                this.setState({ [dob] : localStorageData[this.props.questionNum][dob]});
                this.setState({ [relation_name] : localStorageData[this.props.questionNum][relation_name]});
                this.setState({ [nationality] : localStorageData[this.props.questionNum][nationality]});
                this.setState({ [destination_country] : localStorageData[this.props.questionNum][destination_country]});
            }
        }
    }
    handleAddRelation = (event) => {
        this.setState({no_of_relation : ++this.state.no_of_relation});
    }
    handleSubmit = (event) => {
        if(event){
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            var base_url = process.env.REACT_APP_API_URL;
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            // const forms_zip = { 
            //     'quote_any_else': this.state.quote_any_else,
            //     'relation' : []
            // };
            localStorageData[this.props.questionNum] = this.state;
        //    if(this.state.quote_any_else === 'No')
        //    {
//                axios({
//                    method: "post",
//                    url: base_url + 'leads/xpat_quote_ajax',
//                    data : {"dataArray" : localStorageData},
//                    headers: { "content-type": "application/json" },
//                })
//                .then(result => {
//                    if (result.data.success === true){
//                        alert("Data posted successfully");
//                        localStorage.removeItem('xpatquote');
//                        this.setState({redirectToReferrer : true});
//                    }
//                })
//                .catch(error => this.setState({ error: error.message }));

                // this.setState({no_of_relation : "0" });
                // this.setState({relation : [] });
 
               
        //    }
        //    else
        //    {
                if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
                localStorageData = JSON.stringify(localStorageData);
                localStorage.setItem('xpatquote', localStorageData);
                this.setState({ redirectToReferrer: true });   
          // }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleQuote(quote, event)
    {
        event.preventDefault();
        this.setState({quote_any_else : quote});
    setTimeout(function()
     {
        if(this.state.quote_any_else === 'No')
           {
            this.setState({no_of_relation :0});
           }
        }.bind(this),5);

    }


    render() {

        var countries = [];
        let index = 0;
        var length = this.state.countries.length;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index]['country']}>{this.state.countries[index]['country']}</option>
                </Fragment>
            );
            index++;
        }

        var indents = [];
        let index_number = 0;
        for (var i = 1; i <= this.state.no_of_relation; i++) {
            const relation_name = 'relation[' + index_number + ']';
            const first_name = 'first_name['+ index_number +']';
            const last_name = 'last_name['+ index_number +']';
            const dob = 'dob['+ index_number +']';
            const nationality = 'nationality['+ index_number +']';
            const destination_country = 'destination_country['+ index_number +']';
            indents.push(
                <Fragment key={i}>
                    <h2 className="main">Person {i}</h2>
                    <div className="small-width-container">
                        <div className="form-group small-width">
                            <label>First Name</label>
                            <input type="text" className="form-control" placeholder="First Name" name={first_name} value={this.state[first_name]} onChange={this.handleChange} autoFocus={true} onBlur={this.handleBlur}/>
                        </div>
                        <div className="form-group small-width">
                            <label>Last Name</label>
                            <input type="text" className="form-control" placeholder="Last Name" name={last_name} value={this.state[last_name]} onChange={this.handleChange} onBlur={this.handleBlur}/>
                        </div>
                    </div>
                    <div className="small-width-container">
                        <div className="form-group small-width">
                            <label>DOB</label>
                            <input type="date" className="form-control" value={this.state[dob]} name={dob} placeholder="DOB" onChange={this.handleChange} />
                        </div>
                        <div className="form-group small-width">
                            <label>Relation</label>
                            <input type="text" className="form-control" value={this.state[relation_name]} name={relation_name} placeholder="Relation" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="small-width-container">
                        <div className="form-group small-width">
                            <label>Primary Passport</label>
                            <div className="select-div">
                                <select className="form-control" name={nationality} onChange={this.handleChange} value={this.state[nationality]} onBlur={this.handleBlur}>
                                    <option>Country</option>
                                    {countries}
                                </select>
                            </div>
                        </div>
                        <div className="form-group small-width">
                            <label>Destination Country</label>
                            <div className="select-div">
                                <select className="form-control" name={destination_country} onChange={this.handleChange} value={this.state[destination_country]} onBlur={this.handleBlur}>
                                    <option>Country</option>
                                    {countries}
                                </select>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
            index_number++;
        }
        if (this.state.redirectToReferrer){
//            if(this.state.quote_any_else === 'No')
//            {
//                return (<Redirect to={"/xpatquote/"+1} />)
//            }
//            else
//            {
                const next = parseInt(this.props.questionNum)+parseInt(1);
                return (<Redirect to={"/xpatquote/"+next} />)
//            }
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner">
                        <h2 className="main">Would you like to get a quote for anyone else?</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                {this.validator.message('quote_any_else', this.state.quote_any_else, 'required')}
                            </div>
                            <div className="small-width-container">
                                <div className="form-group small-width">
                                    <a className={this.state.quote_any_else === 'No' ? 'btn btn-default' : 'btn btn-primary'}  onClick={this.handleQuote.bind(this,'No')}>No</a>
                                </div>
                                <div className="form-group small-width">
                                    <a className={this.state.quote_any_else === 'Yes' ? 'btn btn-default' : 'btn btn-primary'} onClick={this.handleQuote.bind(this,'Yes')}>Yes</a>
                                </div>
                            </div>
                            {this.state.quote_any_else === 'Yes' ?
                                <Fragment>
                                    {indents}
                                    
                                    <button className="btn btn-default btn-block mb20 active" type="button" onClick={this.handleAddRelation}>Add Person</button>
                                    
                                    <div className="space_section"></div>
                                </Fragment>
                            : ''}
                            <NextButton btntext="Next"/>
                        </form>
                    </div>
                    <div className="inner"></div>
                </div>
            </Fragment>
        );
    }
} 