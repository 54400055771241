import React, {Fragment, useState} from 'react';
import Header from '../common/component/Header';
import Footer from '../common/component/Footer';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import Loader from '../../loader/Loader';

 export default  function Contact(props) {
    
        const [loader, setLoader] = useState(false);
        const handleUpdateLoader = (ajaxState) =>{
         setLoader(ajaxState);
       }    
        return(
            <Fragment>
            <Loader isLoading={loader}></Loader>
            <div className="wrapper">
                 <div className="affiliates contact"> 
                <Header></Header>
                <ContactForm functionLoader={{ handleUpdateLoader:handleUpdateLoader  }}></ContactForm>
                <ContactInfo></ContactInfo>
                <Footer></Footer>
                </div>
            </div>
            </Fragment>
        );
}