import React, { Component, Fragment,  useState} from 'react';
import './App.css';

import { Route, Router, Switch } from 'react-router-dom';
import QuestionContainerXPQ from './pages/business/component/QuestionContainerXPQ';
import QuestionContainerAccount from './pages/account/component/QuestionContainerAccount';
import Home from './pages/home/Home';
import ContactUs from './pages/contactUs/Contact';
import Article from './pages/articles/Article';
import AboutUs from './pages/aboutUs/About';
import Faq from './pages/faq/Faq';
import Blog from './pages/blog/blog';
import Partner from './pages/partner/partner';
import Products from './pages/products/Product';
import Loader from './loader/Loader';


function App() {
  return (
    <div className="App">
      
      
    <Switch>
      <Route exact path="/" component={(props) => <Home {...props} />} />
      <Route exact path="/contact" component={ContactUs} />
      <Route exact path="/article" component={Article} />
      <Route exact path="/about" component={AboutUs} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/partner" component={Partner} />
      <Route exact path="/products" component={Products} />
     
      <Route exact path="/xpatquote/:num/:genreId?" component={(props) => <QuestionContainerXPQ {...props} />} />
      <Route exact path="/create-account/:num" component={(props) => <QuestionContainerAccount {...props} />} />
    </Switch>
    </div>
  );
}

export default App;
