import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import NextButton from '../../../business/component/NextButton';
import { handleChange, handleBlur } from '../../../common_function/common_function';
import SimpleReactValidator from 'simple-react-validator';
import BackButton from '../../../business/component/BackButton';
export default class CA2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            'first_name': '',
            'last_name': '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const localStorageData = JSON.parse(localStorage.getItem('create-account'));
        if (localStorageData && localStorageData[this.props.questionNum]) {

            this.setState({ first_name: localStorageData[this.props.questionNum].first_name });
            this.setState({ last_name: localStorageData[this.props.questionNum].last_name });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            var forms_info = { 
                'first_name': this.state.first_name,
                'last_name': this.state.last_name
            };
            if(localStorage.getItem('create-account')){
                let localStorageData = JSON.parse(localStorage.getItem('create-account'));
                localStorageData[this.props.questionNum] = forms_info;
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
                localStorageData = JSON.stringify(localStorageData);
                localStorage.setItem('create-account', localStorageData);
            }
            else
            {
                var url_forms_info = { 'current_step': 2 ,'1': forms_info };
                url_forms_info = JSON.stringify(url_forms_info);
                localStorage.setItem('create-account', url_forms_info);
            }
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/create-account/"+next} />)
        }
        return (
            <div className="col-md-7 col-sm-12 right">
                <div className="login-logo visible-sm visible-xs">
                    <a className="navbar-brand" href="/"><img src="/assets/images/logo-login-blue2.svg" alt=""></img></a>
                </div>
                <div className="clearfix"></div>
                <h3>Create Xpat account</h3>
                <p>Create account to start using the full range of advantages today.  </p>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        {this.validator.message('first_name', this.state.first_name, 'required')}
                        {this.validator.message('last_name', this.state.last_name, 'required')}
                    </div>
                    <div className="small-width-container">
                        <div className="form-group small-width">
                            <label>What is your first name?</label>
                            <input type="text" className="form-control" placeholder="First Name" name="first_name" value={this.state.first_name} onChange={this.handleChange} autoFocus={true} onBlur={this.handleBlur}/>
                        </div>
                        <div className="form-group small-width">
                            <label>What is your last name?</label>
                            <input type="text" className="form-control" placeholder="Last Name" name="last_name" value={this.state.last_name} onChange={this.handleChange} onBlur={this.handleBlur}/>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <button type="submit" className={this.state.first_name !== '' && this.state.last_name !== '' ? 'btn btn-brand btn-block'  : 'btn btn-brand btn-block btn-disabled'}>Next</button>
              </form>
            </div>
        );
    }
}