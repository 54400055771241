import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur, handleChangeRadio } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
export default class XPQ8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'feet' : '',
            'inches' : '',
            'weight_type' : 'pounds',
            'kgs_or_lbs' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({'feet' : localStorageData[this.props.questionNum].feet});
            this.setState({'inches' : localStorageData[this.props.questionNum].inches});
            this.setState({'weight_type' : localStorageData[this.props.questionNum].weight_type});
            this.setState({'kgs_or_lbs' : localStorageData[this.props.questionNum].kgs_or_lbs});
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 
                'feet': this.state.feet,
                'inches' : this.state.inches,
                'weight_type' : this.state.weight_type,
                'kgs_or_lbs' : this.state.kgs_or_lbs
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleWeight(selector,event) {
        event.preventDefault();
        if(selector == 'pounds')
        {
            //Add
            var element = document.getElementById("pounds");
            element.classList.add("active");
            var element2 = document.getElementById("pounds_li");
            element2.classList.add("active");

            //Remove
            var element3 = document.getElementById("kilograms");
            element3.classList.remove("active");
            var element4 = document.getElementById("kilograms_li");
            element4.classList.remove("active");
            
            this.setState({'kgs_or_lbs' : ''});
            this.setState({'weight_type' : 'pounds'});
            
        }
        else if(selector == 'kilograms')
        {
            var element = document.getElementById("kilograms");
            element.classList.add("active");
            var element2 = document.getElementById("kilograms_li");
            element2.classList.add("active");

            //Remove
            var element3 = document.getElementById("pounds");
            element3.classList.remove("active");
            var element4 = document.getElementById("pounds_li");
            element4.classList.remove("active");

            this.setState({'kgs_or_lbs' : ''});
            this.setState({'weight_type' : 'kilograms'});
        }

    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner">
                        <h2 className="main">What is your height and weight?</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                {this.validator.message('feet', this.state.feet, 'required')}
                                {this.validator.message('inches', this.state.inches, 'required')}
                                {this.validator.message('weight', this.state.kgs_or_lbs, 'required')}
                            </div>
                            <div className="small-width-container">
                                <div className="form-group small-width">
                                    <label>Feet</label>
                                    <input type="text" className="form-control" value={this.state.feet} onChange={this.handleChange} autoFocus={true} name="feet" placeholder="Feet" autoComplete="off"></input>
                                </div>
                                <div className="form-group small-width">
                                    <label>Inches</label>
                                    <input type="text" className="form-control" value={this.state.inches} onChange={this.handleChange} name="inches" placeholder="Inches" autoComplete="off"></input>
                                </div>
                            </div>
                            <div className="tabbable-panel">
                                <div className="tabbable-line">
                                    <ul className="nav nav-tabs ">
                                        <li id="pounds_li" className={this.state.weight_type === 'pounds' ? 'active' : ''}>
                                            <a data-toggle="tab" onClick={this.handleWeight.bind(this,'pounds')} >Pounds</a></li>
                                        <li id="kilograms_li" className={this.state.weight_type === 'kilograms' ? 'active' : ''}>
                                            <a data-toggle="tab" onClick={this.handleWeight.bind(this,'kilograms')}>Kilograms</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className={this.state.weight_type === 'pounds' ? 'tab-pane active' : 'tab-pane'} id="pounds">
                                            <div className="form-group">
                                                <input type="text" className="form-control" value={this.state.kgs_or_lbs} onChange={this.handleChange} name="kgs_or_lbs" placeholder="Pounds" autoComplete="off"></input>
                                            </div>
                                        </div>
                                        <div className={this.state.weight_type === 'kilograms' ? 'tab-pane active' : 'tab-pane'} id="kilograms">
                                            <div className="form-group">
                                                <input type="text" className="form-control" value={this.state.kgs_or_lbs} onChange={this.handleChange} name="kgs_or_lbs" placeholder="Kilograms" autoComplete="off"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <NextButton btntext="Next"/>
                            <div className="space_section"></div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
} 