import React, { Component, Fragment } from 'react'
import NextButton from '../../../business/component/NextButton';
import { handleChange, handleBlur } from '../../../common_function/common_function';
import BackButton from '../../../business/component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
export default class CA7 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'why_partner' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const localStorageData = JSON.parse(localStorage.getItem('create-account'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ why_partner: localStorageData[this.props.questionNum].why_partner });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('create-account'));
            this.props.functionLoader.handleUpdateLoader(true);
            const forms_bus_name = { 
                'why_partner' : this.state.why_partner
            };
            localStorageData[this.props.questionNum] = forms_bus_name;
            var base_url = process.env.REACT_APP_HOME_API_URL;
            axios({
                method: "post",
                url: base_url + 'users/affiliate_signup_ajax',
                data : {"dataArray" : localStorageData},
                headers: { "content-type": "application/json" },
            })
            .then(result => {
                this.props.functionLoader.handleUpdateLoader(false);
                if (result.data.success === true){
                    alert("Data posted successfully");
                    localStorage.removeItem('create-account');
                    this.setState({redirectToReferrer : true});
                }
            })
            .catch(error => this.setState({ error: error.message }));

            // if(this.props.questionNum >= localStorageData.current_step)
            //     localStorageData.current_step = parseInt(this.props.questionNum)+1;
            // localStorageData = JSON.stringify(localStorageData);
            // localStorage.setItem('create-account', localStorageData);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (this.state.redirectToReferrer){
            alert('Data saved successfully');
            return (<Redirect to={"/create-account/"+1} />)
        }
        return (
            <div class="col-md-7 col-sm-12 right">
                <div class="login-logo visible-sm visible-xs">
                    <a class="navbar-brand" href="/"><img src="assets/images/logo-login-blue2.svg" alt=""></img></a>
                </div>
                <div class="clearfix"></div>
                <h3>Create Xpat account</h3>
                <p>Create account to start using the full range of advantages today.  </p>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        {this.validator.message('why_partner', this.state.why_partner, 'required')}
                    </div>
                    <div class="form-group">
                        <label>why would you like to partner with XpatCoverage?</label> 
                        <input type="text" className="form-control" placeholder="Surprise us" value={this.state.why_partner}  name="why_partner" autoFocus={true} onChange={this.handleChange} onBlur={this.handleBlur}/>
                    </div>
                    <button type="submit" className={this.state.why_partner !== '' ? 'btn btn-brand btn-block'  : 'btn btn-brand btn-block btn-disabled'}>Next</button>
                </form>
            </div>
        );
    }
} 