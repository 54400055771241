import React, { Component, Fragment } from 'react'
import NextButton from '../../../business/component/NextButton';
import { handleChange, handleBlur } from '../../../common_function/common_function';
import BackButton from '../../../business/component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
export default class CA3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'licence_number' : '',
            'licence_state' : '',
            'states' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const localStorageData = JSON.parse(localStorage.getItem('create-account'));

        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'states/get_us_states',
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            if (result.data.success === true){
                this.setState({states : result.data.states});
            }
        })
        .catch(error => this.setState({ error: error.message }));


        if (localStorageData && localStorageData[this.props.questionNum]) {

            this.setState({ licence_number: localStorageData[this.props.questionNum].licence_number });
            this.setState({ licence_state: localStorageData[this.props.questionNum].licence_state });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('create-account'));
            const forms_bus_name = { 
                'licence_number' : this.state.licence_number,
                'licence_state': this.state.licence_state
            };
            localStorageData[this.props.questionNum] = forms_bus_name;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('create-account', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        var states = [];
        let index_number = 0;
        var length = this.state.states.length;
        for (var i = 1; i <= length; i++) {
            states.push(
                <Fragment key={i}>
                    <option value={this.state.states[index_number]['name']}>{this.state.states[index_number]['name']}</option>
                </Fragment>
            );
            index_number++;
        }
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/create-account/"+next} />)
        }
        return (
            <div className="col-md-7 col-sm-12 right">
                <div className="login-logo visible-sm visible-xs">
                    <a className="navbar-brand" href="/"><img src="/assets/images/logo-login-blue2.svg" alt=""></img></a>
                </div>
                <div className="clearfix"></div>
                <h3>Create Xpat account</h3>
                <p>Create account to start using the full range of advantages today.  </p>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        {this.validator.message('licence_number', this.state.licence_number, 'required')}
                        {this.validator.message('licence_state', this.state.licence_state, 'required')}
                    </div>
                    <div className="form-group">
                        <label>What is your Licence Number</label> 
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Licence Number" value={this.state.licence_number}  name="licence_number" onChange={this.handleChange} onBlur={this.handleBlur}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>What is your Licence State?</label> 
                        <div className="select-div">
                            <select className="form-control" name="licence_state" onChange={this.handleChange} value={this.state.licence_state} onBlur={this.handleBlur}>
                                <option>State</option>
                                {states}
                            </select>
                        </div>
                    </div>
                    <button type="submit" className={this.state.licence_number !== '' && this.state.licence_state !== '' ? 'btn btn-brand btn-block'  : 'btn btn-brand btn-block btn-disabled'}>Next</button>
                </form>
            </div>
        );
    }
} 