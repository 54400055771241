import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas'

export default class XPQ16 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'e_signature' : '',
            'data_url' : null,
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    sigPad = {};

    clear = () => {
        this.sigPad.clear();
    }
    trim = () => {
        this.setState({data_url: this.sigPad.toDataURL('image/png')})
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ data_url: localStorageData[this.props.questionNum].e_signature });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            this.props.functionLoader.handleUpdateLoader(true);

            var base_url = process.env.REACT_APP_API_URL;
            var pending_xpat_id;
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            if(localStorage.getItem('pending_xpat_id')){
             pending_xpat_id = JSON.parse(localStorage.getItem('pending_xpat_id'));
            }
            const forms_zip = { 
                'e_signature': this.state.data_url,
                'pending_quote_status' :'completed',
                'pending_xpat_id' : JSON.stringify(pending_xpat_id),
            };
            localStorageData[this.props.questionNum] = forms_zip;

            axios({
                method: "post",
                url: base_url+'leads/xpat_quote_ajax',
                data : {"dataArray" : localStorageData},
                headers: { "content-type": "application/json" },
            })
            .then(result => {
                if (result.data.success === true){
                    alert("Data posted successfully");
                    localStorage.removeItem('xpatquote');

                    if(localStorage.getItem('pending_xpat_id')){
                       localStorage.removeItem('pending_xpat_id');
                    }

                    this.setState({redirectToReferrer : true});
                }
                this.props.functionLoader.handleUpdateLoader(false);
            })
            .catch(error => this.setState({ error: error.message }));
            // localStorageData.current_step = parseInt(this.props.questionNum)+1;
            // localStorageData = JSON.stringify(localStorageData);
            // localStorage.setItem('xpatquote', localStorageData);
            // this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handlePlanSelection(plan, event){
        this.setState({e_signature : plan});
    }
    render() {
        if (this.state.redirectToReferrer){
            return (<Redirect to={"/xpatquote/"+1} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner">
                        <h2 className="main">E-Signature</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                {this.validator.message('signature', this.state.data_url, 'required')}
                            </div>
                           <div className="form-group" style={{ border: '2px solid #D3D3D3'}} >
                            <SignatureCanvas penColor='green'
                            canvasProps={{width: 500, height: 200, backgroundColor : 'rgb(192,192,192)', className: 'sigCanvas'}}  ref={(ref) => { this.sigPad = ref }} />
                            </div>
                          
                            <div className="small-width-container">
                                <div className="form-group small-width">
                                <a className="btn btn btn-default e-signature_button"  onClick={this.clear}>Clear</a>
                                </div>
                                <div className="form-group small-width">
                                <a className="btn btn-default e-signature_button select-btn-left" onClick={this.trim}>Select</a>
                                </div>
                            </div>
                                <NextButton btntext="Finish" />
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
} 