import React, { Component} from 'react';
export default class ExpatHealthInfo extends Component {
    render()
    {
        return(
            <section className="first-section">
                <div className="container">
                  <div className="left">
                    <h1>Expat Health  Insurance</h1>
                      <p>Get your global health insurance and always feel at home.</p>
                      <a href="/xpatquote/1" className="btn btn-brand" >Get Quote</a>
                  </div>
                  <div className="right">
                      <div className="column hidden-xs">
                          <img className="img-responsive hidden" src="assets/images/section-1-1.jpg" alt=""></img>
                        </div>
                        <div className="column">
                          <img className="visible-lg img-responsive" src="assets/images/section-1-2.jpg" alt=""></img>
                          <img className="visible-md visible-sm img-responsive" src="assets/images/section-1-6.jpg" alt=""></img>
                          <img className="visible-xs img-responsive" src="assets/images/section-1-5.jpg" alt=""></img>
                          <img className="img-responsive" src="assets/images/section-1-3.jpg" alt=""></img>
                          <img className="visible-lg img-responsive" src="assets/images/section-1-4.jpg" alt=""></img>
                        </div>
                        <div className="column visible-lg">
                            <img className="visible-lg img-responsive" src="assets/images/section-1-5.jpg" alt=""></img>
                            <img className="visible-lg img-responsive" src="assets/images/section-1-6.jpg" alt=""></img>
                          </div>
                        <div className="column">
                          <img className="img-responsive" src="assets/images/section-1-7.jpg" alt=""></img>
                          <img className="visible-xs img-responsive" src="assets/images/section-1-6.jpg" alt=""></img>
                          <img className="img-responsive hidden" src="assets/images/section-1-1.jpg" alt=""></img>
                          <img className="hidden-lg hidden-xs img-responsive" src="assets/images/section-1-5.jpg" alt=""></img>
                          <img className="visible-lg img-responsive" src="assets/images/section-1-8.jpg" alt=""></img>
                        </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </section>
        );
    }
}