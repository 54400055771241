import React, { Component, Fragment} from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

export default class XPQ15A extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'email' : '',
            'phone' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ email: localStorageData[this.props.questionNum].email });
            this.setState({ phone: localStorageData[this.props.questionNum].phone });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 
                'email': this.state.email,
                'phone' : this.state.phone
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handlePlanSelection(plan, event){
        this.setState({email : plan});
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner">
                        <h2 className="main">Thanks for getting a quote with Xpat Coverage! </h2>
                        <h3>Your quote will be ready in 1-3 business days, please enter your email & phone number and we will contact you when it is ready!</h3>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                {this.validator.message('email', this.state.email, 'required|email')}
                                {this.validator.message('phone', this.state.phone, 'required')}
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={this.state.email} onChange={this.handleChange} autoFocus={true} name="email" placeholder="Email" autoComplete="off" onBlur={this.handleBlur}></input>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={this.state.phone} onChange={this.handleChange} name="phone" placeholder="+1 (XXX) XXX XX XX" autoComplete="off" onBlur={this.handleBlur}></input>
                            </div>
                            <div className="form-group">
                                <NextButton btntext="Next" />
                            </div>
                            <ul className="links-after-button">
                                <li><a href="#">How we use your information?</a></li>
                                <li><a href="#">Declaration for All Customers and Fraud Notice sections</a></li>
                            </ul>     
                        </form>
                    </div>
                    <div className="inner"></div>
                </div>
            </Fragment>
        );
    }
} 