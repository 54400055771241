import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
export function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  }
  const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
      color: '#475665',
      minWidth: '30',
    },
    button:{
        backgroundColor : 'none',
    },
    tooltip: {
      backgroundColor: '#475665',
      fontSize: '14px',
      'maxWidth' : 220,
      'textAlign' : "center",
      'fontWeight' : '10',
    },
  }));
export function handleChange (event)
{
    event.preventDefault();
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
        [name] : value
    });
}

export const focusInput = (component) => {
  if (component) {
      component.focus();
  }
};

export function handleBlur (event) {
    event.preventDefault();
    const name = event.target.name;
    this.validator.showMessageFor(name);
    var element = document.getElementsByName(name);
    if(this.validator.fieldValid(name))
        element[0].classList.remove("error-border");    
    else
        element[0].classList.add("error-border");
    this.forceUpdate();

}

export function handleChangeRadio (event)
{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
        [name] : value
    });
}

export function handleChangeCheckBox (event)
{
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(value === true)
    {
        value=target.value;
    }
    else
    {
        value=false;
    }
    this.setState({
        [name] : value
    });
}

export function handleChangeAmount (event) {
    event.preventDefault();
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    var doller_sign = value.replace(/\$/g, '');
    var result = doller_sign.replace(/,/g, "");
    value = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    value = '$'+value;
    this.setState({
        [name] : value
    });
}