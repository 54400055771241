import React, { Component} from 'react';
export default class secondSection extends Component {
    render()
    {
      
        return(
        <section className="main-section">
            <div className="container">
                <div className="text-container">
                  <h2 className="with-underline">Affiliates</h2>
                  <h4>Why partner with Xpat Coverage? </h4>
                  <p>Partnering with Xpat coverage allows you to offer international travelers and expatriates a top notch medical insurance plan for their extended travels. For every traveler you refer who signs up and qualifies for a quote, you get paid!</p>
                  <a href="/create-account/1" className="btn btn-brand">Sign Up</a>
                </div>
                <div className="video">
                  <img className="img-responsive" src="assets/images/affiliates-right-image.png" alt=""></img>
                </div>
                <div className="clearfix"></div>
            </div>
  
          </section>
      
        );
    }
}