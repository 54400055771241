import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import NextButton from '../../../../component/NextButton';
import { handleChange, BootstrapTooltip, handleBlur } from '../../../../../common_function/common_function';
import SimpleReactValidator from 'simple-react-validator';
import BackButton from '../../../../component/BackButton';
import axios from 'axios';
export default class XPQ16A extends Component {
    constructor(props) {
        super(props)
        this.state = {
            'email': '',
            'phone': '',
            'card_first_name': '',
            'billing_address': '',
            'payment_house': '',
            'zip_code': '',
            'card_number': '',
            'cvv': '',
            'card_exp_date': '',
            'payment_id': '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const prev = parseInt(this.props.questionNum)-(1);
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            // let email = localStorageData['17'].email;
            // let phone = localStorageData['17'].phone;
            // this.setState({ email : email });
            // this.setState({ phone : phone });
        
    }
    handleEvent = (event) => {
        this.setState({card_first_name : event});
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            this.props.functionLoader.handleUpdateLoader(true);
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            if(localStorage.getItem('xpatquote')){

            var base_url = process.env.REACT_APP_API_URL;
            var forms_info = { 
                'email': this.state.email,
                'phone': this.state.phone,
                'card_first_name': this.state.card_first_name,
                'billing_address': this.state.billing_address,
                'payment_house': this.state.payment_house,
                'zip_code': this.state.zip_code,
                'card_number': this.state.card_number,
                'cvv': this.state.cvv,
                'card_exp_date': this.state.card_exp_date


            };

                          
       axios({
                method: "post",
                url: base_url + 'leads/xpat_payment_ajax',
                data : {"paymentArray" : forms_info},
                headers: { "content-type": "application/json" },
            })
            .then(result => {
                if (result.data.status === true){
                    this.props.functionLoader.handleUpdateLoader(false);


                    this.setState({ payment_id: result.data.pxrID });

                    const forms_pay_id = { 'payment_id': this.state.payment_id };
                    localStorageData[this.props.questionNum] = forms_pay_id;
                    localStorageData.current_step = parseInt(this.props.questionNum)+1;
                    localStorageData = JSON.stringify(localStorageData);
                    localStorage.setItem('xpatquote', localStorageData);
                  
                    this.setState({redirectToReferrer : true});
                }else{
                    alert(result.data.message);
                }
            })
            .catch(error => this.setState({ error: error.message
             }
             ));
            
             
            }
            else
            {
                if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
                localStorageData = JSON.stringify(localStorageData);
                localStorage.setItem('xpatquote', localStorageData);
                this.setState({ redirectToReferrer: true });   
            }
            //this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
           return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <div className="quote-page">
                <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                <div className="inner">
                    <h2 className="main">Fill Up Credit Card Detail</h2>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            {this.validator.message('card_first_name', this.state.card_first_name, 'required')}
                            {this.validator.message('card_number', this.state.card_number, 'required')}
                        </div>
                        <div className="small-width-container">
                            <div className="form-group">
                                <label>First Name (On The Card)</label>
                                <input type="text" className="form-control" placeholder="First Name" name="card_first_name" value={this.state.card_first_name} onChange={this.handleChange} autoFocus={true} onBlur={this.handleBlur}/>
                            </div>
                            <div className="clearfix"></div>
                            </div>
                            <div className="small-width-container">
                            <div className="form-group">
                                <label>Billing Address</label>
                                <input type="text" className="form-control" placeholder="Billing Address" name="billing_address" value={this.state.billing_address} onChange={this.handleChange} onBlur={this.handleBlur}/>
                            </div>
                            <div className="clearfix"></div>
                            </div>
                            <div className="small-width-container">
                            <div className="form-group small-width">
                                <label>House</label>
                                <input type="text" className="form-control" placeholder="House" name="payment_house" value={this.state.payment_house} onChange={this.handleChange} onBlur={this.handleBlur}/>
                            </div>
                            <div className="form-group small-width">
                                <label>Zip Code</label>
                                <input type="text" className="form-control" placeholder="Zip Code" name="zip_code" value={this.state.zip_code} onChange={this.handleChange} onBlur={this.handleBlur}/>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="small-width-container">
                            <div className="form-group">
                                <label>Card Number</label>
                                <input type="text" className="form-control" placeholder="Card Number" name="card_number" value={this.state.card_number} onChange={this.handleChange} onBlur={this.handleBlur}/>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="small-width-container">
                            <div className="form-group small-width">
                                <label>CVV</label>
                                <input type="text" className="form-control" placeholder="CVV" name="cvv" value={this.state.cvv} onChange={this.handleChange} onBlur={this.handleBlur}/>
                            </div>
                            <div className="form-group small-width">
                                <label>Exp. Date</label>
                                <input type="date" className="form-control" placeholder="Exp. Date" name="card_exp_date" value={this.state.card_exp_date} onChange={this.handleChange} onBlur={this.handleBlur}/>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <NextButton btntext="Next"/>
                        <div className="space_section"></div>
                    </form>
                </div>
            </div>
        );
    }
}