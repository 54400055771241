import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import ProgressBar from 'react-dennis-progressbar';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
class BackButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			'totalQuestion': this.props.totalQuestion,
			'lskey': this.props.lsKey,
			'redirectUrl': '',
			'progress_bar_width' : (100 / this.props.totalQuestion) * this.props.prevQuestion,
			'redirectToReferrer': false
		}
	}
	handleBack = () => 
    {
        if(this.props.prevQuestion == 1)
            alert('No previous question');
        else
            this.setState({redirectToReferrer : true});
    }
	render() {
		// alert(this.state.progress_bar_width);
		var progressBarStyle = {
			width : this.state.progress_bar_width+'%'
		  };
		if (this.state.redirectToReferrer)
		{
			const queston_number = this.props.prevQuestion- parseInt(1);
			this.setState({redirectToReferrer : false});
			return (<Redirect to={this.props.url+queston_number} />)
		}
		return (
			<Fragment>
				<div className="progress-bar">
					<div className="progress" style={progressBarStyle}>
					</div>
				</div>
                <a style={{ cursor: 'pointer' }} className="back" onClick={this.handleBack}><i className="fa fa-angle-left"></i>back</a>
			</Fragment>
			
			// <div className="col-md-12">
			// 	<ProgressBar stepNumber={this.state.stepNumber} steps={this.props.progressArray}  lineColor={{ active: '#2cdf2e', inactive: '#FFA500'}} lineHeight={7}/>
			// </div>
		);
	}
}
export default BackButton;