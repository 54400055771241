import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

export default class XPQ6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'gender' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ gender: localStorageData[this.props.questionNum].gender });
        }
    }
    handleSubmit = (event) => {
        if(event)
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 'gender': this.state.gender };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleGender (gender, event) {
        if(event)
            event.preventDefault();
        this.setState({gender : gender});
        setTimeout(function()
        {
            this.handleSubmit();
        }.bind(this),5);
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner gender">
                        <h2 className="main">What is your gender?</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="small-width-container">
                                <div className="form-group small-width">
                                    <a className={this.state.gender === 'Male' ? 'btn btn-default male' : 'btn btn-primary male'} onClick={this.handleGender.bind(this, 'Male')}>Male</a>
                                </div>
                                <div className="form-group small-width">
                                    <a className={this.state.gender === 'Female' ? 'btn btn-default female' : 'btn btn-primary female'} onClick={this.handleGender.bind(this, 'Female')}>Female</a>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
} 