import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
export default class XPQ2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'nationality' : '',
            'first_name': '',
            'countries' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));

        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_countries_ajax',
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            if (result.data.success === true){
                this.setState({countries : result.data.countries});
            }
        })
        .catch(error => this.setState({ error: error.message }));

        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ nationality: localStorageData[this.props.questionNum].nationality });
        }
        if(localStorageData && localStorageData['1'])
            this.setState({first_name : localStorageData['1'].first_name});
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_bus_name = { 'nationality': this.state.nationality };
            localStorageData[this.props.questionNum] = forms_bus_name;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        var countries = [];
        let index_number = 0;
        var length = this.state.countries.length;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index_number]['country']}>{this.state.countries[index_number]['country']}</option>
                </Fragment>
            );
            index_number++;
        }
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <div className="quote-page">
                <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                <div className="inner">
                    <h2 className="main">Nice to meet you {this.state.first_name}! <br/>
                    What country is your primary passport from?</h2>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            {this.validator.message('nationality', this.state.nationality, 'required')}
                        </div>
                        <div className="select-div">
                            <select className="form-control" name="nationality" onChange={this.handleChange} value={this.state.nationality} onBlur={this.handleBlur}>
                                <option>Country</option>
                                {countries}
                            </select>
                        </div>
                        <div className="form-group">
                            {/* <a href="#"><i className="fa fa-plus"></i> Add country</a> */}
                        </div>
                        <div className="clearfix"></div>
                        <NextButton btntext="Next"/>
                        <div className="space_section"></div>
                    </form>
                </div>
            </div>
    );
    }
} 