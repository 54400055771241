import React, { Component} from 'react';
export default class Paperless extends Component {
    render()
    {
        var handImage = {
            backgroundImage: 'url(assets/images/pot-flower.png)',
        };
        return(
            <section className="paperless helping">
                <div className="left">
                    <div className="image" style={handImage}></div>
                </div>
                <div className="right">
                    <h1>Paperless company</h1>
                    <p>We try to be responsible and value the environment, which is why our company is 100% paperless. The fact that the company is paperless also means that security is better.</p>
                    <p>Paper documents can be physically destroyed for a variety of reasons. Not the same for documents in the cloud.</p>
                    <img src="assets/images/paperless-floating-image.png" alt=""></img>
                </div>
                <div className="clearfix"></div>
            </section>
        );
    }
}