import React, { Component, Fragment} from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';


import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";

export default class XPQ14 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'name_of_illness' : '',
            'body_part_effect' : '',
            'symtpoms_start' : '',
            'start_date_treatment' : '',
            'end_date_treatment' : '',
            'details_of_medication' : '',
            'current_status' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ name_of_illness: localStorageData[this.props.questionNum].name_of_illness });
            this.setState({ body_part_effect: localStorageData[this.props.questionNum].body_part_effect });
            this.setState({ symtpoms_start: localStorageData[this.props.questionNum].symtpoms_start });
            this.setState({ start_date_treatment: localStorageData[this.props.questionNum].start_date_treatment });
            this.setState({ end_date_treatment: localStorageData[this.props.questionNum].end_date_treatment });
            this.setState({ details_of_medication: localStorageData[this.props.questionNum].details_of_medication });
            this.setState({ current_status: localStorageData[this.props.questionNum].current_status });
            
        }
    }
    handleSubmit = (event) => {
        if(event){
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 
                'name_of_illness': this.state.name_of_illness,
                'body_part_effect' : this.state.body_part_effect,
                'symtpoms_start' : this.state.symtpoms_start,
                'start_date_treatment' : this.state.start_date_treatment,
                'end_date_treatment' : this.state.end_date_treatment,
                'details_of_medication' : this.state.details_of_medication,
                'current_status' : this.state.current_status
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleFileChange = (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append('fileToUpload', event.target.files[0]); 
        axios({
            method: "post",
            url: "http://localhost/xpatquote/api/upload.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data",
                        "content-type": "application/json"
                    },
        })
        .then(result => {
            this.setState({ 'symtpoms_start': result.data });
        })
        .catch(error => this.setState({ error: error.message }));
    }
    handleFileClick = (selector, event) => {
        document.getElementById(selector).click();
    }
    handleRemoveFile(file, event)
    {
        this.setState({[file] : ''});
    }
    handleSelection(value, event)
    {
        this.setState({current_status : value});
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner">
                        <h2 className="main">What's the name of the illness or medical problem and what body part it affects?</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                {this.validator.message('name_of_illness', this.state.name_of_illness, 'required')}
                                {this.validator.message('body_part_effect', this.state.body_part_effect, 'required')}
                                {this.validator.message('details_of_medication', this.state.details_of_medication, 'required')}
                                {this.validator.message('start_date_treatment', this.state.start_date_treatment, 'required')}
                                {this.validator.message('end_date_treatment', this.state.end_date_treatment, 'required')}
                                {this.validator.message('current_status', this.state.current_status, 'required')}
                            </div>
                            {/* First Question */}
                            <div className="small-width-container">
                                <div className="form-group small-width">
                                    <label>Name of Illness</label>
                                    <input type="text" className="form-control" value={this.state.name_of_illness} onChange={this.handleChange} name="name_of_illness" placeholder="Name of Illness" autoComplete="off"></input>
                                </div>
                                <div className="form-group small-width">
                                    <label>Body Part Affected</label>
                                    <input type="text" className="form-control" value={this.state.body_part_effect} onChange={this.handleChange} name="body_part_effect" placeholder="Body Part Affected" autoComplete="off"></input>
                                </div>
                            </div>
                            {/* Second Question */}
                            <h2 className="main">When did symtpoms start and when did they occur last?</h2>
                            <div className="form-group">
                                <div id="fakeDiv">
                                    <input type="file" id="selectedFile" className="form-control selectedFile" name="file" onChange={this.handleFileChange}></input>
                                    <input type="text" id="fakeInput" className="form-control fakeInput" value={this.state.symtpoms_start} onChange={this.handleChange} />
                                    <a className="cross-file" onClick={this.handleRemoveFile.bind(this,'symtpoms_start')}><img src="/assets/images/icons/file-cross.svg" alt=""></img></a>
                                </div>
                                <div className="clearfix"></div>
                                <a onClick={this.handleFileClick.bind(this,'selectedFile')} id="buttonImage"><i className="fa fa-paperclip"></i>Attach Files</a>
                            </div>
                            {/* Third Question */}
                            <h2 className="main">What treatment was provided? (Include details of medication and the start and end dates of treatment)</h2>
                            <div className="form-group">
                                <textarea name="details_of_medication" className="form-control" onChange={this.handleChange} value={this.state.details_of_medication} onBlur={this.handleBlur} placeholder="Details of Medication"></textarea>
                            </div>
                            <div className="form-group">
                                <input type="date" className="form-control" value={this.state.start_date_treatment} name="start_date_treatment" onChange={this.handleChange} autoComplete="off" placeholder="Start Date"/>
                            </div>
                            <div className="form-group">
                                <input type="date" className="form-control" value={this.state.end_date_treatment} name="end_date_treatment" onChange={this.handleChange} autoComplete="off" placeholder="End Date"/>
                            </div>
                            {/* Fourth Quetion */}
                            <h2 className="main">What is the current status of the medical problem?</h2>
                            <div className="small-width-container">
                                <div className="form-group small-width">
                                    <a className={this.state.current_status === 'Ongoing' ? 'btn btn-default' : 'btn btn-primary'}  onClick={this.handleSelection.bind(this,'Ongoing')}>Ongoing</a>
                                </div>
                                <div className="form-group small-width">
                                    <a className={this.state.current_status === 'Complete' ? 'btn btn-default' : 'btn btn-primary'} onClick={this.handleSelection.bind(this,'Complete')}>Complete</a>
                                </div>
                            </div>
                            <div className="small-width-container">
                                <div className="form-group small-width">
                                    <a className={this.state.current_status === 'Recovery' ? 'btn btn-default' : 'btn btn-primary'} onClick={this.handleSelection.bind(this,'Recovery')}>Recovery</a>
                                </div>
                                <div className="form-group small-width">
                                    <a className={this.state.current_status === 'Recurrent' ? 'btn btn-default' : 'btn btn-primary'} onClick={this.handleSelection.bind(this,'Recurrent')}>Recurrent</a>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <NextButton btntext="Next"/>
                        </form>
                    </div>
                    <div className="inner"></div>
                </div>
            </Fragment>
        );
    }
} 