import React, { Component, Fragment,  useState} from 'react';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

    function Loader(props) {
        let isLoading = props.isLoading;
       
        return(
            (isLoading === true ? 
        <Fragment>
                <div className="overlay">
                <div className="lds-ripple"><div></div><div></div><div></div></div>
            </div>
            </Fragment>
            : '')
                
        );
    }

export default Loader;