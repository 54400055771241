import React, { Component} from 'react';
import Header from '../common/component/Header';
import Footer from '../common/component/Footer';
import TopSection from './topSection';
import SecondSection from './secondSection';
import Working from './working';
import FaqHelp from '../faq/FaqHelp';


export default class blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'url' : new URLSearchParams(this.props.location.search).get("affinity_partner")
        };
    }
    componentDidMount() {
        if(this.state.url)
        {
            var forms_info = { 
                'affiliate_string': this.state.url
            };
            var url_forms_info = { '0' : forms_info };
            url_forms_info = JSON.stringify(url_forms_info);
            localStorage.setItem('xpatquote', url_forms_info);
        }
    }
    render()
    {
        return(
            <div className="wrapper">
                   <div class="affiliates">
                <Header></Header>
                <TopSection></TopSection>
                <SecondSection></SecondSection>
                <Working></Working>
                <FaqHelp></FaqHelp>
                <Footer></Footer>
                </div>
            </div>
        );
    }
}