import React, { Component} from 'react';
import { handleChange,handleBlur } from '../common_function/common_function';
import SimpleReactValidator from 'simple-react-validator';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
export default class ContactForm extends Component {

  constructor(props){
    super(props);
     this.state = {
       'name' : '',
       'email' : '',
       'message' : ''
     }
     this.validator = new SimpleReactValidator();
     this.handleChange = handleChange.bind(this);
     this.handleBlur = handleBlur.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this)

  }

  handleSubmit (event){
   event.preventDefault();
   
        if (this.validator.allValid()) {
           this.props.functionLoader.handleUpdateLoader(true);
            var formData = new FormData();
            formData.append('name', this.state.name);
            formData.append('email', this.state.email);
            formData.append('message', this.state.message);
           
            var base_url = process.env.REACT_APP_HOME_API_URL;

            axios({
                method: "post",
                url: base_url+'leads/xpat_contactus_query_ajax',
                data : formData,
                headers: { "content-type": "application/json" },
            })
            .then(result => {
                // if (result.data.success === true){
                    alert(result.data);
                    window.location.reload();
                   
               // }
               this.props.functionLoader.handleUpdateLoader(false);
            })
            .catch(error => this.setState({ error: error.message }));
  }

  
}
    render()
    {
      var handImage = {
        backgroundImage: 'url(assets/images/faq-help.png)',
       };
        return(
          <section className="paperless helping faq-help">
          <div className="left">
            <div className="image" style={handImage}></div>
          </div>
          <div className="right">
            <h1>We are here to help</h1>
            <p>Home is behind, the world ahead and there are many paths to tread through shadows to the edge.</p>
            
            <form onSubmit={this.handleSubmit} >
            <div className="form-group">
                            {this.validator.message('name', this.state.name, 'required')}
                            {this.validator.message('email', this.state.email, 'required|email')}
                            {this.validator.message('message', this.state.message, 'required')}

                        </div>
              <div className="form-group">
                <label>Name</label>
                <input type="text" name="name" className="form-control" value={this.state.name}  placeholder="Name" onChange={this.handleChange} onBlur={this.handleBlur} />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="text" name="email" className="form-control" value={this.state.email} placeholder="Email" onChange={this.handleChange} onBlur={this.handleBlur} />
              </div>
              <div className="form-group text-area">
                  <textarea className="form-control" name="message" id="" value={this.state.message} cols="30" rows="3" onChange={this.handleChange} onBlur={this.handleBlur} placeholder="Message"></textarea>
              </div>
              <div className="clearfix">&nbsp;&nbsp;</div>
              <button type="submit"  className="btn btn-brand">Send</button>
            </form>
          </div>
          <div className="clearfix"></div>
        </section>
        );
    }
}