import React, { Component } from "react";
import {handleBlur } from '../../common_function/common_function';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state ={
            'menuToggle' : false,
        }
         this.handleMenuToggle = this.handleMenuToggle.bind(this);
    }

    handleMenuToggle= (event)=>{
        this.setState({menuToggle: !this.state.menuToggle})
    }

    handleDomToggle= ()=>{
        this.setState({menuToggle: false});
    }
    
    render() {
        
        var base_url = process.env.REACT_APP_API_URL;
        var myAccountLink =  base_url+'users/login';
        document.addEventListener('click', this.handleDomToggle);
       
        return (
            <div className="container header-container">
                <nav className="navbar navbar-default">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/"><img src="assets/images/logo.png" alt=""></img></a>
                    </div>
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                            <li className="dropdown open">
                                <a  onClick={this.handleMenuToggle} className="dropdown-toggle" data-toggle="dropdown" role="button" onBlur={this.handleBlur} aria-haspopup="true" aria-expanded="true">Learn</a>
                                {this.state.menuToggle === true ? 
                                <ul className="dropdown-menu ">
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="/faq">FAQ</a></li>
                                    <li><a href="/blog">Blog</a></li>
                                </ul>
                                 : '' }
                            </li>
                            <li><a href="#" className="visible-xs">My Account</a></li>
                            <li><a href="#" className="visible-xs"> Get Quote</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="menu-links hidden-xs">
                        <ul>
                            <li><a className="btn btn-default" href={`${myAccountLink}`}>My Account</a></li>
                            <li><a className="btn btn-brand" href="/xpatquote/1"> Get Quote</a></li>
                        </ul>
                        <div className="clearfix"></div>
                    </div>
                </nav>
            </div>
        );
    }
}
export default Header;