import React, { Component, Fragment} from 'react';
export default class AboutHistory extends Component {
    render()
    {
      var handImage = { 
        backgroundImage: 'url(assets/images/about-img1.png)',
    };
    var handImage2 = { 
        backgroundImage: 'url(assets/images/about-img3.png)',
    };
    var handHelpingImage = { 
        backgroundImage: 'url(assets/images/helping-hand.png)',
    };
    
        return(
            <Fragment>
            <section className="hero">
            <div className="container">
              <div className="left">
                <div className="image" style={handImage}></div>
              </div>
    
              <div className="right">
                <h1>Our History</h1>
                <p>Xpat Coverage founder and entrepreneur Zain Jeewanjee has devoted 35+ years to the travel insurance industry, but saw a need that wasn’t being met. Individuals living or working abroad were unable to get the same quality of medical insurance that they would have at home. </p>
                <a href="javascript:void(0)" className="btn btn-default">Read More</a>
              </div>
              <div className="clearfix"></div>
            </div>
          </section>

          <section className="main-section">
            <div className="container">
                <div className="video visible-xs">
                  <img className="img-responsive" src="assets/images/about-img2.png" alt="" />
                </div>
                <div className="text-container">
                  <h2 className="with-underline">Simplifying the process</h2>
                  <p>Our sole focus is making our customers journey as easy as can be. We know that getting the proper health insurance while traveling is challenging and confusing. Our intuitive quoting engine makes getting a quote simple and helps you select the right plan based on your needs. </p>
                  <a href="javascript:void(0)" className="btn btn-default">Read More</a>
                </div>
                <div className="video hidden-xs">
                  <img className="img-responsive" src="assets/images/about-img2.png" alt="" />
                </div>
                <div className="clearfix"></div>
            </div>
  
          </section>

          <section className="hero hero2">
            <div className="container">
              <div className="left">
                <div className="image" style={handImage2}></div>
              </div>
    
              <div className="right">
                <h1>Quality Guarantee</h1>
                <p>To ensure our customers are always being taken care of and have peace of mind while traveling, we only work with the top rated carriers in the world. We have carefully selected the products for our customers based on the plan’s coverage, providers, claims assistance, and ease of use.</p>
                <a href="javascript:void(0)" className="btn btn-default">Read More</a>
              </div>
              <div className="clearfix"></div>
            </div>
          </section> 


          <section className="helping section4">
            <div className="left">
              <div className="image" style={handHelpingImage}></div>
            </div>
  
            <div className="right">
              <h1>Giving Back</h1>
              <p>One of our core values as a company is giving back and appreciating what we have. Xpat Coverage operates worldwide and helps travelers of all ethnicities, backgrounds, history, health issues etc., and we are blessed to have many partners who work directly with us all across the globe. This is why we believe it is crucial to always be helping our planet and people in need. We partnered with 3 organizations that go above and beyond to help make our planet a better place by allowing our customers to donate $5 of their premium, at no cost to them, to one of these three organizations.</p>
              <ul className="hidden">
                <li>
                  <img src="assets/images/logo-chariry1.png" alt="" />
                </li>
                <li>
                    <img src="assets/images/logo-chariry2.png" alt="" />
                </li>
                <li>
                  <img src="assets/images/logo-chariry3.png" alt="" />
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </section>
          </Fragment>

        );
    }
}