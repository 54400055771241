import React, { Component} from 'react';
import Header from '../common/component/Header';
import Footer from '../common/component/Footer';
import Questions from './Questions';
import FaqHelp from './FaqHelp';

export default class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'url' : new URLSearchParams(this.props.location.search).get("affinity_partner")
        };
    }
    componentDidMount() {
        if(this.state.url)
        {
            var forms_info = { 
                'affiliate_string': this.state.url
            };
            var url_forms_info = { '0' : forms_info };
            url_forms_info = JSON.stringify(url_forms_info);
            localStorage.setItem('xpatquote', url_forms_info);
        }
    }
    render()
    {
        return(
            <div className="wrapper">
                  <div className="plans-page faq"> 
                <Header></Header>
                <Questions></Questions>
                <FaqHelp></FaqHelp>
                <Footer></Footer>
                </div>
            </div>
        );
    }
}