import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
export default class XPQ11 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'diabetes_endocrine' : 'No',
            'heart_or_circulatory' : 'No',
            'cancer_tumours' : 'No',
            'muscle_skeletal' : 'No',
            'asthma_allergies' : 'No',
            'gall_bladder' : 'No',
            'none_of_above_diabetes' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ diabetes_endocrine: localStorageData[this.props.questionNum].diabetes_endocrine });
            this.setState({ heart_or_circulatory: localStorageData[this.props.questionNum].heart_or_circulatory });
            this.setState({ cancer_tumours: localStorageData[this.props.questionNum].cancer_tumours });
            this.setState({ muscle_skeletal: localStorageData[this.props.questionNum].muscle_skeletal });
            this.setState({ asthma_allergies: localStorageData[this.props.questionNum].asthma_allergies });   
            this.setState({ gall_bladder: localStorageData[this.props.questionNum].gall_bladder });   
            this.setState({ none_of_above_diabetes: localStorageData[this.props.questionNum].none_of_above_diabetes });   
              
        } 
    }
    handleSubmit = (event) => {
        if(event){
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 
                'diabetes_endocrine': this.state.diabetes_endocrine,
                'heart_or_circulatory' : this.state.heart_or_circulatory,
                'cancer_tumours' : this.state.cancer_tumours,
                'muscle_skeletal' : this.state.muscle_skeletal,
                'asthma_allergies' : this.state.asthma_allergies,
                'gall_bladder' : this.state.gall_bladder,
                'none_of_above_diabetes' : this.state.none_of_above_diabetes
            }; 
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleChangeMedicalRadio(disease, event)
    {
        this.state[disease] === '' || this.state[disease] === 'No' 
            ? this.setState({[disease] : 'Yes'}) 
            : this.setState({[disease] : 'No'});
            
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner radio-box">
                        <h2 className="main">Has any applicant received treatment, tests or investigations for, or been diagnosed with, or had any signs or symptoms of: <br />(select all that apply)</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="margin-bottom-20">
                                {/* {this.validator.message('diabetes_endocrine', this.state.diabetes_endocrine, 'required')}
                                {this.validator.message('heart_or_circulatory', this.state.heart_or_circulatory, 'required')}
                                {this.validator.message('cancer_tumours', this.state.cancer_tumours, 'required')}
                                {this.validator.message('muscle_skeletal', this.state.muscle_skeletal, 'required')}
                                {this.validator.message('asthma_allergies', this.state.asthma_allergies, 'required')}
                                {this.validator.message('gall_bladder', this.state.gall_bladder, 'required')} */}
                            </div>
                            <div className="radio">
                                <input type="radio" name="diabetes_endocrine" id="diabetes_endocrine" value="Yes" checked={this.state.diabetes_endocrine === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'diabetes_endocrine')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="diabetes_endocrine">Diabetes and endocrine (glandular) disorders <br/>
                                    <small>(e.g. any thyroid disorder, weight problems, gout, pituitary or adrenal gland conditions)</small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="heart_or_circulatory" id="heart_or_circulatory" value="Yes" checked={this.state.heart_or_circulatory === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'heart_or_circulatory')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="heart_or_circulatory">Heart or circulatory disorders <br/>
                                    <small>(e.g. chest pain, heart attack, high blood pressure, vascular disease, coronary artery disease, angina, irregular heartbeat, aneurysm or heart murmur) </small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="cancer_tumours" id="cancer_tumours" value="cancer_tumours" value="Yes" checked={this.state.cancer_tumours === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'cancer_tumours')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="cancer_tumours">
                                    Cancer, tumours or growths including polyps, cysts or breast lumps. <br/>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="muscle_skeletal" id="muscle_skeletal" value="muscle_skeletal" value="Yes" checked={this.state.muscle_skeletal === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'muscle_skeletal')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="muscle_skeletal">Muscle or skeletal problems <br/>
                                    <small>(e.g. back pain, whiplash, arthritis, joint pain or problems, gout, fractures, cartilage, tendon or ligament problems.) </small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="asthma_allergies" id="asthma_allergies" value="asthma_allergies" value="Yes" checked={this.state.asthma_allergies === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'asthma_allergies')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="asthma_allergies">Asthma, allergies, breathing or respiratory disorders<br/>
                                    <small>(e.g. chest infections, pneumonia, bronchitis, shortness of breath, rhinitis, TB, emphysema or chronic obstructive pulmonary disease.) </small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="gall_bladder" id="gall_bladder" value="gall_bladder" value="Yes" checked={this.state.gall_bladder === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'gall_bladder')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="gall_bladder">Gall bladder, stomach, intestinal, gastric or liver problems<br/>
                                    <small>(e.g. irritable bowel disease, colitis, Crohn’s disease, gastric or peptic ulcers, reflux, indigestion, heartburn, gall stones, hernia, haemorrhoids or hepatitis.) </small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="none_of_above_diabetes" id="none_of_above_diabetes" value="none_of_above_diabetes" value="Yes" checked={this.state.none_of_above_diabetes === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'none_of_above_diabetes')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="none_of_above_diabetes">None of the above<br/>
                                    <small> &nbsp;&nbsp; </small>
                                </label>
                            </div>
                            <NextButton btntext="Next"/>
                        </form>
                    </div>
                    <div className="inner"></div>
                </div>
            </Fragment>
        );
    }
} 