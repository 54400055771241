import React, { Component} from 'react';
export default class NeedCoverage extends Component {
    render()
    {
        return(
            <section className="coverage-section">
                <div className="right">
                    <div className="inner">
                        <h1>Where do you need coverage?</h1>
                        <div className="clearfix"></div>
                        <div className="coverage-card">
                        <a href="/xpatquote/1">
                            <div className="card card-left">
                                <div className="left">
                                    <img src="assets/images/home-coverage-icon1.svg" alt=""></img>
                                </div>
                                <div className="right">
                                    <p>Worldwide</p>
                                    <span>Including the USA</span>
                                </div>
                            </div>
                            </a>
                            <a href="/xpatquote/1">
                            <div className="card card-right">
                                <div className="left">
                                    <img src="assets/images/home-coverage-icon2.svg" alt=""></img>
                                </div>
                               
                                <div className="right">
                                    <p>Worldwide</p>
                                    <span>Excluding the USA</span>
                                </div>
                              
                                <div className="clearfix"></div>
                            </div>
                            </a>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div className="left">
                    <img src="assets/images/home-map.png" className="img-responsive hidden-sm hidden-xs" alt=""></img>
                    <img src="assets/images/home-map-mobile.png" className="img-responsive visible-sm" alt=""></img>
                    <img src="assets/images/home-map-mobile-xs.png" className="img-responsive visible-xs" alt=""></img>
                </div>
            </section>
        );
    }
}
