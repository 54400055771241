import React, { Component, Fragment } from 'react'
import NextButton from '../../../business/component/NextButton';
import { handleChange, handleBlur } from '../../../common_function/common_function';
import BackButton from '../../../business/component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
export default class CA4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'email' : '',
            'phone' : '',
            'country' : '',
            'countries' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const localStorageData = JSON.parse(localStorage.getItem('create-account'));
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_countries_ajax',
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            if (result.data.success === true){
                this.setState({countries : result.data.countries});
            }
        })
        .catch(error => this.setState({ error: error.message }));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ email: localStorageData[this.props.questionNum].email });
            this.setState({ country: localStorageData[this.props.questionNum].country });
            this.setState({ phone: localStorageData[this.props.questionNum].phone });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('create-account'));
            var base_url = process.env.REACT_APP_HOME_API_URL;
            axios({
                method: "post",
                url: base_url+'users/verify_email_ajax',
                data : {"email" : this.state.email},
                headers: { "content-type": "application/json" },
            })
            .then(result => {
                if (result.data.success === true){
                    const forms_bus_name = { 
                        'email' : this.state.email,
                        'country' : this.state.country,
                        'phone': this.state.phone
                    };
                    localStorageData[this.props.questionNum] = forms_bus_name;
                    if(this.props.questionNum >= localStorageData.current_step)
                        localStorageData.current_step = parseInt(this.props.questionNum)+1;

                    localStorageData.password = result.data.password;
                    localStorageData = JSON.stringify(localStorageData);
                    localStorage.setItem('create-account', localStorageData);
                    this.setState({ redirectToReferrer: true });
                }
                else if(result.data.success === false)
                {
                    alert('Email already Exists');
                    // this.setState({error : this.state.email+' already exists'});
                    this.setState({email : ''});
                }
            })
            .catch(error => this.setState({ error: error.message }));
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        var countries = [];
        let index_number = 0;
        var length = this.state.countries.length;
        for (var i = 1; i <= length; i++) {
            countries.push(
                <Fragment key={i}>
                    <option value={this.state.countries[index_number]['country']}>{this.state.countries[index_number]['country']}</option>
                </Fragment>
            );
            index_number++;
        }
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/create-account/"+next} />)
        }
        return (
            <div className="col-md-7 col-sm-12 right">
                <div className="login-logo visible-sm visible-xs">
                    <a className="navbar-brand" href="/"><img src="/assets/images/logo-login-blue2.svg" alt=""></img></a>
                </div>
                <div className="clearfix"></div>
                <h3>Create Xpat account</h3>
                <p>Create account to start using the full range of advantages today.  </p>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        {/* <div class="srv-validation-message">{this.state.error}</div> */}
                        {this.validator.message('email', this.state.email, 'required|email')}
                        {this.validator.message('country', this.state.country, 'required')}
                        {this.validator.message('phone', this.state.phone, 'required')}
                    </div>
                    <div className="form-group">
                        <label>What is your Email?</label> 
                        <input type="text" className="form-control" placeholder="Your Email" value={this.state.email}  name="email" autoFocus={true} onChange={this.handleChange} onBlur={this.handleBlur}/>
                    </div>
                    <div className="form-group">
                        <label>What is your country?</label> 
                        <div className="select-div">
                            <select className="form-control" name="country" onChange={this.handleChange} value={this.state.country} onBlur={this.handleBlur}>
                                <option>Country</option>
                                {countries}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>What is your phone number?</label> 
                        <input type="text" className="form-control" placeholder="+1 (XXX) XXX XX XX" value={this.state.phone}  name="phone" onChange={this.handleChange} onBlur={this.handleBlur}/>
                    </div> 
                    <button type="submit" className={this.state.email !== '' && this.state.country !== '' && this.state.phone !== '' ? 'btn btn-brand btn-block'  : 'btn btn-brand btn-block btn-disabled'}>Create Account</button>
                </form>
            </div>
        );
    }
} 