import React, { Component, Fragment } from 'react'
import NextButton from '../../../business/component/NextButton';
import { handleChange, handleBlur } from '../../../common_function/common_function';
import BackButton from '../../../business/component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
export default class CA5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'password' : '',
            'email' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const localStorageData = JSON.parse(localStorage.getItem('create-account'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ password: localStorageData[this.props.questionNum].password });
        }
        if(localStorageData && localStorageData['4'])
        {
            this.setState({ email: localStorageData['4'].email });
        }
    }
    handleAgainPassword = () => {
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'users/verify_email_ajax',
            data : {"email" : this.state.email},
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            if (result.data.success === true){
                alert("Email send again");
                let localStorageData = JSON.parse(localStorage.getItem('create-account'));
                localStorageData.password = result.data.password;
                localStorageData = JSON.stringify(localStorageData);
                localStorage.setItem('create-account', localStorageData);
            }
        })
        .catch(error => this.setState({ error: error.message }));
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('create-account'));
            if(localStorageData.password === this.state.password)
            {
                const forms_bus_name = { 
                    'password' : this.state.password
                };
                localStorageData[this.props.questionNum] = forms_bus_name;
                if(this.props.questionNum >= localStorageData.current_step)
                    localStorageData.current_step = parseInt(this.props.questionNum)+1;
                localStorageData = JSON.stringify(localStorageData);
                localStorage.setItem('create-account', localStorageData);
                this.setState({ redirectToReferrer: true });
            }
            else
            {
                alert("Password not match");
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/create-account/"+next} />)
        }
        return (
            <div className="col-md-7 col-sm-12 right">
                <div className="login-logo visible-sm visible-xs">
                    <a className="navbar-brand" href="/"><img src="/assets/images/logo-login-blue2.svg" alt=""></img></a>
                </div>
                <div className="clearfix"></div>
                <h3>Create Xpat account</h3>
                <p>Enter the password from email we’ve sent you. Check your “Spam” folder, if you can’t find email.</p>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        {this.validator.message('password', this.state.password, 'required')}
                    </div>
                    <div className="form-group">
                        <label>What is your Password?</label> 
                        <input type="password" className="form-control" placeholder="Your Password" value={this.state.password}  name="password" onChange={this.handleChange} onBlur={this.handleBlur}/>
                    </div>
                    <ul className="button-group v-group">
                      <li> <button type="submit" className={this.state.password !== ''  ? 'btn btn-brand btn-block'  : 'btn btn-brand btn-block btn-disabled'}>Create Account</button> </li>
                      <li> <a className="btn btn-brand" onClick={this.handleAgainPassword}>Send password again</a> </li>
                    </ul>
                </form>
            </div>
        );
    }
} 