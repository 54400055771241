import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import Loader from '../../../../../../loader/Loader';



import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import { ToggleRadioButtonUnchecked } from 'material-ui/svg-icons';
import lineStyle from 'material-ui/svg-icons/action/line-style';
import { ListItemText } from '@material-ui/core';


export default class XPQ15 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //'start_select_date' : '',
            'payment_period' : 'Monthly',
            'currency' : 'US Dollars',
            'currency_sign' : '$',
            'payment_period_factor' : 1.00, 
            'level_plan' : '',
            'show_price' : true,
            'core_deductible_silver' : '$375',
            'coi_silver' : '0% CI - $0 OOP',
            'silver_price' : '$ 0.00',
            'silver_price_orignal' : 0.00,
            'core_deductible_platinum' : '$375',
            'coi_platinum' : '0% CI - $0 OOP',
            'platinum_price' : '$ 0.00',
            'platinum_price_orignal' : 0.00,
            'core_deductible_gold' : '$375',
            'coi_gold' : '0% CI - $0 OOP',
            'gold_price' : '$ 0.00',
            'gold_price_orignal' : 0.00,
            'core_deductible_care' : '$375',
            'coi_care' : '0% CI - $0 OOP',
            'care_price' : '$ 0.00',
            'care_price_orignal' : '',
            'dob' : '',
            'nationality' : '',
            'living_country': '',
            'is_load' : true,

            'selected_plan_price' : '',
            'selected_coi' : '',
            'selected_core_deductible' : '',
            

            'add_cover_usa' : 'WW',
            'annual_benefit_limit_silver' : '$1,000,000',
            'annual_benefit_limit_gold' : '$2,000,000',
            'overall_benefit_limit_care' : '$500,000',
            'condition_limit_care' : '$250,000',



            // Second part

            'outpatient_silver_price' : '$ 0.00',
            'outpatient_silver_price_orignal' : 0.00,
            
            'dv_silver_price' : '$ 0.00',
            'dv_silver_price_orignal' : 0.00,
            
            'ev_silver_price' : '$ 0.00',
            'ev_silver_price_orignal' : 0.00,
            
            'hw_silver_price' : '$ 0.00',
            'hw_silver_price_orignal' :  0.00,

            'outpatient_platinum_price' : '$ 0.00',
            'outpatient_platinum_price_orignal' : 0.00,
            
            'dv_platinum_price' : '$ 0.00',
            'dv_platinum_price_orignal' : 0.00,
            
            'ev_platinum_price' : '$ 0.00',
            'ev_platinum_price_orignal' : 0.00,
            
            'hw_platinum_price' : '$ 0.00',
            'hw_platinum_price_orignal' :  0.00,

            'outpatient_gold_price' : '$ 0.00',
            'outpatient_gold_price_orignal' : 0.00,
            
            'dv_gold_price' : '$ 0.00',
            'dv_gold_price_orignal' : 0.00,
            
            'ev_gold_price' : '$ 0.00',
            'ev_gold_price_orignal' : 0.00,

            'outpatient_care_price' : '$ 0.00',
            'outpatient_care_price_orignal' : 0.00,
            
            'hw_gold_price' : '$ 0.00',
            'hw_gold_price_orignal' :  0.00,
            

            'core_deductible_outpatient_silver' : '$500',
            'coi_outpatient_silver' : '20% CI - $3,000 OOP',

            'core_deductible_outpatient_platinum' : '$500',
            'coi_outpatient_platinum' : '20% CI - $3,000 OOP',

            'core_deductible_outpatient_gold' : '$500',
            'coi_outpatient_gold' : '20% CI - $3,000 OOP',

            'core_deductible_outpatient_care' : '$500',
            'coi_outpatient_care' : '20% CI - $3,000 OOP',
            
            'dv_care_price' : '$ 0.00',
            'dv_care_price_orignal' : 0.00,

            'additional_benefits_payment_silver_outpatient' : false,
            'additional_benefits_payment_silver_ev' : false,
            'additional_benefits_payment_silver_hw' : false,
            'additional_benefits_payment_silver_dv' : false,


            'additional_benefits_payment_gold_outpatient' : false,
            'additional_benefits_payment_gold_ev' : false,
            'additional_benefits_payment_gold_hw' : false,
            'additional_benefits_payment_gold_dv' : false,

            'additional_benefits_payment_platinum_outpatient' : false,
            'additional_benefits_payment_platinum_ev' : false,
            'additional_benefits_payment_platinum_hw' : false,
            'additional_benefits_payment_platinum_dv' : false,

            'additional_benefits_payment_care_outpatient' : false,
            'additional_benefits_payment_care_dv' : false,


            // outpatient selected

            'selected_additional_benefits_payment_outpatient' : '',
            'selected_plan_price_outpatient' : '',
            'selected_plan_price_orignal_outpatient' : '',
            'selected_core_deductible_outpatient' : '',
            'selected_coi_outpatient' : '',
            'selected_additional_benefits_payment_ev' : '',
            'selected_plan_price_ev' : '',
            'selected_plan_price_orignal_ev' : '',
            'selected_additional_benefits_payment_hw' : '',
            'selected_plan_price_hw' : '',
            'selected_plan_price_orignal_hw' : '',
            'selected_additional_benefits_payment_dv' : '',
            'selected_plan_price_dv' : '',
            'selected_plan_price_orignal_dv' : '',

            'relation_array' : [],

            'redirectToReferrer': false,
            'loadingStatus': false

        }
        // Symbol Euro € Pound £ 
        // 
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.update_currency_plans_rates = this.update_currency_plans_rates.bind(this);
        this.handleBlur = handleBlur.bind(this);
       
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));

        var show_price = true;
        if(localStorageData && localStorageData['14'] && localStorageData['12'] && localStorageData['13'])
        {
            if(localStorageData['14'].any_one_have_illness === 'Yes' || localStorageData['14'].take_mediction === 'Yes' ||
            localStorageData['12'].asthma_allergies === 'Yes' || localStorageData['12'].cancer_tumours === 'Yes' ||
            localStorageData['12'].diabetes_endocrine === 'Yes' || localStorageData['12'].gall_bladder === 'Yes' ||
            localStorageData['12'].heart_or_circulatory === 'Yes' || localStorageData['12'].muscle_skeletal === 'Yes' ||
            localStorageData['13'].anxiety === 'Yes' || localStorageData['13'].blood_infective === 'Yes' ||
            localStorageData['13'].ear_nose_throat === 'Yes' || localStorageData['13'].neurological === 'Yes' ||
            localStorageData['13'].skin_problems === 'Yes' || localStorageData['13'].urinary_or_reproductive === 'Yes'
            )
            {
                this.setState({show_price : true});
                show_price = true;
            }
        }

        var relation_length = localStorageData['11'].no_of_relation ? localStorageData['11'].no_of_relation : 0;
        var relation_array = [];
        for(var i = 0; i < relation_length; i++)
        {
            const dob = 'dob['+ i +']';
            const nationality = 'nationality['+ i +']';
            const destination_country = 'destination_country['+ i +']';
            let localarray = {
                'age' : localStorageData['11'][dob],
                'nationality' : localStorageData['11'][nationality],
                'living_country' : localStorageData['11'][destination_country]
            };
            // localarray = JSON.stringify(localarray);
            relation_array.push(localarray);
        }
        relation_array = JSON.stringify(relation_array);
        this.setState({relation_array : relation_array});
        // Load Call

        if(this.state.is_load === true && show_price === true)
        {
            if(localStorageData && localStorageData['2'])
                this.setState({ nationality : localStorageData['2'].nationality});
            if(localStorageData && localStorageData['3'])
                this.setState({ dob : localStorageData['3'].dob});
            if(localStorageData && localStorageData['5'])
                this.setState({ living_country : localStorageData['5'].destination_country});
            setTimeout(
                function() {
                    //this.setState({setloaderStatus : true});
                    this.props.functionLoader.handleUpdateLoader(true);
                    var formData = new FormData();
                    formData.append('nationality', this.state.nationality);
                    formData.append('dob', this.state.dob);
                    formData.append('living_country', this.state.living_country);
                    formData.append('aoc', this.state.add_cover_usa);
                    formData.append('currency' , this.state.currency);
                    formData.append('is_api', true);
                    formData.append('relation_array', this.state.relation_array);
                    var base_url = process.env.REACT_APP_HOME_API_URL;
                    axios({
                        method: "post",
                        url: base_url+'get_quote/price_list',
                        data : formData,
                        headers: { "content-type": "application/json" },
                    })
                    .then(result => {
                       
                        if (result.data.success === true){
                            this.setState({dob : result.data.age});
                            
                            // if(this.state.level_plan == 'silver')
                            // {
                                this.setState({silver_price_orignal : result.data.prices.core_silver});
                                this.setState({silver_price : (this.changeTwoDecimal((result.data.prices.core_silver*this.state.payment_period_factor)))});
                                
                                this.setState({outpatient_silver_price_orignal : result.data.prices.outpatient_silver});
                                this.setState({outpatient_silver_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.outpatient_silver*this.state.payment_period_factor)))});

                                if(result.data.prices.other_products_silver.dv)
                                {
                                    this.setState({dv_silver_price_orignal : result.data.prices.other_products_silver.dv});
                                    this.setState({dv_silver_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_silver.dv*this.state.payment_period_factor)))});
                                }

                                if(result.data.prices.other_products_silver.ev)
                                {
                                    this.setState({ev_silver_price_orignal : result.data.prices.other_products_silver.ev});
                                    this.setState({ev_silver_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_silver.ev*this.state.payment_period_factor)))});
                                }

                                if(result.data.prices.other_products_silver.hw)
                                {
                                    this.setState({hw_silver_price_orignal : result.data.prices.other_products_silver.hw});
                                    this.setState({hw_silver_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_silver.hw*this.state.payment_period_factor)))});
                                }

                                
    
                          // }
                            // if(this.state.level_plan == 'platinum')
                            // {
                                this.setState({platinum_price_orignal : result.data.prices.core_platinum});
                                this.setState({platinum_price : (this.changeTwoDecimal((result.data.prices.core_platinum*this.state.payment_period_factor)))});

                                this.setState({outpatient_platinum_price_orignal : result.data.prices.outpatient_platinum});
                                this.setState({outpatient_platinum_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.outpatient_platinum*this.state.payment_period_factor)))});

                                if(result.data.prices.other_products_platinum.dv)
                                {
                                    this.setState({dv_platinum_price_orignal : result.data.prices.other_products_platinum.dv});
                                    this.setState({dv_platinum_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_platinum.dv*this.state.payment_period_factor)))});
                                }

                                if(result.data.prices.other_products_platinum.ev)
                                {
                                    this.setState({ev_platinum_price_orignal : result.data.prices.other_products_platinum.ev});
                                    this.setState({ev_platinum_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_platinum.ev*this.state.payment_period_factor)))});
                                }

                                if(result.data.prices.other_products_platinum.hw)
                                {
                                    this.setState({hw_platinum_price_orignal : result.data.prices.other_products_platinum.hw});
                                    this.setState({hw_platinum_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_platinum.hw*this.state.payment_period_factor)))});
                                }
                          //  }
                            // if(this.state.level_plan == 'gold')
                            // {
                                this.setState({gold_price_orignal : result.data.prices.core_gold});
                                this.setState({gold_price : (this.changeTwoDecimal((result.data.prices.core_gold*this.state.payment_period_factor)))});

                                this.setState({outpatient_gold_price_orignal : result.data.prices.outpatient_gold});
                                this.setState({outpatient_gold_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.outpatient_gold*this.state.payment_period_factor)))});

                                if(result.data.prices.other_products_gold.dv)
                                {
                                    this.setState({dv_gold_price_orignal : result.data.prices.other_products_gold.dv});
                                    this.setState({dv_gold_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_gold.dv*this.state.payment_period_factor)))});
                                }

                                if(result.data.prices.other_products_gold.ev)
                                {
                                    this.setState({ev_gold_price_orignal : result.data.prices.other_products_gold.ev});
                                    this.setState({ev_gold_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_gold.ev*this.state.payment_period_factor)))});
                                }

                                if(result.data.prices.other_products_gold.hw)
                                {
                                    this.setState({hw_gold_price_orignal : result.data.prices.other_products_gold.hw});
                                    this.setState({hw_gold_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_gold.hw*this.state.payment_period_factor)))});
                                }
                          // }

                            // if(this.state.level_plan == 'care')
                            // {
                                this.setState({care_price_orignal : result.data.prices.close_care});
                                this.setState({care_price : (this.changeTwoDecimal((result.data.prices.close_care*this.state.payment_period_factor)))});

                                this.setState({outpatient_care_price_orignal : result.data.prices.outpatient_care});
                                this.setState({outpatient_care_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.outpatient_care*this.state.payment_period_factor)))});
                                 
                                if(result.data.prices.other_products_care.dv)
                                {
                                    this.setState({dv_care_price_orignal : result.data.prices.other_products_care.dv});
                                    this.setState({dv_care_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data.prices.other_products_care.dv*this.state.payment_period_factor)))});
                                }
                                //  }

                                // this.setState({core_deductible_platinum : localStorageData[this.props.questionNum].selected_core_deductible});
                                // this.setState({coi_platinum : localStorageData[this.props.questionNum].selected_coi});
                
                           this.updateOutPatientRates(this.state.level_plan);
                               
                          setTimeout(function(){
                            this.handleResposeCheckboxPlan(this.state.level_plan, result.data.prices); 
                           this.handlePlanCoreCostAllRates(this.state.level_plan);
                      
                        }.bind(this),200
                        );
                        }
                        this.props.functionLoader.handleUpdateLoader(false);
                    })
                    .catch(error => this.setState({ error: error.message }));
                }
                .bind(this),
                5
            );
            this.setState({is_load : false});   
        }

        // Other stuff
        if (localStorageData && localStorageData[this.props.questionNum]) {

           // this.setState({ start_select_date: localStorageData[this.props.questionNum].start_select_date });
            if(localStorageData[this.props.questionNum].payment_period)
                this.setState({ payment_period: localStorageData[this.props.questionNum].payment_period });
            if(localStorageData[this.props.questionNum].payment_period_factor)
                this.setState({ payment_period_factor: localStorageData[this.props.questionNum].payment_period_factor });
            if(localStorageData[this.props.questionNum].currency)
                this.setState({ currency : localStorageData[this.props.questionNum].currency });
            if(localStorageData[this.props.questionNum].currency_sign)
                this.setState({ currency_sign : localStorageData[this.props.questionNum].currency_sign });

            setTimeout(function(){
                this.updateAnnualLimit();
            }.bind(this),5);
            

            if(localStorageData[this.props.questionNum].level_plan)
                this.setState({ level_plan: localStorageData[this.props.questionNum].level_plan });
            if(localStorageData[this.props.questionNum].selected_plan_price_orignal)
                this.setState({ selected_plan_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal});
            if(localStorageData[this.props.questionNum].selected_plan_price)
                this.setState({ selected_plan_price : localStorageData[this.props.questionNum].selected_plan_price});
            if(localStorageData[this.props.questionNum].selected_coi)
                this.setState({ selected_coi : localStorageData[this.props.questionNum].selected_coi});
            if(localStorageData[this.props.questionNum].selected_core_deductible)
                this.setState({ selected_core_deductible : localStorageData[this.props.questionNum].selected_core_deductible});
            if(localStorageData[this.props.questionNum].add_cover_usa)
                this.setState({ add_cover_usa : localStorageData[this.props.questionNum].add_cover_usa});
            if(localStorageData[this.props.questionNum].is_load)
                this.setState({ is_load : localStorageData[this.props.questionNum].is_load})

            if(localStorageData[this.props.questionNum].level_plan === 'silver')
            {
               // this.setState({silver_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal});
                //this.setState({silver_price : localStorageData[this.props.questionNum].selected_plan_price});
                this.setState({core_deductible_silver : localStorageData[this.props.questionNum].selected_core_deductible});
                this.setState({coi_silver : localStorageData[this.props.questionNum].selected_coi});
                // Second portation

                // outpatient
                this.setState({additional_benefits_payment_silver_outpatient : localStorageData[this.props.questionNum].selected_additional_benefits_payment_outpatient});
                
                //this.setState({outpatient_silver_price : localStorageData[this.props.questionNum].selected_plan_price_outpatient});
                //this.setState({outpatient_silver_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_outpatient});
              this.setState({core_deductible_outpatient_silver : localStorageData[this.props.questionNum].selected_core_deductible_outpatient});
                this.setState({coi_outpatient_silver : localStorageData[this.props.questionNum].selected_coi_outpatient});
                //handleSilverRadioPlan
               
              //  this.handleSilverRadioPlan.bind(this,'core_deductible_outpatient_silver');

                // Medical Evacuation
                this.setState({additional_benefits_payment_silver_ev : localStorageData[this.props.questionNum].selected_additional_benefits_payment_ev});
              //  this.setState({ev_silver_price : localStorageData[this.props.questionNum].selected_plan_price_ev});
                //this.setState({ev_silver_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_ev});
                
                //Health and Wellbeing

                this.setState({additional_benefits_payment_silver_hw : localStorageData[this.props.questionNum].selected_additional_benefits_payment_hw});
                //this.setState({hw_silver_price : localStorageData[this.props.questionNum].selected_plan_price_hw});
                //this.setState({hw_silver_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_hw});
                
                //Vision Dental 

                this.setState({additional_benefits_payment_silver_dv : localStorageData[this.props.questionNum].selected_additional_benefits_payment_dv});
               // this.setState({dv_silver_price : localStorageData[this.props.questionNum].selected_plan_price_dv});
                //this.setState({dv_silver_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_dv});
            }
            else if(localStorageData[this.props.questionNum].level_plan === 'platinum')
            {
               //this.setState({platinum_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal});
                //this.setState({platinum_price : localStorageData[this.props.questionNum].selected_plan_price});
                this.setState({core_deductible_platinum : localStorageData[this.props.questionNum].selected_core_deductible});
                this.setState({coi_platinum : localStorageData[this.props.questionNum].selected_coi});

                // Second portation

                // outpatient
                this.setState({additional_benefits_payment_platinum_outpatient : localStorageData[this.props.questionNum].selected_additional_benefits_payment_outpatient});
                //this.setState({outpatient_platinum_price : localStorageData[this.props.questionNum].selected_plan_price_outpatient});
               // this.setState({outpatient_platinum_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_outpatient});
                this.setState({core_deductible_outpatient_platinum : localStorageData[this.props.questionNum].selected_core_deductible_outpatient});
                this.setState({coi_outpatient_platinum : localStorageData[this.props.questionNum].selected_coi_outpatient});

                // Medical Evacuation
                this.setState({additional_benefits_payment_platinum_ev : localStorageData[this.props.questionNum].selected_additional_benefits_payment_ev});
               // this.setState({ev_platinum_price : localStorageData[this.props.questionNum].selected_plan_price_ev});
                //this.setState({ev_platinum_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_ev});
                
                //Health and Wellbeing

                this.setState({additional_benefits_payment_platinum_hw : localStorageData[this.props.questionNum].selected_additional_benefits_payment_hw});
               // this.setState({hw_platinum_price : localStorageData[this.props.questionNum].selected_plan_price_hw});
                //this.setState({hw_platinum_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_hw});
                
                //Vision Dental 

                this.setState({additional_benefits_payment_platinum_dv : localStorageData[this.props.questionNum].selected_additional_benefits_payment_dv});
               // this.setState({dv_platinum_price : localStorageData[this.props.questionNum].selected_plan_price_dv});
                //this.setState({dv_platinum_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_dv});
            }
            else if(localStorageData[this.props.questionNum].level_plan === 'gold')
            {
               //  this.setState({gold_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal});
               // this.setState({gold_price : localStorageData[this.props.questionNum].selected_plan_price});
                this.setState({core_deductible_gold : localStorageData[this.props.questionNum].selected_core_deductible});
                this.setState({coi_gold : localStorageData[this.props.questionNum].selected_coi});

                // Second portation

                // outpatient
                this.setState({additional_benefits_payment_gold_outpatient : localStorageData[this.props.questionNum].selected_additional_benefits_payment_outpatient});
              //  this.setState({outpatient_gold_price : localStorageData[this.props.questionNum].selected_plan_price_outpatient});
                //this.setState({outpatient_gold_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_outpatient});
                this.setState({core_deductible_outpatient_gold : localStorageData[this.props.questionNum].selected_core_deductible_outpatient});
                this.setState({coi_outpatient_gold : localStorageData[this.props.questionNum].selected_coi_outpatient});

                // Medical Evacuation
                this.setState({additional_benefits_payment_gold_ev : localStorageData[this.props.questionNum].selected_additional_benefits_payment_ev});
               // this.setState({ev_gold_price : localStorageData[this.props.questionNum].selected_plan_price_ev});
                //this.setState({ev_gold_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_ev});
                
                //Health and Wellbeing

                this.setState({additional_benefits_payment_gold_hw : localStorageData[this.props.questionNum].selected_additional_benefits_payment_hw});
              //  this.setState({hw_gold_price : localStorageData[this.props.questionNum].selected_plan_price_hw});
               // this.setState({hw_gold_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_hw});
                
                //Vision Dental 

                this.setState({additional_benefits_payment_gold_dv : localStorageData[this.props.questionNum].selected_additional_benefits_payment_dv});
               // this.setState({dv_gold_price : localStorageData[this.props.questionNum].selected_plan_price_dv});
               // this.setState({dv_gold_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_dv});


            }
            else if(localStorageData[this.props.questionNum].level_plan === 'care')
            {
                // this.setState({care_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal});
                //this.setState({care_price : localStorageData[this.props.questionNum].selected_plan_price});
                this.setState({core_deductible_care : localStorageData[this.props.questionNum].selected_core_deductible});
                this.setState({coi_care : localStorageData[this.props.questionNum].selected_coi});

                // outpatient
                this.setState({additional_benefits_payment_care_outpatient : localStorageData[this.props.questionNum].selected_additional_benefits_payment_outpatient});
              //  this.setState({outpatient_care_price : localStorageData[this.props.questionNum].selected_plan_price_outpatient});
                //this.setState({outpatient_care_price_orignal : localStorageData[this.props.questionNum].selected_plan_price_orignal_outpatient});
                this.setState({core_deductible_outpatient_care : localStorageData[this.props.questionNum].selected_core_deductible_outpatient});
                this.setState({coi_outpatient_care : localStorageData[this.props.questionNum].selected_coi_outpatient});

                this.setState({additional_benefits_payment_care_dv : localStorageData[this.props.questionNum].selected_additional_benefits_payment_dv});
              
            }
        }
    }

    //Hand core and cost rates acording to local storage
    handlePlanCoreCostAllRates(plan){

    if(plan == 'silver')
      {
          this.updateSilverRates();;
      }else if (plan == 'gold')
      {
        this.updateGoldRates();
      }else if (plan == 'platinum')
      {
        this.updatePlatinumRates();
     }else if (plan == 'care')
     {
        this.updateCareRates();
     }

    }

    //handle local storag checkbox calculations

    handleResposeCheckboxPlan(plan, data)
    {
      if(plan == 'silver')
      {
          this.handleSilverStorageCheckboxes(data);
      }else if (plan == 'gold')
      {
        this.handleGoldStorageCheckboxes(data);
      }else if (plan == 'platinum')
      {
        this.handlePlatinumStorageCheckboxes(data);
     }else if (plan == 'care')
     {
        this.handleCareStorageCheckboxes(data);
     }

    }
    
    handleSilverStorageCheckboxes = (data) => {

     //   var total = 0;
        var gtotal = parseFloat(data.core_silver);
        var checkBox = this.state.additional_benefits_payment_silver_outpatient;
        var checkBoxSilver_ev = this.state.additional_benefits_payment_silver_ev;

        var checkBoxSilver_hw = this.state.additional_benefits_payment_silver_hw;
        var checkBoxSilver_dv = this.state.additional_benefits_payment_silver_dv;

        // if(checkBox == true)
        // {
        //     gtotal = gtotal + parseFloat(data.outpatient_silver);
        // }

        if(checkBoxSilver_ev == true)
        {
        gtotal = gtotal + parseFloat(data.other_products_silver.ev);
        
        }

        if(checkBoxSilver_hw == true)
        {
        gtotal = gtotal + parseFloat(data.other_products_silver.hw);
        
        }

        if(checkBoxSilver_dv == true)
        {
        gtotal = gtotal + parseFloat(data.other_products_silver.dv);
        
        }

            this.setState({silver_price_orignal : gtotal});
            this.setState({silver_price : (this.changeTwoDecimal((this.state.silver_price_orignal)))});
                                    
        }

    handleGoldStorageCheckboxes = (data) => {

            //   var total = 0;
               let gtotal = parseFloat(data.core_gold);
               let checkBox = this.state.additional_benefits_payment_gold_outpatient;
               let checkBoxgold_ev = this.state.additional_benefits_payment_gold_ev;
       
               let checkBoxgold_hw = this.state.additional_benefits_payment_gold_hw;
               let checkBoxgold_dv = this.state.additional_benefits_payment_gold_dv;
       
            //    if(checkBox == true)
            //    {
            //        gtotal = gtotal + parseFloat(data.outpatient_gold);
            //    }
       
               if(checkBoxgold_ev == true)
               {
               gtotal = gtotal + parseFloat(data.other_products_gold.ev);
               
               }
       
               if(checkBoxgold_hw == true)
               {
               gtotal = gtotal + parseFloat(data.other_products_gold.hw);
               
               }
       
               if(checkBoxgold_dv == true)
               {
               gtotal = gtotal + parseFloat(data.other_products_gold.dv);
               
               }
       
                   this.setState({gold_price_orignal : gtotal});
                   this.setState({gold_price : (this.changeTwoDecimal((this.state.gold_price_orignal)))});
                                           
               }

    handlePlatinumStorageCheckboxes = (data) => {

                //   var total = 0;
                   let ptotal = parseFloat(data.core_platinum);
                   let checkBox = this.state.additional_benefits_payment_platinum_outpatient;
                   let checkBoxplatinum_ev = this.state.additional_benefits_payment_platinum_ev;
           
                   let checkBoxplatinum_hw = this.state.additional_benefits_payment_platinum_hw;
                   let checkBoxplatinum_dv = this.state.additional_benefits_payment_platinum_dv;
           
                //    if(checkBox == true)
                //    {
                //        ptotal = ptotal + parseFloat(data.outpatient_platinum);
                //    }
           
                   if(checkBoxplatinum_ev == true)
                   {
                   ptotal = ptotal + parseFloat(data.other_products_platinum.ev);
                   
                   }
           
                   if(checkBoxplatinum_hw == true)
                   {
                   ptotal = ptotal + parseFloat(data.other_products_platinum.hw);
                   
                   }
           
                   if(checkBoxplatinum_dv == true)
                   {
                   ptotal = ptotal + parseFloat(data.other_products_platinum.dv);
                   
                   }
           
                       this.setState({platinum_price_orignal : ptotal});
                       this.setState({platinum_price : (this.changeTwoDecimal((this.state.platinum_price_orignal)))});
                                               
                   }
    handleCareStorageCheckboxes = (data) => {

                    //   var total = 0;
                       let ptotal = parseFloat(data.core_care);
                       let checkBox = this.state.additional_benefits_payment_care_outpatient;
                       let checkBoxcare_ev = this.state.additional_benefits_payment_care_ev;
               
                       let checkBoxcare_hw = this.state.additional_benefits_payment_care_hw;
                       let checkBoxcare_dv = this.state.additional_benefits_payment_care_dv;
               
                    //    if(checkBox == true)
                    //    {
                    //        ptotal = ptotal + parseFloat(data.outpatient_care);
                    //    }
               
                       if(checkBoxcare_ev == true)
                       {
                       ptotal = ptotal + parseFloat(data.other_products_care.ev);
                       
                       }
               
                       if(checkBoxcare_hw == true)
                       {
                       ptotal = ptotal + parseFloat(data.other_products_care.hw);
                       
                       }
               
                       if(checkBoxcare_dv == true)
                       {
                       ptotal = ptotal + parseFloat(data.other_products_care.dv);
                       
                       }
               
                           this.setState({care_price_orignal : ptotal});
                           this.setState({care_price : (this.changeTwoDecimal((this.state.care_price_orignal)))});
                                                   
                       }
    

    // Start pasted here
    convertCurrency = (amount, fromCurrency, toCurrency, cb) => {
        var https = require('https');
        var apiKey = '37a16d27d139e4f43f0a';
        fromCurrency = encodeURIComponent(fromCurrency);
        toCurrency = encodeURIComponent(toCurrency);
        var query = fromCurrency + '_' + toCurrency;
        var url = 'https://free.currconv.com/api/v7/convert?q='
            + query + '&compact=ultra&apiKey=' + apiKey;
        https.get(url, function(res){
        var body = '';
        res.on('data', function(chunk){
            body += chunk;
        });
        res.on('end', function(){
            try 
            {
                var jsonObj = JSON.parse(body);
                var val = jsonObj[query];
                if (val) {
                    var total = val * amount;
                    cb(null, Math.round(total * 100) / 100);
                } else {
                    var err = new Error("Value not found for " + query);
                    console.log(err);
                    cb(err);
                }
               
            } catch(e) {
                console.log("Parse error: ", e);
                cb(e);
            }
            
        });
    }).on('error', function(e){
        console.log("Got an error: ", e);
        cb(e);
    });
}
    handleSubmit = (event) => {
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 
                'currency' : this.state.currency,
                'currency_sign' : this.state.currency_sign,
              //  'start_select_date' : this.state.start_select_date,
                'payment_period' : this.state.payment_period,
                'payment_period_factor' : this.state.payment_period_factor,
                'level_plan': this.state.level_plan,
                'selected_plan_price_orignal' : this.state.selected_plan_price_orignal,
                'selected_plan_price' : this.state.selected_plan_price,
                'selected_coi' : this.state.selected_coi,
                'selected_core_deductible' : this.state.selected_core_deductible,
                'add_cover_usa' : this.state.add_cover_usa,
                // second portation
                'selected_additional_benefits_payment_outpatient' : this.state.selected_additional_benefits_payment_outpatient,
                'selected_plan_price_outpatient' : this.state.selected_plan_price_outpatient,
                'selected_plan_price_orignal_outpatient' : this.state.selected_plan_price_orignal_outpatient,
                'selected_core_deductible_outpatient' : this.state.selected_core_deductible_outpatient,
                'selected_coi_outpatient' : this.state.selected_coi_outpatient,
                'selected_additional_benefits_payment_ev' : this.state.selected_additional_benefits_payment_ev,
                'selected_plan_price_ev' : this.state.selected_plan_price_ev,
                'selected_plan_price_orignal_ev' : this.state.selected_plan_price_orignal_ev,
                'selected_additional_benefits_payment_hw' : this.state.selected_additional_benefits_payment_hw,
                'selected_plan_price_hw' : this.state.selected_plan_price_hw,
                'selected_plan_price_orignal_hw' : this.state.selected_plan_price_orignal_hw,
                'selected_additional_benefits_payment_dv' : this.state.selected_additional_benefits_payment_dv,
                'selected_plan_price_dv' : this.state.selected_plan_price_dv,
                'selected_plan_price_orignal_dv' : this.state.selected_plan_price_orignal_dv,

                // end
                'is_load' : this.state.is_load
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    changeTwoDecimal =  (num) => {
        if(num)
            return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        else 
            return 0.00;
    }

    // handle Change payment period 

    handleChangePaymentPeriod = (event) => {
        event.preventDefault();
        this.props.functionLoader.handleUpdateLoader(true);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name] : value
        });

        // var factor = this.state.payment_period_factor;
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_payment_period_factor_ajax/'+value,
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            this.props.functionLoader.handleUpdateLoader(false);
            if (result.data){
                // factor = result.data;
                this.setState({payment_period_factor : result.data});
                setTimeout(function(){
                    this.update_currency_plans_rates();
                }.bind(this),100
                );
            }
        })
        .catch(error => this.setState({ error: error.message }));
    }

    update_currency_plans_rates()
    {
        this.updateSilverRates();
        this.updatePlatinumRates();
        this.updateGoldRates();
        this.updateCareRates();
        this.updateAdditionalRates("silver");
        this.updateAdditionalRates('platinum');
        this.updateAdditionalRates('gold');
        this.updateAdditionalRates('care');
    }

    updateSilverRatesPrice = () => 
    {
        // update Silver Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';
        if(this.state.currency !=='US Dollars')
        {
            this.convertCurrency(this.state.silver_price_orignal, 'USD', symbol, function(err, amount) {
                this.setState({ silver_price :  this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
            
        }
        else{
            this.setState({ silver_price : this.changeTwoDecimal(this.state.silver_price_orignal * this.state.payment_period_factor )});
        }
    }

    updatePlatinumRatesPrice = () => 
    {
        // update Platinum Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';
        if(this.state.currency !=='US Dollars')
        {
            this.convertCurrency(this.state.platinum_price_orignal, 'USD', symbol, function(err, amount) {
                this.setState({ platinum_price :  this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
        }
        else{
            this.setState({ platinum_price :  this.changeTwoDecimal(this.state.platinum_price_orignal * this.state.payment_period_factor )});
        }
    }

    updateGoldRatesPrice = () => 
    {
        // update Gold Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';
        if(this.state.currency !=='US Dollars')
        {
            this.convertCurrency(this.state.gold_price_orignal, 'USD', symbol, function(err, amount) {
                this.setState({ gold_price :  this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
        }
        else{
            this.setState({ gold_price :  this.changeTwoDecimal(this.state.gold_price_orignal * this.state.payment_period_factor )});
        }
    }

    updateCareRatesPrice = () => 
    {
        // update Care Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';
        if(this.state.currency !=='US Dollars')
        {
            this.convertCurrency(this.state.care_price_orignal, 'USD', symbol, function(err, amount) {
                this.setState({ care_price :  this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
        }
        else{
            this.setState({ care_price :  this.changeTwoDecimal(this.state.care_price_orignal * this.state.payment_period_factor )});
        }
    }

    updateSilverRates = () => {

        this.props.functionLoader.handleUpdateLoader(true);
        var formData = new FormData();
        formData.append('age', this.state.dob);
        formData.append('nationality', this.state.nationality);
        formData.append('living_country', this.state.living_country);
        formData.append('aoc', this.state.add_cover_usa);
        formData.append('deductible', this.state.core_deductible_silver);
        formData.append('coi', this.state.coi_silver);
        formData.append('currency' , this.state.currency);
        formData.append('is_api', true);
        formData.append('relation_array', this.state.relation_array);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_silver_price_ajax',
            data : formData,
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            this.props.functionLoader.handleUpdateLoader(false);
            if (result.data){
                this.setState({silver_price_orignal : result.data});
                setTimeout(function (){
                    this.updateSilverRatesPrice();
                }.bind(this),2);
                // this.setState({silver_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data * this.state.payment_period_factor)))});
            }
            else if(result.data == 0)
            {
                this.setState({silver_price_orignal : 0.00}); 
                this.setState({silver_price :  '0.00' });
            }
              this.handleAddSilverRadioValues();
        })
        .catch(error => this.setState({ error: error.message }));
    }
    updateAdditionalRates = (name_plan) => {
        this.props.functionLoader.handleUpdateLoader(true);
        var formData = new FormData();
        var deductible_name = 'core_deductible_outpatient_'+name_plan;
        var coi_name = 'coi_outpatient_'+name_plan;

        formData.append('nationality', this.state.nationality);
        formData.append('dob', this.state.dob);
        formData.append('living_country', this.state.living_country);
        formData.append('aoc', this.state.add_cover_usa);
        formData.append('deductible', this.state[deductible_name]);
        formData.append('coi', this.state[coi_name]);
        formData.append('currency', this.state.currency);
        formData.append('is_api', true);
        formData.append('relation_array', this.state.relation_array);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_additional_benfits_'+name_plan+'_price_ajax',
            data : formData,
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            if (result.data.success === true){
                var outpatient_name_orignal = 'outpatient_'+name_plan+'_price_orignal';
                var hw_name_orignal = 'hw_'+name_plan+'_price_orignal';
                var ev_name_orignal = 'ev_'+name_plan+'_price_orignal';
                var dv_name_orignal = 'dv_'+name_plan+'_price_orignal';
                this.setState({ [outpatient_name_orignal] : this.changeTwoDecimal(result.data.data.outpatient)});
                if(result.data.data.other_products != null && name_plan !== 'care')
                {
                    this.setState({[hw_name_orignal] : this.changeTwoDecimal(result.data.data.other_products.hw)});
                    this.setState({[ev_name_orignal] : this.changeTwoDecimal(result.data.data.other_products.ev)});
                    this.setState({[dv_name_orignal] : this.changeTwoDecimal(result.data.data.other_products.dv)});
                }
                setTimeout(function(){
                    
                    this.updateAdditionalRatesPrice(name_plan,'outpatient');
                    if(name_plan !='care')
                    {
                        this.updateAdditionalRatesPrice(name_plan,'hw');
                        this.updateAdditionalRatesPrice(name_plan,'ev');
                        this.updateAdditionalRatesPrice(name_plan,'dv');
                    }
                }.bind(this),5);
            }
        })
        .catch(error => this.setState({ error: error.message }));
    }


    //....radio
     handleAddSilverRadioValues(){
        
        setTimeout(
            function() {
                this.updateSilverRadioOnCoreCostselectPlan('core_deductible_outpatient_silver');
                this.updateSilverRadioOnCoreCostselectPlan('additional_benefits_payment_silver_ev');
                this.updateSilverRadioOnCoreCostselectPlan('additional_benefits_payment_silver_hw');
                this.updateSilverRadioOnCoreCostselectPlan('additional_benefits_payment_silver_dv');

            }
            .bind(this),
            5
        );
     }
     
      handleAddGoldRadioValues(){
        
        setTimeout(
            function() {
                this.updateGoldRadioOnCoreCostselectPlan('additional_benefits_payment_gold_outpatient');
                this.updateGoldRadioOnCoreCostselectPlan('additional_benefits_payment_gold_ev');
                this.updateGoldRadioOnCoreCostselectPlan('additional_benefits_payment_gold_hw');
                this.updateGoldRadioOnCoreCostselectPlan('additional_benefits_payment_gold_dv');

            }
            .bind(this),
            5
        );
     }
     
     handleAddPlatinumRadioValues(){
        
        setTimeout(
            function() {
                this.updatePlatinumRadioOnCoreCostselectPlan('additional_benefits_payment_platinum_outpatient');
                this.updatePlatinumRadioOnCoreCostselectPlan('additional_benefits_payment_platinum_ev');
                this.updatePlatinumRadioOnCoreCostselectPlan('additional_benefits_payment_platinum_hw');
                this.updatePlatinumRadioOnCoreCostselectPlan('additional_benefits_payment_platinum_dv');

            }
            .bind(this),
            5
        );
     }
     handleAddCareRadioValues(){
        
        setTimeout(
            function() {
                this.updateCareRadioOnCoreCostselectPlan('additional_benefits_payment_care_outpatient');
                this.updateCareRadioOnCoreCostselectPlan('additional_benefits_payment_care_ev');
                this.updateCareRadioOnCoreCostselectPlan('additional_benefits_payment_care_hw');
                this.updateCareRadioOnCoreCostselectPlan('additional_benefits_payment_care_dv');

            }
            .bind(this),
            5
        );
     }

    // Start outpatient and other products

    updateAdditionalRatesPrice = (name_plan, additional_name) => {
        // update Outpatient Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';
        
        var name_price_orignal = additional_name+'_'+name_plan+'_price_orignal';
        var name_price = additional_name+'_'+name_plan+'_price';

        
        if(this.state.currency !=='US Dollars')
        {
            var value = this.state[name_price_orignal] === undefined ? 0.00 : this.state[name_price_orignal];
            this.convertCurrency(value, 'USD', symbol, function(err, amount) {
                this.setState({ [name_price] : this.state.currency_sign + ' ' + this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
            
        }
        else{
            this.setState({ [name_price] : this.state.currency_sign + ' ' + this.changeTwoDecimal(this.state[name_price_orignal] * this.state.payment_period_factor )});
        }
    }
    // End outpatient and other products

    updatePlatinumRates = () => {

        this.props.functionLoader.handleUpdateLoader(true);
        var formData = new FormData();
        formData.append('age', this.state.dob);
        formData.append('nationality', this.state.nationality);
        formData.append('living_country', this.state.living_country);
        formData.append('aoc', this.state.add_cover_usa);
        formData.append('deductible', this.state.core_deductible_platinum);
        formData.append('coi', this.state.coi_platinum);
        formData.append('currency' , this.state.currency);
        formData.append('is_api', true);
        formData.append('relation_array', this.state.relation_array);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_platinum_price_ajax',
            data : formData,
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            this.props.functionLoader.handleUpdateLoader(false);
            if (result.data){
                this.setState({platinum_price_orignal : result.data});
                setTimeout(function(){
                    this.updatePlatinumRatesPrice();
                }.bind(this),2);
                // this.setState({platinum_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data * this.state.payment_period_factor)))});
            }
            else if(result.data == 0)
            {
                this.setState({platinum_price_orignal : 0.00});
                this.setState({platinum_price : '0.00'});
            }
            this.handleAddPlatinumRadioValues();
        })
        .catch(error => this.setState({ error: error.message }));
    }

    updateGoldRates = () => {
        this.props.functionLoader.handleUpdateLoader(true);
        var formData = new FormData();
        formData.append('age', this.state.dob);
        formData.append('nationality', this.state.nationality);
        formData.append('living_country', this.state.living_country);
        formData.append('aoc', this.state.add_cover_usa);
        formData.append('deductible', this.state.core_deductible_gold);
        formData.append('coi', this.state.coi_gold);
        formData.append('currency' , this.state.currency);
        formData.append('is_api', true);
        formData.append('relation_array', this.state.relation_array);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_gold_price_ajax',
            data : formData,
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            this.props.functionLoader.handleUpdateLoader(false);
            if (result.data){
                this.setState({gold_price_orignal : result.data});
                setTimeout(function(){
                    this.updateGoldRatesPrice();
                }.bind(this),2);
                // this.setState({gold_price : this.state.currency_sign+' '+(this.changeTwoDecimal((result.data * this.state.payment_period_factor)))});
            }
            else if(result.data == 0)
            {
                this.setState({gold_price_orignal : 0.00});
                this.setState({gold_price : '0.00'});
            }
            this.handleAddGoldRadioValues();
        })
        .catch(error => this.setState({ error: error.message }));
    }

    updateCareRates = () => {
        this.props.functionLoader.handleUpdateLoader(true);
        var formData = new FormData();
        formData.append('age', this.state.dob);
        formData.append('nationality', this.state.nationality);
        formData.append('living_country', this.state.living_country);
        formData.append('aoc', this.state.add_cover_usa);
        formData.append('deductible', this.state.core_deductible_care);
        formData.append('coi', this.state.coi_care);
        formData.append('currency' , this.state.currency);
        formData.append('is_api', true);
        formData.append('relation_array', this.state.relation_array);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_care_price_ajax',
            data : formData,
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            this.props.functionLoader.handleUpdateLoader(false);
            if (result.data){
                this.setState({care_price_orignal : result.data});
                setTimeout(function(){
                    this.updateCareRatesPrice();
                }.bind(this),2);
                // this.setState({care_price : this.state.currency_sign+' '+this.changeTwoDecimal((result.data * this.state.payment_period_factor))});
            }
            else if(result.data == 0){
                this.setState({care_price_orignal : 0.00});
                this.setState({care_price : '0.00'});
            }
            this.handleAddCareRadioValues();
        })
        .catch(error => this.setState({ error: error.message }));
    }

    // handle Change Add Cover Usa

    handleChangeAddCoverUsa = () => {
        if(this.state.show_price === true)
        {
            setTimeout(
                function() {
                    this.updateSilverRates();
                    this.updatePlatinumRates();
                    this.updateGoldRates();
                    this.updateCareRates();
                    this.updateAdditionalRates("silver");
                    this.updateAdditionalRates('platinum');
                    this.updateAdditionalRates('gold');
                    this.updateAdditionalRates('care');
                }
            .bind(this),5);
        }
    }
    
    updateSelectedPlanValues = (plan) => {
        if(plan === 'silver')
        {
            // alert(this.state.silver_price);
            this.setState({selected_plan_price_orignal : this.state.silver_price_orignal});
            this.setState({selected_plan_price : this.state.silver_price});
            this.setState({selected_coi : this.state.coi_silver});
            this.setState({selected_core_deductible : this.state.core_deductible_silver});

            // Second portation

            // outpatient
            this.setState({selected_additional_benefits_payment_outpatient : this.state.additional_benefits_payment_silver_outpatient});
            this.setState({selected_plan_price_outpatient : this.state.outpatient_silver_price});
            this.setState({selected_plan_price_orignal_outpatient : this.state.outpatient_silver_price_orignal});
            this.setState({selected_core_deductible_outpatient : this.state.core_deductible_outpatient_silver});
            this.setState({selected_coi_outpatient : this.state.coi_outpatient_silver});

            // Medical Evacuation
            this.setState({selected_additional_benefits_payment_ev : this.state.additional_benefits_payment_silver_ev});
            this.setState({selected_plan_price_ev : this.state.ev_silver_price});
            this.setState({selected_plan_price_orignal_ev : this.state.ev_silver_price_orignal});
            
            //Health and Wellbeing

            this.setState({selected_additional_benefits_payment_hw : this.state.additional_benefits_payment_silver_hw});
            this.setState({selected_plan_price_hw : this.state.hw_silver_price});
            this.setState({selected_plan_price_orignal_hw : this.state.hw_silver_price_orignal});
            
            //Vision Dental 

            this.setState({selected_additional_benefits_payment_dv : this.state.additional_benefits_payment_silver_dv});
            this.setState({selected_plan_price_dv : this.state.dv_silver_price});
            this.setState({selected_plan_price_orignal_dv : this.state.dv_silver_price_orignal});
        }
        if(plan === 'platinum')
        {
            this.setState({selected_plan_price_orignal : this.state.platinum_price_orignal});
            this.setState({selected_plan_price : this.state.platinum_price});
            this.setState({selected_coi : this.state.coi_platinum});
            this.setState({selected_core_deductible : this.state.core_deductible_platinum});

            // Second portation

            // outpatient
            this.setState({selected_additional_benefits_payment_outpatient : this.state.additional_benefits_payment_platinum_outpatient});
            this.setState({selected_plan_price_outpatient : this.state.outpatient_platinum_price});
            this.setState({selected_plan_price_orignal_outpatient : this.state.outpatient_platinum_price_orignal});
            this.setState({selected_core_deductible_outpatient : this.state.core_deductible_outpatient_platinum});
            this.setState({selected_coi_outpatient : this.state.coi_outpatient_platinum});

            // Medical Evacuation
            this.setState({selected_additional_benefits_payment_ev : this.state.additional_benefits_payment_platinum_ev});
            this.setState({selected_plan_price_ev : this.state.ev_platinum_price});
            this.setState({selected_plan_price_orignal_ev : this.state.ev_platinum_price_orignal});
            
            //Health and Wellbeing

            this.setState({selected_additional_benefits_payment_hw : this.state.additional_benefits_payment_platinum_hw});
            this.setState({selected_plan_price_hw : this.state.hw_platinum_price});
            this.setState({selected_plan_price_orignal_hw : this.state.hw_platinum_price_orignal});
            
            //Vision Dental 

            this.setState({selected_additional_benefits_payment_dv : this.state.additional_benefits_payment_platinum_dv});
            this.setState({selected_plan_price_dv : this.state.dv_platinum_price});
            this.setState({selected_plan_price_orignal_dv : this.state.dv_platinum_price_orignal});
        }
        if(plan === 'gold')
        {
            this.setState({selected_plan_price_orignal : this.state.gold_price_orignal});
            this.setState({selected_plan_price : this.state.gold_price});
            this.setState({selected_coi : this.state.coi_gold});
            this.setState({selected_core_deductible : this.state.core_deductible_gold});

            // Second portation

            // outpatient
            this.setState({selected_additional_benefits_payment_outpatient : this.state.additional_benefits_payment_gold_outpatient});
            this.setState({selected_plan_price_outpatient : this.state.outpatient_gold_price});
            this.setState({selected_plan_price_orignal_outpatient : this.state.outpatient_gold_price_orignal});
            this.setState({selected_core_deductible_outpatient : this.state.core_deductible_outpatient_gold});
            this.setState({selected_coi_outpatient : this.state.coi_outpatient_gold});

            // Medical Evacuation
            this.setState({selected_additional_benefits_payment_ev : this.state.additional_benefits_payment_gold_ev});
            this.setState({selected_plan_price_ev : this.state.ev_gold_price});
            this.setState({selected_plan_price_orignal_ev : this.state.ev_gold_price_orignal});
            
            //Health and Wellbeing

            this.setState({selected_additional_benefits_payment_hw : this.state.additional_benefits_payment_gold_hw});
            this.setState({selected_plan_price_hw : this.state.hw_gold_price});
            this.setState({selected_plan_price_orignal_hw : this.state.hw_gold_price_orignal});
            
            //Vision Dental 

            this.setState({selected_additional_benefits_payment_dv : this.state.additional_benefits_payment_gold_dv});
            this.setState({selected_plan_price_dv : this.state.dv_gold_price});
            this.setState({selected_plan_price_orignal_dv : this.state.dv_gold_price_orignal});
        }
        if(plan === 'care')
        {
            this.setState({selected_plan_price_orignal : this.state.care_price_orignal});
            this.setState({selected_plan_price : this.state.care_price});
            this.setState({selected_coi : this.state.coi_care});
            this.setState({selected_core_deductible : this.state.core_deductible_care});

            // Second portation

            // outpatient
            this.setState({selected_additional_benefits_payment_outpatient : this.state.additional_benefits_payment_care_outpatient});
            this.setState({selected_plan_price_outpatient : this.state.outpatient_care_price});
            this.setState({selected_plan_price_orignal_outpatient : this.state.outpatient_care_price_orignal});
            this.setState({selected_core_deductible_outpatient : this.state.core_deductible_outpatient_care});
            this.setState({selected_coi_outpatient : this.state.coi_outpatient_care});
           // this.setState({selected_plan_price_orignal_dv : this.state.dv_care_price_orignal});

            //Vision Dental 

            this.setState({selected_additional_benefits_payment_dv : this.state.additional_benefits_payment_care_dv});
            this.setState({selected_plan_price_dv : this.state.dv_care_price});
            this.setState({selected_plan_price_orignal_dv : this.state.dv_care_price_orignal});
        }
        setTimeout(function(){
            this.handleSubmit();
        }.bind(this),5
        );

    }

    handlePlanSelection(plan, event){
        this.setState({level_plan : plan});
        this.updateSelectedPlanValues(plan);
    }

    //Silver plan
    handleChangeSilverPlan = (event) => {
        event.preventDefault();
        this.handleChange(event);
        setTimeout(
            function() {
                this.updateSilverRates();
            }
            .bind(this),
            5
        );
    }

    // Platinum Plan

    handleChangePlatinumPlan = (event) => {
        this.handleChange(event);
        setTimeout(
            function() {
                this.updatePlatinumRates();
            }
            .bind(this),
            5
        );
    }
    //Gold plan

    handleChangeGoldPlan = (event) => {
        this.handleChange(event);
        setTimeout(
            function() {
                this.updateGoldRates();
            }
            .bind(this),
            5
        );
    }
    
    //Close Care

    handleChangeCloseCarePlan = (event) => {
        this.handleChange(event);
        setTimeout(
            function() {
                this.updateCareRates();
            }
            .bind(this),
            5
        );
    }

    updateAnnualLimit = () =>{
        if(this.state.currency === 'US Dollars')
        {
            this.setState({ annual_benefit_limit_silver : '$1,000,000'});
            this.setState({ annual_benefit_limit_gold : '$2,000,000'});
            this.setState({ overall_benefit_limit_care : '$500,000'});
            this.setState({ condition_limit_care : '$250,000'});
        }
        else if(this.state.currency === 'Euro'){
            this.setState({ annual_benefit_limit_silver : '€800,000'});
            this.setState({ annual_benefit_limit_gold : '€1,600,000'});
            this.setState({ overall_benefit_limit_care : '€400,000'});
            this.setState({ condition_limit_care : '€200,000'});
        }
        else if(this.state.currency === 'Sterling')
        {
            this.setState({ annual_benefit_limit_silver : '£650,000'});
            this.setState({ annual_benefit_limit_gold : '£1,300,000'});
            this.setState({ overall_benefit_limit_care : '£325,000'});
            this.setState({ condition_limit_care : '£165,000'});
        }
    }
    handleChangeCurrency = (event) => {
        this.handleChange(event);
        setTimeout(function(){
            if(this.state.currency === 'US Dollars')
            {
                this.setState({core_deductible_silver : '$375'});
                this.setState({coi_silver : '0% CI - $0 OOP'});

                this.setState({core_deductible_platinum : '$375'});
                this.setState({coi_platinum : '0% CI - $0 OOP'});

                this.setState({core_deductible_gold : '$375'});
                this.setState({coi_gold : '0% CI - $0 OOP'});

                this.setState({core_deductible_care : '$375'});
                this.setState({coi_care : '0% CI - $0 OOP'});

                // Second part

                this.setState({core_deductible_outpatient_silver : '$375'});
                this.setState({coi_outpatient_silver : '0% CI - $0 OOP'});

                this.setState({core_deductible_outpatient_platinum : '$375'});
                this.setState({coi_outpatient_platinum : '0% CI - $0 OOP'});

                this.setState({core_deductible_outpatient_gold : '$375'});
                this.setState({coi_outpatient_gold : '0% CI - $0 OOP'});

                this.setState({core_deductible_outpatient_care : '$375'});
                this.setState({coi_outpatient_care : '0% CI - $0 OOP'});

                this.setState({currency_sign : '$'});
                
            }
            else if(this.state.currency === 'Euro'){
                this.setState({core_deductible_silver : 'â‚¬275'});
                this.setState({coi_silver : '0% CI - â‚¬0 OOP'});

                this.setState({core_deductible_platinum : 'â‚¬275'});
                this.setState({coi_platinum : '0% CI - â‚¬0 OOP'});

                this.setState({core_deductible_gold : 'â‚¬275'});
                this.setState({coi_gold : '0% CI - â‚¬0 OOP'});

                this.setState({core_deductible_care : 'â‚¬275'});
                this.setState({coi_care : '0% CI - â‚¬0 OOP'});

                // Second part

                this.setState({ core_deductible_outpatient_silver : 'â‚¬370'});
                this.setState({ coi_outpatient_silver : '0% CI - â‚¬0 OOP'});

                this.setState({ core_deductible_outpatient_platinum : 'â‚¬370'});
                this.setState({ coi_outpatient_platinum : '0% CI - â‚¬0 OOP'});

                this.setState({ core_deductible_outpatient_gold : 'â‚¬370'});
                this.setState({ coi_outpatient_gold : '0% CI - â‚¬0 OOP'});

                this.setState({ core_deductible_outpatient_care : 'â‚¬370'});
                this.setState({ coi_outpatient_care : '0% CI - â‚¬0 OOP'});

                this.setState({currency_sign : '€'});
            }
            else if(this.state.currency === 'Sterling')
            {
                this.setState({core_deductible_silver : 'Â£250'});
                this.setState({coi_silver : '0% CI - Â£0 OOP'});

                this.setState({core_deductible_platinum : 'Â£250'});
                this.setState({coi_platinum : '0% CI - Â£0 OOP'});

                this.setState({core_deductible_gold : 'Â£250'});
                this.setState({coi_gold : '0% CI - Â£0 OOP'});

                this.setState({core_deductible_care : 'Â£250'});
                this.setState({coi_care : '0% CI - Â£0 OOP'});

                // Second part

                this.setState({ core_deductible_outpatient_silver : 'Â£335'});
                this.setState({ coi_outpatient_silver : '0% CI - Â£0 OOP'});

                this.setState({ core_deductible_outpatient_platinum : 'Â£335'});
                this.setState({ coi_outpatient_platinum : '0% CI - Â£0 OOP'});

                this.setState({ core_deductible_outpatient_gold : 'Â£335'});
                this.setState({ coi_outpatient_gold : '0% CI - Â£0 OOP'});

                this.setState({ core_deductible_outpatient_care : 'Â£335'});
                this.setState({ coi_outpatient_care : '0% CI - Â£0 OOP'});

                this.setState({currency_sign : '£'});
            }
            this.updateAnnualLimit();
            this.update_currency_plans_rates();
        }.bind(this),5);
    }

    // START FUNCTIONS FORM NEW THEME
    
    handleChangeMedicalRadio(cover_type, event)
    {
        this.state[cover_type] === '' || this.state[cover_type] === 'WWE-USA' 
            ? this.setState({[cover_type] : 'WW'}) 
            : this.setState({[cover_type] : 'WWE-USA'});  
            
        setTimeout(function()
        {
            this.handleChangeAddCoverUsa();
        }.bind(this),5);
    }

    
    

    handleSilverRadioPlan(cove_type, event)
    {
        
     setTimeout(function()
     {
        if(cove_type == 'core_deductible_outpatient_silver'){
                    
            var checkBox = this.state.additional_benefits_payment_silver_outpatient;
            var pric =  this.state.silver_price_orignal;
            var outpatient =  this.state.outpatient_silver_price_orignal;
            var total_outp = 0;
            if(checkBox == true){

            total_outp  = parseFloat(pric) + parseFloat(outpatient);
            this.setState({silver_price_orignal : total_outp});
        
            }else{
                total_outp  = parseFloat(pric) - parseFloat(outpatient);
                this.setState({silver_price_orignal : total_outp});
            }
           
          
        this.setState({silver_price : (this.changeTwoDecimal((total_outp)))});        
       
      }else if(cove_type == 'additional_benefits_payment_silver_ev'){
   
        var checkBox = this.state.additional_benefits_payment_silver_ev;
            var pric =  this.state.silver_price_orignal;
            var silver_medical =  this.state.ev_silver_price_orignal;
            
            var total_ev = 0;
            if(checkBox == true){

            total_ev  = parseFloat(pric) + parseFloat(silver_medical);
            this.setState({silver_price_orignal : total_ev});
        
            }else{
                total_ev  = parseFloat(pric) - parseFloat(silver_medical);
                this.setState({silver_price_orignal : total_ev});
            }
        
        this.setState({silver_price : (this.changeTwoDecimal((total_ev)))});
         
      
    }else if(cove_type == 'additional_benefits_payment_silver_hw'){
   
        var checkBox = this.state.additional_benefits_payment_silver_hw;
            var pric =  this.state.silver_price_orignal;
            var benifits =  this.state.hw_silver_price_orignal;
            
            var total_hw = 0;
            if(checkBox == true){

            total_hw  = parseFloat(pric) + parseFloat(benifits);
            this.setState({silver_price_orignal : total_hw});
        
            }else{
                total_hw  = parseFloat(pric) - parseFloat(benifits);
                this.setState({silver_price_orignal : total_hw});
            }
        
        this.setState({silver_price : (this.changeTwoDecimal((total_hw)))});
         
   
    }else if(cove_type == 'additional_benefits_payment_silver_dv'){
   
        var checkBox = this.state.additional_benefits_payment_silver_dv;
            var pric =  this.state.silver_price_orignal;
            var dv_silver_price =  this.state.dv_silver_price_orignal;
            
            var total_dv = 0;
            if(checkBox == true){

            total_dv  = parseFloat(pric) + parseFloat(dv_silver_price);
            this.setState({silver_price_orignal : total_dv});
        
            }else{
                total_dv  = parseFloat(pric) - parseFloat(dv_silver_price);
                this.setState({silver_price_orignal : total_dv});
            }
        
        this.setState({silver_price : (this.changeTwoDecimal((total_dv)))});
         
      }
    }.bind(this),5);
     
    }


    handleGoldRadioPlan(cove_type, event)
    {
        
     setTimeout(function()
     {
        if(cove_type == 'additional_benefits_payment_gold_outpatient'){
                    
            var checkBox = this.state.additional_benefits_payment_gold_outpatient;
            var pric =  this.state.gold_price_orignal;
            var outpatient =  this.state.outpatient_gold_price_orignal;
            var total = 0;
            if(checkBox == true){

            total  = parseFloat(pric) + parseFloat(outpatient);
            this.setState({gold_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(outpatient);
                this.setState({gold_price_orignal : total});
            }
          
        this.setState({gold_price : (this.changeTwoDecimal((total)))});        
       
      }else if(cove_type == 'additional_benefits_payment_gold_ev'){
   
        var checkBox = this.state.additional_benefits_payment_gold_ev;
            var pric =  this.state.gold_price_orignal;
            var medical =  this.state.ev_gold_price_orignal;
            
            var total = 0;
            if(checkBox == true){

            total  = parseFloat(pric) + parseFloat(medical);
            this.setState({gold_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(medical);
                this.setState({gold_price_orignal : total});
            }
        
        this.setState({gold_price : (this.changeTwoDecimal((total)))});
         
      
    }else if(cove_type == 'additional_benefits_payment_gold_hw'){
   
        var checkBox = this.state.additional_benefits_payment_gold_hw;
            var pric =  this.state.gold_price_orignal;
            var benifits =  this.state.hw_gold_price_orignal;
            
            var total = 0;
            if(checkBox == true){

            total  = parseFloat(pric) + parseFloat(benifits);
            this.setState({gold_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(benifits);
                this.setState({gold_price_orignal : total});
            }
        
        this.setState({gold_price : (this.changeTwoDecimal((total)))});
         
   
    }else if(cove_type == 'additional_benefits_payment_gold_dv'){
   
        var checkBox = this.state.additional_benefits_payment_gold_dv;
            var pric =  this.state.gold_price_orignal;
            var dv_price =  this.state.dv_gold_price_orignal;
            
            var total = 0;
            if(checkBox == true){

            total  = parseFloat(pric) + parseFloat(dv_price);
            this.setState({gold_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(dv_price);
                this.setState({gold_price_orignal : total});
            }
        
        this.setState({gold_price : (this.changeTwoDecimal((total)))});
         
      }
    }.bind(this),5);
     
    }


    handlePlatinumRadioPlan(cove_type, event)
    {
        
     setTimeout(function()
     {
        if(cove_type == 'additional_benefits_payment_platinum_outpatient'){
                    
            var checkBox = this.state.additional_benefits_payment_platinum_outpatient;
            var pric =  this.state.platinum_price_orignal;
            var outpatient =  this.state.outpatient_platinum_price_orignal;
            var total = 0;
            if(checkBox == true){

            total  = parseFloat(pric) + parseFloat(outpatient);
            this.setState({platinum_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(outpatient);
                this.setState({platinum_price_orignal : total});
            }
          
        this.setState({platinum_price : (this.changeTwoDecimal((total)))});        
       
      }else if(cove_type == 'additional_benefits_payment_platinum_ev'){
   
        var checkBox = this.state.additional_benefits_payment_platinum_ev;
            var pric =  this.state.platinum_price_orignal;
            var medical =  this.state.ev_platinum_price_orignal;
            
            var total = 0;
            if(checkBox == true){

            total  = parseFloat(pric) + parseFloat(medical);
            this.setState({platinum_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(medical);
                this.setState({platinum_price_orignal : total});
            }
        
        this.setState({platinum_price : (this.changeTwoDecimal((total)))});
         
      
    }else if(cove_type == 'additional_benefits_payment_platinum_hw'){
   
        var checkBox = this.state.additional_benefits_payment_platinum_hw;
            var pric =  this.state.platinum_price_orignal;
            var benifits =  this.state.hw_platinum_price_orignal;
            
            var total = 0;
            if(checkBox == true){

            total  = parseFloat(pric) + parseFloat(benifits);
            this.setState({platinum_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(benifits);
                this.setState({platinum_price_orignal : total});
            }
        
        this.setState({platinum_price : (this.changeTwoDecimal((total)))});
         
   
    }else if(cove_type == 'additional_benefits_payment_platinum_dv'){
   
        var checkBox = this.state.additional_benefits_payment_platinum_dv;
            var pric =  this.state.platinum_price_orignal;
            var dv_price =  this.state.dv_platinum_price_orignal;
            
            var total = 0;
            if(checkBox == true){

            total  = parseFloat(pric) + parseFloat(dv_price);
            this.setState({platinum_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(dv_price);
                this.setState({platinum_price_orignal : total});
            }
        
        this.setState({platinum_price : (this.changeTwoDecimal((total)))});
         
      }
      else if(cove_type == 'additional_benefits_payment_care_dv'){
   
        var checkBox_care_div = this.state.additional_benefits_payment_care_dv;
            var pric =  this.state.platinum_price_orignal;
            var dv_price =  this.state.dv_platinum_price_orignal;
            
            var total = 0;
            if(checkBox_care_div == true){

            total  = parseFloat(pric) + parseFloat(dv_price);
            this.setState({platinum_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(dv_price);
                this.setState({platinum_price_orignal : total});
            }
        
        this.setState({platinum_price : (this.changeTwoDecimal((total)))});
         
      }
    }.bind(this),5);
     
    }

    handleCareRadioPlan(cove_type, event)
    {
        
     setTimeout(function()
     {
        if(cove_type == 'additional_benefits_payment_care_outpatient'){
                    
            var checkBox = this.state.additional_benefits_payment_care_outpatient;
            var pric =  this.state.care_price_orignal;
            var outpatient =  this.state.outpatient_care_price_orignal;
            var total = 0;
            if(checkBox == true){

            total  = parseFloat(pric) + parseFloat(outpatient);
            this.setState({care_price_orignal : total});
        
            }else{
                total  = parseFloat(pric) - parseFloat(outpatient);
                this.setState({care_price_orignal : total});
            }
          
        this.setState({care_price : (this.changeTwoDecimal((total)))});        
       
      }else if(cove_type == 'additional_benefits_payment_care_dv'){
   
            var checkBox_care_dv = this.state.additional_benefits_payment_care_dv;
            var pric =  this.state.care_price_orignal;
            var dv_price =  this.state.dv_care_price_orignal;
            
            var total_dv = 0;
            if(checkBox_care_dv == true){

            total_dv  = parseFloat(pric) + parseFloat(dv_price);
            this.setState({care_price_orignal : total_dv});
        
            }else{
                total_dv  = parseFloat(pric) - parseFloat(dv_price);
                this.setState({care_price_orignal : total_dv});
            }
            this.setState({care_price : (this.changeTwoDecimal((total_dv)))});        
     
        }
    }.bind(this),5);
     
    } 

    //...........Add radio on core and cost value change
    updateSilverRadioOnCoreCostselectPlan(cove_type, event)
    {
        
     setTimeout(function()
     {
        if(cove_type == 'core_deductible_outpatient_silver'){
                    
            let checkBox = this.state.additional_benefits_payment_silver_outpatient;

            if(checkBox == true){
                let pric =  this.state.silver_price_orignal;
                let outpatient =  this.state.outpatient_silver_price_orignal;
                let total_outp = 0;

            total_outp  = parseFloat(pric) + parseFloat(outpatient);
            this.setState({silver_price_orignal : total_outp});
            this.setState({silver_price : (this.changeTwoDecimal((total_outp)))});        
        
            }
          
        
      }else if(cove_type == 'additional_benefits_payment_silver_ev'){
   
        let checkBox_ev = this.state.additional_benefits_payment_silver_ev;
           
            if(checkBox_ev == true){

            let pric =  this.state.silver_price_orignal;
            let silver_medical =  this.state.ev_silver_price_orignal;
            let total_ev  = parseFloat(pric) + parseFloat(silver_medical);
            this.setState({silver_price_orignal : total_ev});
            this.setState({silver_price : (this.changeTwoDecimal((total_ev)))});
        
            }
        
         
      
    }else if(cove_type == 'additional_benefits_payment_silver_hw'){
   
           var checkBox_hw = this.state.additional_benefits_payment_silver_hw;
           
            if(checkBox_hw == true){
            let pric =  this.state.silver_price_orignal;
            let benifits =  this.state.hw_silver_price_orignal;
            
            let total_hw  = parseFloat(pric) + parseFloat(benifits);
            this.setState({silver_price_orignal : total_hw});
            this.setState({silver_price : (this.changeTwoDecimal((total_hw)))});
        
            }
        
         
   
    }else if(cove_type == 'additional_benefits_payment_silver_dv'){
   
        let checkBox_dv = this.state.additional_benefits_payment_silver_dv;
             
            if(checkBox_dv == true){

            var pric =  this.state.silver_price_orignal;
            var dv_silver_price =  this.state.dv_silver_price_orignal;
            let  total_dv  = parseFloat(pric) + parseFloat(dv_silver_price);
            this.setState({silver_price_orignal : total_dv});
            this.setState({silver_price : (this.changeTwoDecimal((total_dv)))});
        
            }
          
      }
    }.bind(this),5);
     
    }

    updateGoldRadioOnCoreCostselectPlan(cove_type, event)
    {
        
     setTimeout(function()
     {
        if(cove_type == 'additional_benefits_payment_gold_outpatient'){
                    
            let checkBox = this.state.additional_benefits_payment_gold_outpatient;

            if(checkBox == true){
                let pric =  this.state.gold_price_orignal;
                let outpatient =  this.state.outpatient_gold_price_orignal;
                let total_outp = 0;

            total_outp  = parseFloat(pric) + parseFloat(outpatient);
            this.setState({gold_price_orignal : total_outp});
            this.setState({gold_price : (this.changeTwoDecimal((total_outp)))});        
        
            }
          
        
      }else if(cove_type == 'additional_benefits_payment_gold_ev'){
   
        let checkBox_ev = this.state.additional_benefits_payment_gold_ev;
           
            if(checkBox_ev == true){

            let pric =  this.state.gold_price_orignal;
            let gold_medical =  this.state.ev_gold_price_orignal;
            let total_ev  = parseFloat(pric) + parseFloat(gold_medical);
            this.setState({gold_price_orignal : total_ev});
            this.setState({gold_price :  (this.changeTwoDecimal((total_ev)))});
        
            }
        
         
      
    }else if(cove_type == 'additional_benefits_payment_gold_hw'){
   
           var checkBox_hw = this.state.additional_benefits_payment_gold_hw;
           
            if(checkBox_hw == true){
            let pric =  this.state.gold_price_orignal;
            let benifits =  this.state.hw_gold_price_orignal;
            
            let total_hw  = parseFloat(pric) + parseFloat(benifits);
            this.setState({gold_price_orignal : total_hw});
            this.setState({gold_price : (this.changeTwoDecimal((total_hw)))});
        
            }
        
         
   
    }else if(cove_type == 'additional_benefits_payment_gold_dv'){
   
        let checkBox_dv = this.state.additional_benefits_payment_gold_dv;
             
            if(checkBox_dv == true){

            var pric =  this.state.gold_price_orignal;
            var dv_gold_price =  this.state.dv_gold_price_orignal;
            let  total_dv  = parseFloat(pric) + parseFloat(dv_gold_price);
            this.setState({gold_price_orignal : total_dv});
            this.setState({gold_price : (this.changeTwoDecimal((total_dv)))});
        
            }
          
      }
    }.bind(this),5);
     
    }
    updatePlatinumRadioOnCoreCostselectPlan(cove_type, event)
    {
        
     setTimeout(function()
     {
        if(cove_type == 'additional_benefits_payment_platinum_outpatient'){
                    
            let checkBox = this.state.additional_benefits_payment_platinum_outpatient;

            if(checkBox == true){
                let pric =  this.state.platinum_price_orignal;
                let outpatient =  this.state.outpatient_platinum_price_orignal;
                let total_outp = 0;

            total_outp  = parseFloat(pric) + parseFloat(outpatient);
            this.setState({platinum_price_orignal : total_outp});
            this.setState({platinum_price : (this.changeTwoDecimal((total_outp)))});        
        
            }
          
        
      }else if(cove_type == 'additional_benefits_payment_platinum_ev'){
   
        let checkBox_ev = this.state.additional_benefits_payment_platinum_ev;
           
            if(checkBox_ev == true){

            let pric =  this.state.platinum_price_orignal;
            let platinum_medical =  this.state.ev_platinum_price_orignal;
            let total_ev  = parseFloat(pric) + parseFloat(platinum_medical);
            this.setState({platinum_price_orignal : total_ev});
            this.setState({platinum_price : (this.changeTwoDecimal((total_ev)))});
        
            }
        
         
      
    }else if(cove_type == 'additional_benefits_payment_platinum_hw'){
   
           var checkBox_hw = this.state.additional_benefits_payment_platinum_hw;
           
            if(checkBox_hw == true){
            let pric =  this.state.platinum_price_orignal;
            let benifits =  this.state.hw_platinum_price_orignal;
            
            let total_hw  = parseFloat(pric) + parseFloat(benifits);
            this.setState({platinum_price_orignal : total_hw});
            this.setState({platinum_price : (this.changeTwoDecimal((total_hw)))});
        
            }
        
         
   
    }else if(cove_type == 'additional_benefits_payment_platinum_dv'){
   
        let checkBox_dv = this.state.additional_benefits_payment_platinum_dv;
             
            if(checkBox_dv == true){

            var pric =  this.state.platinum_price_orignal;
            var dv_platinum_price =  this.state.dv_platinum_price_orignal;
            let  total_dv  = parseFloat(pric) + parseFloat(dv_platinum_price);
            this.setState({platinum_price_orignal : total_dv});
            this.setState({platinum_price : (this.changeTwoDecimal((total_dv)))});
        
            }
          
      }
    }.bind(this),5);
     
    }
    updateCareRadioOnCoreCostselectPlan(cove_type, event)
    {
        
     setTimeout(function()
     {
        if(cove_type == 'additional_benefits_payment_care_outpatient'){
                    
            let checkBox = this.state.additional_benefits_payment_care_outpatient;

            if(checkBox == true){
                let pric =  this.state.care_price_orignal;
                let outpatient =  this.state.outpatient_care_price_orignal;
                let total_outp = 0;

            total_outp  = parseFloat(pric) + parseFloat(outpatient);
            this.setState({care_price_orignal : total_outp});
            this.setState({care_price : (this.changeTwoDecimal((total_outp)))});        
        
            }
          
        
      }else if(cove_type == 'additional_benefits_payment_care_ev'){
   
        let checkBox_ev = this.state.additional_benefits_payment_care_ev;
           
            if(checkBox_ev == true){

            let pric =  this.state.care_price_orignal;
            let care_medical =  this.state.ev_care_price_orignal;
            let total_ev  = parseFloat(pric) + parseFloat(care_medical);
            this.setState({care_price_orignal : total_ev});
            this.setState({care_price : (this.changeTwoDecimal((total_ev)))});
        
            }
        
         
      
    }else if(cove_type == 'additional_benefits_payment_care_hw'){
   
           var checkBox_hw = this.state.additional_benefits_payment_care_hw;
           
            if(checkBox_hw == true){
            let pric =  this.state.care_price_orignal;
            let benifits =  this.state.hw_care_price_orignal;
            
            let total_hw  = parseFloat(pric) + parseFloat(benifits);
            this.setState({care_price_orignal : total_hw});
            this.setState({care_price : (this.changeTwoDecimal((total_hw)))});
        
            }
        
         
   
    }else if(cove_type == 'additional_benefits_payment_care_dv'){
   
        let checkBox_dv = this.state.additional_benefits_payment_care_dv;
             
            if(checkBox_dv == true){

            var pric =  this.state.care_price_orignal;
            var dv_care_price =  this.state.dv_care_price_orignal;
            let  total_dv  = parseFloat(pric) + parseFloat(dv_care_price);
            this.setState({care_price_orignal : total_dv});
            this.setState({care_price : (this.changeTwoDecimal((total_dv)))});
        
            }
          
      }
    }.bind(this),5);
     
    }
//end
    //    After selection

    handleSilverRadioValOnselectbar(event)
     {

        let checkBox = this.state.additional_benefits_payment_silver_outpatient;
        var checkBoxSilver_ev = this.state.additional_benefits_payment_silver_ev;

        var checkBoxSilver_hw = this.state.additional_benefits_payment_silver_hw;
        var checkBoxSilver_dv = this.state.additional_benefits_payment_silver_dv;

        
        if(checkBox == true){
                setTimeout(function ()
                {
                let pric =  this.state.silver_price_orignal;
                let outpatient =  this.state.outpatient_silver_price_orignal;
                let  total_  = parseFloat(pric) + parseFloat(outpatient);
                this.setState({silver_price_orignal : total_});
                this.setState({silver_price : (this.changeTwoDecimal((total_)))});        
          
            }.bind(this),5);
            


           }
    } 
    handlePlatiumRadioValOnselectbar(event)
    {

       let checkBox = this.state.additional_benefits_payment_platinum_outpatient;
       
       if(checkBox == true){
               setTimeout(function ()
               {
               let pric =  this.state.platinum_price_orignal;
               let outpatient =  this.state.outpatient_platinum_price_orignal;
               let  total_  = parseFloat(pric) + parseFloat(outpatient);
               this.setState({platinum_price_orignal : total_});
               this.setState({platinum_price : (this.changeTwoDecimal((total_)))});        
         
           }.bind(this),5);
           


          }
   }
   handleGoldRadioValOnselectbar(event)
    {

       let checkBox = this.state.additional_benefits_payment_gold_outpatient;
       
       if(checkBox == true){
               setTimeout(function ()
               {
               let pric =  this.state.gold_price_orignal;
               let outpatient =  this.state.outpatient_gold_price_orignal;
               let  total_  = parseFloat(pric) + parseFloat(outpatient);
               this.setState({gold_price_orignal : total_});
               this.setState({gold_price : (this.changeTwoDecimal((total_)))});        
         
           }.bind(this),5);
           


          }
   }
   handleCareRadioValOnselectbar(event)
    {

       let checkBox = this.state.additional_benefits_payment_care_outpatient;
       
       if(checkBox == true){
               setTimeout(function ()
               {
               let pric =  this.state.care_price_orignal;
               let outpatient =  this.state.outpatient_care_price_orignal;
               let  total_  = parseFloat(pric) + parseFloat(outpatient);
               this.setState({care_price_orignal : total_});
               this.setState({care_price : (this.changeTwoDecimal((total_)))});        
         
           }.bind(this),5);
           


          }
   }
//    Before selection

   handleSilverRadioValOnBeforeSelectbar(event)
     {
         
        var checkBox = this.state.additional_benefits_payment_silver_outpatient;
        
        if(checkBox == true)
        {
            let pric =  this.state.silver_price_orignal;
            let outpatient =  this.state.outpatient_silver_price_orignal;
                if(checkBox == true)
                {
                    let   total  = parseFloat(pric) - parseFloat(outpatient);
                    this.setState({silver_price_orignal : total});
                }   
        }    
        
     }
    
    handlePlatiumRadioValOnBeforeSelectbar(event)
     {
         
        var checkBox = this.state.additional_benefits_payment_platinum_outpatient;
        
        if(checkBox == true)
        {
            let pric =  this.state.platinum_price_orignal;
            let outpatient =  this.state.outpatient_platinum_price_orignal;
                if(checkBox == true)
                {
                    let   total  = parseFloat(pric) - parseFloat(outpatient);
                    this.setState({platinum_price_orignal : total});
                }   
        }    
        
     }

     handleGoldRadioValOnBeforeSelectbar(event)
     {
         
        var checkBox = this.state.additional_benefits_payment_gold_outpatient;
        
        if(checkBox == true)
        {
            let pric =  this.state.gold_price_orignal;
            let outpatient =  this.state.outpatient_gold_price_orignal;
                if(checkBox == true)
                {
                    let   total  = parseFloat(pric) - parseFloat(outpatient);
                    this.setState({gold_price_orignal : total});
                }   
        }    
        
     }
     handleCareRadioValOnBeforeSelectbar(event)
     {
         
        var checkBox = this.state.additional_benefits_payment_care_outpatient;
        
        if(checkBox == true)
        {
            let pric =  this.state.care_price_orignal;
            let outpatient =  this.state.outpatient_care_price_orignal;
                if(checkBox == true)
                {
                    let   total  = parseFloat(pric) - parseFloat(outpatient);
                    this.setState({care_price_orignal : total});
                }   
        }    
        
     }


    handBfAllCheckBox(name_plan){

        if(name_plan == 'silver')
        {
             this.handleSilverRadioValOnBeforeSelectbar();
        }else if(name_plan == 'gold')
        {
            this.handleGoldRadioValOnBeforeSelectbar();
        }else if(name_plan == 'platinum')
        {
            this.handlePlatiumRadioValOnBeforeSelectbar();
        }else if(name_plan == 'care')
        {
            this.handleCareRadioValOnBeforeSelectbar();
        }

    }

    handAfAllCheckBox(name_plan){

        if(name_plan == 'silver')
        {
             this.handleSilverRadioValOnselectbar();
        }else if(name_plan == 'gold')
        {
            this.handleGoldRadioValOnselectbar();
        }else if(name_plan == 'platinum')
        {
            this.handlePlatiumRadioValOnselectbar();
        }else if(name_plan == 'care')
        {
            this.handleCareRadioValOnselectbar();
        }

    }

    handleChangeAdditionalPlan(name_plan, event){
        this.handleChange(event);
        
        setTimeout(
            function() {
                this.updateOutPatientRates(name_plan);
            }
            .bind(this),
            5
        );
    } 

    updateOutPatientRates = (name_plan) => {
        
        var formData = new FormData();
        var deductible_name = 'core_deductible_outpatient_'+name_plan;
        var coi_name = 'coi_outpatient_'+name_plan;
        formData.append('age', this.state.dob);
        formData.append('nationality', this.state.nationality);
        formData.append('living_country', this.state.living_country);
        formData.append('aoc', this.state.add_cover_usa);
        formData.append('deductible', this.state[deductible_name]);
        formData.append('currency', this.state.currency);
        formData.append('coi', this.state[coi_name]);
        formData.append('is_api', true);
        formData.append('relation_array',this.state.relation_array);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        this.handBfAllCheckBox(name_plan);
        axios({
            method: "post",
            url: base_url+'get_quote/get_outpatient_'+ name_plan +'_price_ajax',
            data : formData,
            headers: { "content-type": "application/json" },
        })
        .then(result => {
           
            if (result.data){
                var outpatient_name_orignal = 'outpatient_'+name_plan+'_price_orignal';
                var outpatient_name = 'outpatient_'+name_plan+'_price';
                // this.setState({silver_price_orignal : result.data}); 
                this.setState({[outpatient_name_orignal] : result.data });
                setTimeout(function ()
                {
                    this.updateOutpatientRatesPrice(name_plan);
                }.bind(this),5);
                
            }
            else if(result.data == 0)
            {
                this.setState({[outpatient_name_orignal] : '0.00' });
                this.setState({[outpatient_name] : this.state.currency_sign+' '+ '0.00' });
            }
            // set outpatients values
            setTimeout(function ()
                {
                  this.handAfAllCheckBox(name_plan);
                }.bind(this),5);
          
        })
        .catch(error => this.setState({ error: error.message }));
    }

    updateOutpatientRatesPrice = (name_plan) => {
        // update Outpatient Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';

        var outpatient_price_orignal = 'outpatient_'+name_plan+'_price_orignal';
        var outpatient_price = 'outpatient_'+name_plan+'_price';

        if(this.state.currency !=='US Dollars')
        {
            var value = this.state[outpatient_price_orignal] === undefined ? 0.00 : this.state[outpatient_price_orignal];
            this.convertCurrency(value, 'USD', symbol, function(err, amount) {
                this.setState({ [outpatient_price] : this.state.currency_sign + ' ' + this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
            
        }
        else{
            this.setState({[outpatient_price] : this.state.currency_sign + ' ' + this.changeTwoDecimal(this.state[outpatient_price_orignal] * this.state.payment_period_factor )});
        }
    }
    
    // END FUNCTION FOR NEW THEME
    render() {
        var deductible = [];
        var cost_share = [];
        var outpatient_deductible = [];
        var outpatient_cost_share = [];
        if(this.state.currency === 'US Dollars')
        {
            deductible.push(
                <Fragment key={1}>
                    <option value="$0">$0</option>
                    <option value="$375">$375</option>
                    <option value="$750">$750</option>
                    <option value="$1,500">$1,500</option>
                    <option value="$3,000">$3,000</option>
                    <option value="$7,500">$7,500</option>
                    <option value="$10,000">$10,000</option>
                </Fragment>
            );
            cost_share.push(
                <Fragment key={2}>
                    <option value="0% CI - $0 OOP">0% Cost share $0 OOP Max</option>
                    <option value="10% CI - $2,000 OOP">10% Cost share $2000 OOP Max</option>
                    <option value="10% CI - $5,000 OOP">10% Cost share $5000 OOP Max</option>
                    <option value="20% CI - $2,000 OOP">20% Cost share $2000 OOP Max</option>
                    <option value="20% CI - $5,000 OOP">20% Cost share $5000 OOP Max</option>
                    <option value="30% CI - $2,000 OOP">30% Cost share $2000 OOP Max</option>
                    <option value="30% CI - $5,000 OOP">30% Cost share $5000 OOP Max</option>
                </Fragment>
            );
            outpatient_deductible.push(
                <Fragment key={3}>
                    <option value="$0">$0</option>
                    <option value="$150">$150</option>
                    <option value="$500">$500</option>
                    <option value="$1,000">$1000</option>
                    <option value="$1,500">$1500</option>
                </Fragment>
            );
            outpatient_cost_share.push(
                <Fragment key={4}>
                    <option value="0% CI - $0 OOP">0% Cost share $0 OOP Max</option>
                    <option value="10% CI - $3,000 OOP">10% Cost share $3000 OOP Max</option>
                    <option value="20% CI - $3,000 OOP">20% Cost share $3000 OOP Max</option>
                    <option value="30% CI - $3,000 OOP">30% Cost share $3000 OOP Max</option>
                </Fragment>
            );
        }
        else if(this.state.currency === 'Euro')
        {
            deductible.push(
                <Fragment key={1}>
                    <option value="â‚¬0">€0</option>
                    <option value="â‚¬275">€275</option>
                    <option value="â‚¬550">€550</option>
                    <option value="â‚¬1,100">€1100</option>
                    <option value="â‚¬2,200">€2200</option>
                    <option value="â‚¬5,500">€5500</option>
                    <option value="â‚¬7,400">€7400</option>
                </Fragment>
            );
            cost_share.push(
                <Fragment key={2}>
                    <option value="0% CI - â‚¬0 OOP">0% Cost share €0 OOP Max</option>
                    <option value="10% CI - â‚¬1,480 OOP">10% Cost share €1480 OOP Max</option>
                    <option value="10% CI - â‚¬3,700 OOP">10% Cost share €3700 OOP Max</option>
                    <option value="20% CI - â‚¬1,480 OOP">20% Cost share €1480 OOP Max</option>
                    <option value="20% CI - â‚¬3,700 OOP">20% Cost share €3700 OOP Max</option>
                    <option value="30% CI - â‚¬1,480 OOP">30% Cost share €1480 OOP Max</option>
                    <option value="30% CI - â‚¬3,700 OOP">30% Cost share €3700 OOP Max</option>
                </Fragment>
            );
            outpatient_deductible.push(
                <Fragment key={3}>
                    <option value="â‚¬0">€0</option>
                    <option value="â‚¬110">€110</option>
                    <option value="â‚¬370">€370</option>
                    <option value="â‚¬700">€700</option>
                    <option value="â‚¬1,100">€1100</option>
                </Fragment>
            );
            outpatient_cost_share.push(
                <Fragment key={4}>
                    <option value="0% CI - â‚¬0 OOP">0% Cost share €0 OOP Max</option>
                    <option value="10% CI - â‚¬2,200 OOP">10% Cost share €2200 OOP Max</option>
                    <option value="20% CI - â‚¬2,200 OOP">20% Cost share €2200 OOP Max</option>
                    <option value="30% CI - â‚¬2,200 OOP">30% Cost share €2200 OOP Max</option>
                </Fragment>
            );
        }
        else if(this.state.currency === 'Sterling')
        {
            deductible.push(
                <Fragment key={1}>
                    <option value="Â£0">£0</option>
                    <option value="Â£250">£250</option>
                    <option value="Â£500">£500</option>
                    <option value="Â£1,000">£1000</option>
                    <option value="Â£2,000">£2000</option>
                    <option value="Â£5,000">£5000</option>
                    <option value="Â£6,650">£6650</option>
                </Fragment>
            );
            cost_share.push(
                <Fragment key={2}>
                    <option value="0% CI - Â£0 OOP">0% Cost share £0 OOP Max</option>
                    <option value="10% CI - Â£1,330 OOP">10% Cost share £1330 OOP Max</option>
                    <option value="10% CI - Â£3,325 OOP">10% Cost share £3325 OOP Max</option>
                    <option value="20% CI - Â£1,330 OOP">20% Cost share £1330 OOP Max</option>
                    <option value="20% CI - Â£3,325 OOP">20% Cost share £3325 OOP Max</option>
                    <option value="30% CI - Â£1,330 OOP">30% Cost share £1330 OOP Max</option>
                    <option value="30% CI - Â£3,325 OOP">30% Cost share £3325 OOP Max</option>
                </Fragment>
            );
            outpatient_deductible.push(
                <Fragment key={1}>
                    <option value="Â£0">£0</option>
                    <option value="Â£100">£100</option>
                    <option value="Â£335">£335</option>
                    <option value="Â£600">£600</option>
                    <option value="Â£1,000">£1000</option>
                </Fragment>
            );
            outpatient_cost_share.push(
                <Fragment key={2}>
                    <option value="0% CI - Â£0 OOP">0% Cost share £0 OOP Max</option>
                    <option value="10% CI - Â£2,000 OOP">10% Cost share £2000 OOP Max</option>
                    <option value="20% CI - Â£2,000 OOP">20% Cost share £2000 OOP Max</option>
                    <option value="30% CI - Â£2,000 OOP">30% Cost share £2000 OOP Max</option>
                </Fragment>
            );
        }
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                  {/* <Loader isLoading={false} /> */}
                  
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner">
                        <h2 className="main">Select your plan</h2>
                        <form onSubmit={this.handleSubmit}>
                            {/* <div className="form-group">
                                <label>Start Date</label>
                                <input type="date" className="form-control" value={this.state.start_select_date} name="start_select_date" onChange={this.handleChange}></input>
                            </div> */}
                            {this.state.show_price=== true ?
                                <Fragment>
                                    <div className="small-width-container">
                                        <div className="form-group small-width width-xs">
                                            <label htmlFor="payment_period">Payment Period</label> 
                                            <div className="select-div">
                                                <select name="payment_period" className="form-control" id="payment_period" value={this.state.payment_period} onChange={this.handleChangePaymentPeriod}>
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="Quarterly">Quarterly</option>
                                                    <option value="Annual">Annual</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group small-width width-xs">
                                            <label htmlFor="currency">Currency</label> 
                                            <div className="select-div">
                                                <select className="form-control" name="currency" id="currency" value={this.state.currency} onChange={this.handleChangeCurrency}>
                                                    <option value="Sterling">Sterling</option>
                                                    <option value="Euro">Euro</option>
                                                    <option value="US Dollars">US Dollars</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </Fragment>
                                
                            : ''}
                            
                            
                            <div className="radio">
                                <input type="radio" name="add_cover_usa" id="add_cover_usa" value="WW" checked={this.state.add_cover_usa === 'WW'} onClick={this.handleChangeMedicalRadio.bind(this,'add_cover_usa')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="add_cover_usa">Include USA Coverage</label>
                            </div>
                        </form>
                    </div>
                    {/* Second Inner */}
                    {/* Plan Sections started */}
                    <div className="inner choose-insurance">
                        <div className="container">
                            <div className="row">
                                {/* Start Close Care */}
                                <div className="col-sm-6 col-md-3">
                                    <div className="col col4">
                                        <div className="heading">Close Care </div>
                                        <form>
                                            <p>
                                                An essential health plan created for those who need coverage only in their country of residence, and their country of nationality. See all benefits
                                            </p>
                                            <div className="information_points p2">
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Overall benefit limit: {this.state.overall_benefit_limit_care}</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Condition limit: {this.state.condition_limit_care}</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Semi-private room</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Full cancer care</p>
                                                <p><i className="fa fa-times" aria-hidden="true"></i> No Maternity</p>
                                                <p><i className="fa fa-times" aria-hidden="true"></i> No Medical Evacuation</p>
                                                <p><i className="fa fa-times" aria-hidden="true"></i> No Travel Safety service</p>
                                            </div>
                                            {this.state.show_price === true ?
                                                <Fragment>
                                                    <div className="form-group">
                                                        <label>Core Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="core_deductible_care" name="core_deductible_care" value={this.state.core_deductible_care} onChange={this.handleChangeCloseCarePlan}>
                                                                {deductible}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Cost share after Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="coi_care" name="coi_care" value={this.state.coi_care} onChange={this.handleChangeCloseCarePlan}>
                                                                {cost_share}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_care_outpatient" className="styled" onClick={this.handleCareRadioPlan.bind(this,'additional_benefits_payment_care_outpatient')} checked={this.state.additional_benefits_payment_care_outpatient} name="additional_benefits_payment_care_outpatient" onChange={this.handleChangeRadio} type="checkbox"></input>
                                                            <label htmlFor="additional_benefits_payment_care_outpatient">Outpatient <span>{this.state.outpatient_care_price}</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Outpatient Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="core_deductible_outpatient_care" name="core_deductible_outpatient_care" value={this.state.core_deductible_outpatient_care} onChange={this.handleChangeAdditionalPlan.bind(this,'care')}>
                                                                {outpatient_deductible}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Outpatient Cost share</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="coi_outpatient_care" name="coi_outpatient_care" value={this.state.coi_outpatient_care} onChange={this.handleChangeAdditionalPlan.bind(this,'care')}>
                                                                {outpatient_cost_share}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_care_dv" className="styled" checked={this.state.additional_benefits_payment_care_dv} name="additional_benefits_payment_care_dv" onClick={this.handleCareRadioPlan.bind(this,'additional_benefits_payment_care_dv')} type="checkbox" onChange={this.handleChangeRadio}></input>
                                                            <label htmlFor="additional_benefits_payment_care_dv">
                                                               Dental Care and Treatment
                                                                <span>{this.state.dv_care_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="medical-1" className="styled" type="checkbox"></input>
                                                            <label htmlFor="medical-1">
                                                                Medical evacuation
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="health-1" className="styled" type="checkbox"></input>
                                                            <label htmlFor="health-1">
                                                                Health and wellbeing
                                                            </label>
                                                        </div>
                                                    </div>
                                                    </div> */}
                                                </Fragment>
                                            : '' }
                                        </form>
                                        {this.state.show_price === true ?
                                            <div className="monthly">
                                                {this.state.payment_period} <span>{this.state.care_price}</span>
                                            </div>
                                        : ''}
                                        <a className="apply" onClick={this.handlePlanSelection.bind(this,'care')}>Apply</a>
                                    </div>
                                </div>
                                {/* End Close Care */}
                                {/* Start Silver */}
                                <div className="col-sm-6 col-md-3">
                                    <div className="col col1">
                                        <div className="heading">Silver</div>
                                        <form>
                                            <p>
                                                The core benefits on the Silver plan provide international inpatient and daypatient cover, including hospital stays and treatment. See all benefits
                                            </p>
                                            <div className="information_points p2">
                                                <p> <i className="fa fa-check" aria-hidden="true"></i> Annual benefit limit: {this.state.annual_benefit_limit_silver}</p>
                                                <p> <i className="fa fa-check" aria-hidden="true"></i> Semi-private room</p>
                                                <p> <i className="fa fa-check" aria-hidden="true"></i> Full cancer care</p>
                                                <p> <i className="fa fa-check" aria-hidden="true"></i> Newborn care cover</p>
                                            </div>
                                            {this.state.show_price === true ?
                                                <Fragment>
                                                    <div className="form-group">
                                                        <label>Core Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="core_deductible_silver" name="core_deductible_silver" value={this.state.core_deductible_silver} onChange={this.handleChangeSilverPlan}>
                                                                {deductible}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Cost share after Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="coi_silver" name="coi_silver" value={this.state.coi_silver} onChange={this.handleChangeSilverPlan}>
                                                                {cost_share}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_silver_outpatient" className="styled" name="additional_benefits_payment_silver_outpatient" checked={this.state.additional_benefits_payment_silver_outpatient} type="checkbox" onClick={this.handleSilverRadioPlan.bind(this,'core_deductible_outpatient_silver')}  onChange={this.handleChangeRadio}></input>
                                                            <label htmlFor="additional_benefits_payment_silver_outpatient">Outpatient <span>{this.state.outpatient_silver_price}</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Outpatient Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="core_deductible_outpatient_silver" name="core_deductible_outpatient_silver" value={this.state.core_deductible_outpatient_silver} onChange={this.handleChangeAdditionalPlan.bind(this,'silver')}>
                                                                {outpatient_deductible}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Outpatient Cost share</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="coi_outpatient_silver" name="coi_outpatient_silver" value={this.state.coi_outpatient_silver} onChange={this.handleChangeAdditionalPlan.bind(this,'silver')} >
                                                                {outpatient_cost_share}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_silver_ev" className="styled" checked={this.state.additional_benefits_payment_silver_ev} name="additional_benefits_payment_silver_ev" type="checkbox" onClick={this.handleSilverRadioPlan.bind(this,'additional_benefits_payment_silver_ev')} onChange={this.handleChangeRadio}></input>
                                                            <label htmlFor="additional_benefits_payment_silver_ev">
                                                                Medical evacuation
                                                                <span>{this.state.ev_silver_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_silver_hw" className="styled" checked={this.state.additional_benefits_payment_silver_hw} name="additional_benefits_payment_silver_hw" onClick={this.handleSilverRadioPlan.bind(this,'additional_benefits_payment_silver_hw')} type="checkbox" onChange={this.handleChangeRadio}></input>
                                                            <label htmlFor="additional_benefits_payment_silver_hw">
                                                                Health and wellbeing
                                                                <span>{this.state.hw_silver_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_silver_dv" className="styled" checked={this.state.additional_benefits_payment_silver_dv} name="additional_benefits_payment_silver_dv" onClick={this.handleSilverRadioPlan.bind(this,'additional_benefits_payment_silver_dv')} type="checkbox" onChange={this.handleChangeRadio}></input>
                                                            <label htmlFor="additional_benefits_payment_silver_dv">
                                                                Vision and Dental
                                                                <span>{this.state.dv_silver_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            : '' }
                                        </form>
                                        {this.state.show_price === true ?
                                            <div className="monthly">
                                                {this.state.payment_period} <span id="planPriced" >{this.state.silver_price}</span>
                                            </div>
                                        : ''}
                                        <a className="apply" onClick={this.handlePlanSelection.bind(this,'silver')}>Apply</a>
                                    </div>
                                    </div>
                                {/* End Silver */}
                                {/* Start Gold */}
                                <div className="col-sm-6 col-md-3">
                                    <div className="col col2">
                                        <div className="heading">Gold</div>
                                        <form>
                                            <p>
                                                Comprehensive coverage, with higher overall annual limits and added benefits including inpatient maternity care. See all benefits
                                            </p>
                                            <div className="information_points p2">
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Annual benefit limit: {this.state.annual_benefit_limit_gold}</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Private room</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Full cancer care</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Inpatient Maternity</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Mental Health care paid in full</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Access to our Safe Travel app</p>
                                            </div>
                                            {this.state.show_price === true ?
                                                <Fragment>
                                                    <div className="form-group">
                                                        <label>Core Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="core_deductible_gold" name="core_deductible_gold" value={this.state.core_deductible_gold} onChange={this.handleChangeGoldPlan}>
                                                                {deductible}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Cost share after Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="coi_gold" name="coi_gold" value={this.state.coi_gold} onChange={this.handleChangeGoldPlan}>
                                                                {cost_share}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_gold_outpatient" className="styled" checked={this.state.additional_benefits_payment_gold_outpatient} onClick={this.handleGoldRadioPlan.bind(this,'additional_benefits_payment_gold_outpatient')} name="additional_benefits_payment_gold_outpatient" defaultChecked={this.state.additional_benefits_payment_gold_outpatient ? 'checked' : ''} type="checkbox" onChange={this.handleChangeRadio}></input>
                                                            <label htmlFor="additional_benefits_payment_gold_outpatient">Outpatient <span>{this.state.outpatient_gold_price}</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Outpatient Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="core_deductible_outpatient_gold" name="core_deductible_outpatient_gold"  value={this.state.core_deductible_outpatient_gold} onChange={this.handleChangeAdditionalPlan.bind(this,'gold')}>
                                                                {outpatient_deductible}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Outpatient Cost share</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="coi_outpatient_gold" name="coi_outpatient_gold"  value={this.state.coi_outpatient_gold} onChange={this.handleChangeAdditionalPlan.bind(this,'gold')}>
                                                                {outpatient_cost_share}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_gold_ev" className="styled" checked={this.state.additional_benefits_payment_gold_ev} onClick={this.handleGoldRadioPlan.bind(this,'additional_benefits_payment_gold_ev')} name="additional_benefits_payment_gold_ev" type="checkbox" onChange={this.handleChangeRadio}></input>
                                                            <label htmlFor="additional_benefits_payment_gold_ev">
                                                                Medical evacuation
                                                                <span>{this.state.ev_gold_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_gold_hw" className="styled" checked={this.state.additional_benefits_payment_gold_hw} name="additional_benefits_payment_gold_hw" onClick={this.handleGoldRadioPlan.bind(this,'additional_benefits_payment_gold_hw')} type="checkbox" onChange={this.handleChangeRadio}></input>
                                                            <label htmlFor="additional_benefits_payment_gold_hw">
                                                                Health and wellbeing
                                                                <span>{this.state.hw_gold_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_gold_dv" className="styled" checked={this.state.additional_benefits_payment_gold_dv} name="additional_benefits_payment_gold_dv" type="checkbox" onClick={this.handleGoldRadioPlan.bind(this,'additional_benefits_payment_gold_dv')} onChange={this.handleChangeRadio}></input>
                                                            <label htmlFor="additional_benefits_payment_gold_dv">
                                                                Vision and Dental
                                                                <span>{this.state.dv_gold_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            : '' }
                                        </form>
                                        {this.state.show_price === true ?
                                            <div className="monthly">
                                                {this.state.payment_period} <span>{this.state.gold_price}</span>
                                            </div>
                                        : ''}
                                        <a className="apply" onClick={this.handlePlanSelection.bind(this,'gold')}>Apply</a>
                                    </div>
                                </div>
                                {/* End Gold */}
                                {/* Start Platinum */}
                                <div className="col-sm-6 col-md-3">
                                    <div className="col col3">
                                        <div className="heading">Platinum</div>
                                        <form>
                                            <p>
                                            Our highest level of coverage offers an unlimited overall annual limit, with most benefits being paid in full. See all benefits
                                            </p>
                                            <div className="information_points p2 ">
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Annual benefit limit: Unlimited</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Private room</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Full cancer care</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Inpatient Maternity</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Mental Health care paid in full</p>
                                                <p><i className="fa fa-check" aria-hidden="true"></i> Access to our Safe Travel app</p>
                                            </div>
                                            {this.state.show_price === true ?
                                                <Fragment>
                                                    <div className="form-group">
                                                        <label>Core Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="core_deductible_platinum" name="core_deductible_platinum" value={this.state.core_deductible_platinum} onChange={this.handleChangePlatinumPlan}>
                                                                {deductible}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Cost share after Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="coi_platinum" name="coi_platinum" value={this.state.coi_platinum} onChange={this.handleChangePlatinumPlan}>
                                                                {cost_share}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_platinum_outpatient" className="styled" checked={this.state.additional_benefits_payment_platinum_outpatient} onClick={this.handlePlatinumRadioPlan.bind(this,'additional_benefits_payment_platinum_outpatient')} name="additional_benefits_payment_platinum_outpatient" onChange={this.handleChangeRadio} type="checkbox"></input>
                                                            <label htmlFor="additional_benefits_payment_platinum_outpatient">Outpatient <span>{this.state.outpatient_platinum_price}</span></label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Outpatient Deductible</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="core_deductible_outpatient_platinum" name="core_deductible_outpatient_platinum" value={this.state.core_deductible_outpatient_platinum}  onChange={this.handleChangeAdditionalPlan.bind(this,'platinum')}>
                                                                {outpatient_deductible}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Outpatient Cost share</label>
                                                        <div className="select-div">
                                                            <select className="form-control" id="coi_outpatient_platinum" name="coi_outpatient_platinum" value={this.state.coi_outpatient_platinum} onChange={this.handleChangeAdditionalPlan.bind(this,'platinum')}>
                                                                {outpatient_cost_share}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_platinum_ev" className="styled" checked={this.state.additional_benefits_payment_platinum_ev} onClick={this.handlePlatinumRadioPlan.bind(this,'additional_benefits_payment_platinum_ev')} name="additional_benefits_payment_platinum_ev" onChange={this.handleChangeRadio} type="checkbox"></input>
                                                            <label htmlFor="additional_benefits_payment_platinum_ev">
                                                                Medical evacuation
                                                                <span>{this.state.ev_platinum_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_platinum_hw" className="styled" checked={this.state.additional_benefits_payment_platinum_hw} name="additional_benefits_payment_platinum_hw" onClick={this.handlePlatinumRadioPlan.bind(this,'additional_benefits_payment_platinum_hw')} onChange={this.handleChangeRadio} type="checkbox"></input>
                                                            <label htmlFor="additional_benefits_payment_platinum_hw">
                                                                Health and wellbeing
                                                                <span>{this.state.hw_platinum_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox checkbox-primary">
                                                            <input id="additional_benefits_payment_platinum_dv" className="styled" checked={this.state.additional_benefits_payment_platinum_dv} onClick={this.handlePlatinumRadioPlan.bind(this,'additional_benefits_payment_platinum_dv')} name="additional_benefits_payment_platinum_dv" onChange={this.handleChangeRadio} type="checkbox"></input>
                                                            <label htmlFor="additional_benefits_payment_platinum_dv">
                                                                Vision and Dental
                                                                <span>{this.state.dv_platinum_price}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            : '' }
                                        </form>
                                        {this.state.show_price === true ?
                                            <div className="monthly">
                                                {this.state.payment_period} <span>{this.state.platinum_price}</span>
                                            </div>
                                        : ''}
                                        <a className="apply" onClick={this.handlePlanSelection.bind(this,'platinum')}>Apply</a>
                                    </div>
                                </div>
                                {/* End Platinum */}
                            </div>
                        </div>
                    </div>
                    {/* Plan Section ended */}
                    <div className="inner"></div>
                </div>
        </Fragment>
        );
    }
} 