import React, { Fragment, useState } from 'react';
import HeaderQuestion from '../../common/component/HeaderQuestion';
import Sidebar from '../pages/login/Sidebar';
import CA1 from '../pages/login/CA1';
import CA2 from '../pages/login/CA2';
import CA3 from '../pages/login/CA3';
import CA4 from '../pages/login/CA4';
import CA5 from '../pages/login/CA5';
import CA6 from '../pages/login/CA6';
import CA7 from '../pages/login/CA7';
import Loader from '../../../loader/Loader';
import { Redirect } from 'react-router-dom';

function QuestionContainerAccount(props) {

    let number = props.match.params.num;
    
    const [loader, setLoader] = useState(false);
	const handleUpdateLoader = (ajaxState) =>{
		setLoader(ajaxState);
	}
	const localStorageData = JSON.parse(localStorage.getItem('create-account'));
	
	if(number !== '1' && !localStorageData)
		return (<Redirect to={"/create-account/1"} />)
	let component = <CA1 questionNum={number}/>;
	if(number === '1')
        component = <CA1 questionNum={number}/>;
    if(number === '2')
        component = <CA2 questionNum={number}/>;
    if(number === '3')
        component = <CA3 questionNum={number}/>;
    if(number === '4')
        component = <CA4 questionNum={number}/>;
    if(number === '5')
        component = <CA5 questionNum={number}/>;
    if(number === '6')
        component = <CA6 questionNum={number}/>;
    if(number === '7')
		component = <CA7 questionNum={number} functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;
	if(localStorageData && localStorageData.current_step && number > localStorageData.current_step){
		return (<Redirect to={"/create-account/"+localStorageData.current_step} />)	
	}
	return (
		<Fragment>
            <Loader isLoading={loader}></Loader>
            <div className="wrapper login-wrapper partner">
                <section className="main">
                    <div className="container">
                        <Sidebar/>
                        {component}
                    </div>
                </section>
            </div>
        </Fragment>
	);
}
export default QuestionContainerAccount;
