import React, { Component, Fragment } from 'react'
import NextButton from '../../../business/component/NextButton';
import { handleChange, handleBlur, handleChangeRadio } from '../../../common_function/common_function';
import BackButton from '../../../business/component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
export default class CA3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'worker_type' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
    }
    componentDidMount() {
        const localStorageData = JSON.parse(localStorage.getItem('create-account'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ worker_type: localStorageData[this.props.questionNum].worker_type });
        }
    }
    handleChangeWorkerType(name, event)
    {
        this.setState({worker_type : name});
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('create-account'));
            const forms_bus_name = { 
                'worker_type' : this.state.worker_type
            };

            if(localStorage.getItem('create-account')){
                localStorageData[this.props.questionNum] = forms_bus_name;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('create-account', localStorageData);
            }
            else
            {
                var url_forms_info = { 'current_step': 2 ,'1': forms_bus_name };
                url_forms_info = JSON.stringify(url_forms_info);
                localStorage.setItem('create-account', url_forms_info);
            }
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/create-account/"+next} />)
        }
        return (
            <div className="col-md-7 col-sm-12 right">
                <div className="login-logo visible-sm visible-xs">
                    <a className="navbar-brand" href="index.html"><img src="/assets/images/logo-login-blue2.svg" alt=""></img></a>
                </div>
                <div className="clearfix"></div>
                <h3>Create Xpat account</h3>
                <p>Create account to start using the full range of advantages today.</p>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        {this.validator.message('worker_type', this.state.worker_type, 'required')}
                    </div>
                    <div className="radio card" onClick={this.handleChangeWorkerType.bind(this,'I am an Insurance Broker')}>
                        <input type="radio" name="worker_type" value="I am an Insurance Broker" checked={this.state.worker_type === 'I am an Insurance Broker'}  onChange={this.handleChangeRadio}></input>
                        <label>I am an Insurance Broker</label>
                    </div>
                    <div className="radio card" onClick={this.handleChangeWorkerType.bind(this,'I am a Blogger')}>
                        <input type="radio" name="worker_type" value="I am a Blogger" checked={this.state.worker_type === 'I am a Blogger'}  onChange={this.handleChangeRadio}></input>
                        <label>I am a Blogger</label>
                    </div>
                    <div className="radio card" onClick={this.handleChangeWorkerType.bind(this,'Other')}>
                        <input type="radio" name="worker_type" value="worker_type" value="Other" checked={this.state.worker_type === 'Other'} onChange={this.handleChangeRadio}></input>
                        <label>Other</label>
                    </div>
                    <button type="submit" className={this.state.worker_type != '' ? 'btn btn-brand btn-block'  : 'btn btn-brand btn-block btn-disabled'}>Next</button>
                </form> 
            </div>
        );
    }
} 