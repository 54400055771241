import React, { Component} from 'react';
export default class ContactInfo extends Component {
    render()
    {
      var handImage = {
        backgroundImage: 'url(assets/images/contact-image.png)',
    };
        return(
          <section class="goal">
          <div class="goal-row">
            <div class="goal-col">
              <div class="text">
                <h4>Project Aware</h4>
                <div class="icon"><img src="assets/images/logo-chariry1.png" alt="" /></div>
                <p>Project Aware creates global change for our oceans by keeping them clean, spearheading policies that help keep our oceans and its wildlife safe.</p>
              </div>
            </div> 

            <div class="goal-col">
              <div class="text">
                <h4>Gift of Adoption</h4>
                <div class="icon"><img src="assets/images/logo-chariry2.png" alt="" /></div>
                <p>Gift of Adoption helps finalize the adoption process for children and their families with adoption assistance grants, to make sure the adoption process goes smoothly.</p>
              </div>
            </div> 

            <div class="goal-col">
              <div class="text">
                <h4>Yours humanly</h4>
                <div class="icon"><img src="assets/images/logo-chariry3.png" alt="" /></div>
                <p>Yours Humanly assists children in need around the world by providing them with whole K-12 scholarships, educational resources, and programs to enrich the learning process for students and schools.</p>
              </div>
            </div> 
            <div class="clearfix"></div>
          </div>
        </section> 
        );
    }
}