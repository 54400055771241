import React, { Component } from "react";
import { handleChange } from '../../common_function/common_function';
import { Link } from 'react-router-dom';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.handleChange = handleChange.bind(this);
    }
    render() {
        var base_url = process.env.REACT_APP_API_URL;
       var myAccountLink =  base_url+'users/login';
       var partnerLink =  base_url+'users/login';

        return (
            <footer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1">
                            <img className="footer-logo" src="assets/images/logo-footer.png" alt=""></img>
                        </div>
                        <div className="col-xs-4 col-md-2">
                            <h4>Details</h4>
                            <ul>
                                <li><a href="/products">Products</a></li>
                                <li><a href="/partner">Partners</a></li>
                            </ul>
                        </div>
                        <div className="col-xs-4 col-md-2">
                            <h4>Company</h4>
                            <ul>
                                {/*<li><a href="/contact">Contact</a></li>*/}
                                <li><a href="/about"> About</a></li>
                                <li><a href="/blog">Blog</a></li>
                                
                            </ul>
                        </div>
                        <div className="col-xs-4 col-md-2">
                            <h4>Support</h4>
                            <ul>
                                <li><a href="/faq">FAQ</a></li>
                                <li><a href={`${myAccountLink}`}>My Account</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-md-offset-1 newsletter">
                            <h4>WANT TO LEARN MORE?</h4>
                            <p>Join our newsletter to recieve updates on insurance news</p>
                            <form>
                                <input type="text" className="form-control" placeholder="Enter your email"></input>
                                <button type="button" className="btn btn-brand">Sign Up</button>
                            </form>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="left">
                             Copyright © Xpat All rights reserved
                        </div>
                        <div className="right">
                            <ul>
                                <li><a href=""><img src="assets/images/icons/linkedin.svg" alt=""></img></a></li>
                                <li><a href=""><img src="assets/images/icons/facebook.svg" alt=""></img></a></li>
                                <li><a href=""><img src="assets/images/icons/instagram.svg" alt=""></img></a></li>
                                <li><a href=""><img src="assets/images/icons/twitter.svg" alt=""></img></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default Footer;