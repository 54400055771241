import React, { Component, Fragment} from 'react';
export default class OurProducts extends Component {

  handleSidebar = (event) =>
    {
        event.preventDefault();
        var last_active =  document.getElementsByClassName("active");
        console.clear();
        console.log(last_active);

        last_active[0].classList.remove('active');
        last_active[0].classList.remove('active');

          var attribute = event.target.attributes.getNamedItem('data-id').value;
          var menu_bar = document.getElementById(attribute);
              menu_bar.classList.add("active");
              
          var li = event.target.attributes.getNamedItem('data-current').value;
          var li_bar = document.getElementById(li);
            li_bar.classList.add("active");
      
    }
    render()
    {
        return(

          <Fragment>
             <section className="plans-products">
            <div className="container">
              <h2>Our Products</h2>
              <h4>Home is behind, the world ahead and there are many paths to tread through shadows to the edge.</h4>

              <div className="switch-container">
                  <ul className="switch-tabs">
                      <li><a data-name="bronze" href="javascript:void(0)" className="active">Bronze</a></li>
                      <li><a href="javascript:void(0)" data-name="silver">Silver</a></li>
                      <li><a href="javascript:void(0)" data-name="gold">Gold</a></li>
                      <li><a href="javascript:void(0)"data-name="platinum">Platinum</a></li>
                  </ul>
              </div>
              <div className="prod-row">
                <div className="prod-col prod-col1 bronze">
                  <div className="inner">
                    <h3>Bronze Plan</h3>
                    <h4>$500</h4>
                    <p>Country of nationality plus country of residence</p>
                    <span>Overall annual benefit up to $ 500, 000</span>
  
                    <div className="links-bottom">
                      <a href="javascript:void(0)" className="summary">Summary</a>
                      <a href="javascript:void(0)" className="document">Full Document</a>
                    </div>
                  </div>
                </div>
                <div className="prod-col prod-col2 silver">
                  <div className="inner">
                    <h3>Silver Plan</h3>
                    <h4>$500</h4>
                    <p>Country of nationality plus country of residence</p>
                    <span>Overall annual benefit up to $ 500, 000</span>

                    <div className="links-bottom">
                      <a href="javascript:void(0)" className="summary">Summary</a>
                      <a href="javascript:void(0)" className="document">Full Document</a>
                    </div>
                  </div>
                </div>
                <div className="prod-col prod-col3 gold">
                  <div className="inner">
                    <h3>Golden Plan</h3>
                    <h4>$500</h4>
                    <p>Country of nationality plus country of residence</p>
                    <span>Overall annual benefit up to $ 500, 000</span>

                    <div className="links-bottom">
                      <a href="javascript:void(0)" className="summary">Summary</a>
                      <a href="javascript:void(0)" className="document">Full Document</a>
                    </div>
                  </div>
                </div>
                <div className="prod-col prod-col4 platinum">
                  <div className="inner">
                    <h3>Platinum Plan</h3>
                    <h4>$500</h4>
                    <p>Country of nationality plus country of residence</p>
                    <span>Overall annual benefit up to $ 500, 000</span>

                    <div className="links-bottom">
                      <a href="javascript:void(0)" className="summary">Summary</a>
                      <a href="javascript:void(0)" className="document">Full Document</a>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
              </div> 
              {/* <!--prod-row--> */}
            </div>
          </section> 
          {/* <!--plans-products--> */}

          </Fragment>
                   
        );
    }
}