import React, { Component } from "react";
import { Fragment } from "react";
import { Redirect } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
// import { slide as Menu } from 'react-burger-menu'
import { handleChange, handleBlur } from '../../common_function/common_function';
import SimpleReactValidator from 'simple-react-validator';
import Thanks from '../../common/component/Thanks';
import axios from 'axios';
class HeaderQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'lskey' : this.props.lsKey,
            'thanksToReferrer' : false,
            'email' : '',
            'phone' : '',
            'emailError' : '',
            'phoneError': '',
            'redirectToReferrer' : false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    handleSaveApplication = () => {
        this.props.functionLoader.handleUpdateLoader(true);

        if (this.validator.allValid()) {
            var base_url = process.env.REACT_APP_API_URL;
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            let pending_xpat_id = JSON.parse(localStorage.getItem('pending_xpat_id'));
            const min = 1;
            const max = 1000;
        var rand = Math.floor(Math.random()*(max-min+1)+min)
           const forms_zip = { 
                'email': this.state.email,
                'phone' : this.state.phone,
                'pending_xpat_id' : JSON.stringify(pending_xpat_id),
                'pending_quote_status' :'pending',
                'pending_quote_id' :'000'+rand,
                'form_json_array' : JSON.stringify(localStorageData)
            };
            localStorageData[localStorageData.current_step] = forms_zip;
          
            axios({
                method: "post",
                url: base_url + 'leads/xpat_quote_ajax',
                data : {"dataArray" : localStorageData},
                headers: { "content-type": "application/json" },
            })
            .then(result => {
                this.props.functionLoader.handleUpdateLoader(false);

                if (result.data.success === true){
                    alert("Data posted successfully");
                    localStorage.removeItem('xpatquote');
                    localStorage.removeItem('pending_xpat_id');
                    document.getElementById('emailModal').style.display = "none";
                    this.setState({thanksToReferrer : true});
                }
                
            })
            .catch(error => this.setState({ error: error.message }));
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleStartOver = () => {
		confirmAlert({
			title: 'Are you sure ?',
			message: 'You will lose all your data on this application and the application will begin from where you started',
			buttons: [
				{
					label: 'No'
				},
				{
					label: 'Yes',
					onClick: () => {
						const localStorageData = JSON.parse(localStorage.getItem(this.state.lskey));
						localStorage.removeItem(this.state.lskey);
						if (localStorageData && localStorageData['1']) {
							this.setState({ 'redirectUrl': localStorageData['1'].startoverRedirect });
							this.setState({ 'redirectToReferrer': true });
						}
					}
				}
			]
        });
    }
    handlePopupShow = (selector, event) => {
        event.preventDefault();
        document.getElementById(selector).style.display = "block";
    }
    handlePopupClose = (selector, event) => {
        if(event)
            event.preventDefault();
        document.getElementById(selector).style.display = "none";
    }
    handleSidebar = () =>
    {
        var menu_bar = document.getElementById("menu-btn");
        var aside = document.getElementById("aside_bar");
        var checkclass = menu_bar.classList.contains('menu-active');
        if(checkclass === false)
        {
            menu_bar.classList.add("menu-active");
            aside.classList.add("active");
        }
        else
        {
            menu_bar.classList.remove("menu-active");
            aside.classList.remove("active");
        }
    }
    render() {
        if (this.state.redirectToReferrer)
        {
            const queston_number = this.props.prevQuestion-1;
            this.setState({redirectToReferrer : false});
			return (<Redirect to={this.props.url+queston_number} />)
        }
        if(this.state.thanksToReferrer === true)
        {
			return (<Redirect to={'thanks'} />)
        }
        return (
            <Fragment>
                <nav className="navbar navbar-default navbar-quote">
                    <div className="navbar-header">
                        <div className="menu-icon">
                            <a className="menu-btn" style={{cursor : 'pointer'}} id="menu-btn" onClick={this.handleSidebar}>
                                <span className="top"></span>
                                <span className="middle"></span>
                                <span className="bottom"></span>
                            </a>
                        </div>
                        <a className="navbar-brand" href="/"><img src="/assets/images/logo.png" alt=""></img></a>
                    </div>
                    <ul className="save-quote">
                        <li><a className="btn btn-brand" onClick={this.handlePopupShow.bind(this,'emailModal')}>Save Quote</a></li>
                    </ul>
                </nav>
                <aside id="aside_bar">
                    <div className="menu">
                        <ul>
                            <li> <a href=""> Home </a></li>
                            <li> <a href=""> About us </a></li>
                            <li> <a href=""> Save Quote </a></li>
                        </ul>
                    </div>
                </aside>
                <div id="emailModal" className="modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" onClick={this.handlePopupClose.bind(this,'emailModal')} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="save-quote-inner">
                                <form>
                                    <div className="form-group">
                                        {this.validator.message('email', this.state.email, 'required')}
                                        {this.validator.message('phone', this.state.phone, 'required')}
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Email address" name="email" value={this.state.email} onChange={this.handleChange} onBlur={this.handleBlur} autoFocus={true}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Contact Number" name="phone" value={this.state.phone} onChange={this.handleChange} onBlur={this.handleBlur}/>
                                    </div>
                                    <button type="button" className="btn btn-brand" onClick={this.handleSaveApplication}>Save</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                </Fragment>
            );
        }
    }
    export default HeaderQuestion;