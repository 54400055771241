import React, { Component} from 'react';
export default class Questions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'plusOneVisible' : false,
            'plusTwoVisible' : false,
            'plusThreeVisible' : false,
            'panel' : '',
            'tabicon':'',
            'toggleEvent': '0',
        }
      // this.onToggle = this.onToggle.bind(this);
       
    }
    
    togglePopup(type, event) {
        event.preventDefault();    
        this.setState({panel: type});
        
        if(type === 'collapseOne')
        {
            this.setState({plusOneVisible: !this.state.plusOneVisible});
            this.setState({plusTwoVisible: false});
            this.setState({plusThreeVisible: false});
            
        }
        else if(type === 'collapseTwo')
        {
            this.setState({plusTwoVisible: !this.state.plusTwoVisible});
            this.setState({plusOneVisible: false});
            this.setState({plusThreeVisible: false});
        }
        else if(type === 'collapseThree') 
        {
            this.setState({plusThreeVisible: !this.state.plusThreeVisible});
            this.setState({plusOneVisible: false});
            this.setState({plusTwoVisible: false});
        }
      
    }
    render()
    {
        return(
            <section className="questions">
                <div className="container">
                    <div className="video visible-sm">
                        <img className="img-responsive" src="assets/images/home-video-thumbnail.png" alt=""></img>
                    </div>
                    <h2>Frequently Asked Questions</h2>
                    <div className="video visible-xs">
                        <img className="img-responsive" src="assets/images/home-video-thumbnail.png" alt=""></img>
                    </div>
                    <div className="inner">
                        <div className="panel-group accordion" id="accordion">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseOne')}  data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                            <span className="icon"><span  className={this.state.panel === 'collapseOne' && this.state.plusOneVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What does my expat policy cover?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseOne' && this.state.plusOneVisible === true  ?
                                <div id="collapseOne" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                 : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseTwo')} data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                            <span className="icon"><span className={this.state.panel === 'collapseTwo' && this.state.plusTwoVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            Where am I covered with my policy?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseTwo' && this.state.plusTwoVisible === true  ?
                                <div id="collapseTwo" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle"  onClick={this.togglePopup.bind(this, 'collapseThree')} data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                            <span className="icon"><span className={this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What is an expat?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true  ?
                                <div id="collapseThree" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                </div>
                            </div>
                            : ''}
                        </div>
                        <a href="#" className="btn btn-brand hidden-sm hidden-xs">Ask Question</a>
                    </div>
                    <div className="video hidden-sm hidden-xs">
                        <img className="img-responsive" src="assets/images/home-video-thumbnail.png" alt=""></img>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}