import React, { Component, Fragment} from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
export default class XPQ13 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'any_one_have_illness' : 'No',
            'take_mediction' : 'No',
            'provide_details_have_illness' : '',
            'provide_details_take_medication' : '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ any_one_have_illness: localStorageData[this.props.questionNum].any_one_have_illness });
            this.setState({ take_mediction: localStorageData[this.props.questionNum].take_mediction });
            this.setState({ provide_details_have_illness: localStorageData[this.props.questionNum].provide_details_have_illness });
            this.setState({ document_path_have_illness: localStorageData[this.props.questionNum].document_path_have_illness });
            this.setState({ provide_details_take_medication: localStorageData[this.props.questionNum].provide_details_take_medication });
            this.setState({ document_path_take_medication: localStorageData[this.props.questionNum].document_path_take_medication });
            
        }
    }
    handleSubmit = (event) => {
        if(event){
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 
                'any_one_have_illness': this.state.any_one_have_illness,
                'take_mediction' : this.state.take_mediction,
                'provide_details_have_illness' : this.state.provide_details_have_illness,
                'provide_details_take_medication' : this.state.provide_details_take_medication,
                'document_path_have_illness' : this.state.document_path_have_illness,
                'document_path_take_medication' : this.state.document_path_take_medication
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleFileChange (value, event) {
        event.preventDefault();

        var formData = new FormData();
        formData.append('fileToUpload', event.target.files[0]); 

        axios({
            method: "post",
            url: "http://localhost/xpatquote/api/upload.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data",
                        "content-type": "application/json"
                    },
        })
            .then(result => {
                this.setState({ [value]: result.data });
            })
            .catch(error => this.setState({ error: error.message }));
    }
    handleChangeMedicalRadio(disease, event)
    {
        this.state[disease] === '' || this.state[disease] === 'No' 
            ? this.setState({[disease] : 'Yes'}) 
            : this.setState({[disease] : 'No'});    
    }
    handleFileClick = (selector, event) => {
        document.getElementById(selector).click();
    }
    handleRemoveFile(file, event)
    {
        this.setState({[file] : ''});
    } 
    
    handleSelection(value, event)
    {
        this.setState({any_one_have_illness : value});
    }
    handleTakeMedictionSelection(value, event)
    {
        this.setState({take_mediction : value});
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner">
                        <form onSubmit={this.handleSubmit}>
                            {/* <div className="radio">
                                <input type="radio" name="any_one_have_illness" id="any_one_have_illness" value="Yes" checked={this.state.any_one_have_illness === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'any_one_have_illness')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="any_one_have_illness">Does anyone have any illness, condition or symptom not already mentioned? <br/>
                                </label>
                            </div> */}
                           <h2 className="main-fix">
                                Does anyone have any illness, condition or symptom not already mentioned?
                                </h2>
                            <div className="small-width-container">
                                
                                <div className="form-group"></div>
                                <div className="small-width-container">
                                <div className="form-group small-width">
                                    <a className={this.state.any_one_have_illness === 'Yes' ? 'btn btn-default btn-brand' : 'btn btn-primary'}  onClick={this.handleSelection.bind(this,'Yes')}>Yes</a>
                                </div>
                                <div className="form-group small-width">
                                    <a className={this.state.any_one_have_illness === 'No' ? 'btn btn-default btn-brand' : 'btn btn-primary'} onClick={this.handleSelection.bind(this,'No')}>No</a>
                                </div>
                                </div>
                            </div>
                            {this.state.any_one_have_illness === 'Yes' ?
                                <Fragment>
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={this.state.provide_details_have_illness} onChange={this.handleChange} name="provide_details_have_illness" placeholder="Provide Details" autoComplete="off"></input>
                                    </div>
                                    <div className="form-group">
                                        <div id="fakeDiv">
                                            <input type="file" id="selectedFile" className="form-control selectedFile" name="file" onChange={this.handleFileChange.bind(this,'document_path_have_illness')}></input>
                                            <input type="text" id="fakeInput" className="form-control fakeInput" value={this.state.document_path_have_illness} />
                                            <a className="cross-file" onClick={this.handleRemoveFile.bind(this,'document_path_have_illness')}><img src="/assets/images/icons/file-cross.svg" alt=""></img></a>
                                        </div>
                                        <div className="clearfix"></div>
                                        <a onClick={this.handleFileClick.bind(this,'selectedFile')} id="buttonImage"><i className="fa fa-paperclip"></i>Attach Files</a>
                                    </div>
                                    <div className="form-group">
                                        <p className="text-above-next">Please include details of any known or suspected issues whether or not medical advice has been sought or a diagnosis reached.</p>
                                    </div>
                                </Fragment>
                            : ''}
                            {/* <div className="radio">
                                <input type="radio" name="take_mediction" id="take_mediction" value="Yes" checked={this.state.take_mediction === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'take_mediction')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="take_mediction">Does anyone take medication, get any treatment or expect to have a review or follow up for a medical issue not mentioned? <br/>
                                </label>
                            </div> */}
                             <div className="small-width-container">
                             <h2 className="main-fix">
                             Does anyone take medication, get any treatment or expect to have a review or follow up for a medical issue not mentioned? <br/>
                                </h2>
                                <div className="form-group"></div>
                                <div className="small-width-container">
                                <div className="form-group small-width">
                                    <a className={this.state.take_mediction === 'Yes' ? 'btn btn-default btn-brand' : 'btn btn-primary'}  onClick={this.handleTakeMedictionSelection.bind(this,'Yes')}>Yes</a>
                                </div>
                                <div className="form-group small-width">
                                    <a className={this.state.take_mediction === 'No' ? 'btn btn-default btn-brand' : 'btn btn-primary'} onClick={this.handleTakeMedictionSelection.bind(this,'No')}>No</a>
                                </div>
                                </div>
                            </div>
                            {this.state.take_mediction === 'Yes' ?
                                <Fragment>
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={this.state.provide_details_take_medication} onChange={this.handleChange} name="provide_details_take_medication" placeholder="Provide Details" autoComplete="off"></input>
                                    </div>
                                    <div className="form-group">
                                        <div id="fakeDiv">
                                            <input type="file" id="selectedFile" className="form-control selectedFile" name="file" onChange={this.handleFileChange.bind(this,'document_path_take_medication')}></input>
                                            <input type="text" id="fakeInputMedication" className="form-control fakeInput" value={this.state.document_path_take_medication} />
                                            <a className="cross-file" onClick={this.handleRemoveFile.bind(this,'document_path_take_medication')}><img src="/assets/images/icons/file-cross.svg" alt=""></img></a>
                                        </div>
                                        <div className="clearfix"></div>
                                        <a onClick={this.handleFileClick.bind(this,'selectedFileMedication')} id="buttonImage"><i className="fa fa-paperclip"></i>Attach Files</a>
                                    </div>
                                    <div className="form-group">
                                        <p className="text-above-next">Please include details of any known or suspected issues whether or not medical advice has been sought or a diagnosis reached.</p>
                                    </div>
                                </Fragment>
                            : ''}
                            <NextButton btntext="Next"/>
                        </form>
                    </div> 
                    <div className="inner"></div>
                </div>
            </Fragment>
        );
    }
} 