import React, { Component} from 'react';
export default class ContactForm extends Component {
    render()
    {
      var handImage = {
        backgroundImage: 'url(assets/images/faq-help.png)',
    };
        return(
          <section className="paperless helping faq-help">
          <div className="left">
            <div className="image" style={handImage}></div>
          </div>
          <div className="right">
            <h1>We are here to help</h1>
            <p>Home is behind, the world ahead and there are many paths to tread through shadows to the edge.</p>
            <form>
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" placeholder="Name" />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="text" className="form-control" placeholder="Email" />
              </div>
              <div className="form-group text-area">
                  <textarea className="form-control" id="" cols="30" rows="1" placeholder="Message"></textarea>
              </div>
              <div id="fakeDiv">
                  <input type="file" id="selectedFile" />
                   <input type="text" id="fakeInput" />
                   <a className="cross-file" href="javascript:void(0)"><img src="assets/images/icons/file-cross.svg" alt="" /></a> 
              </div>
              <div className="clearfix"></div>
              <a href="javascript:void(0)" onclick="document.getElementById('selectedFile').click();" id="buttonImage"><i className="fa fa-paperclip"></i>Attach Files</a>

              <a href="" className="btn btn-brand btn-disabled">Get Quote</a>
            </form>
          </div>
          <div className="clearfix"></div>
        </section>
        );
    }
}