import React, { Component} from 'react';
export default class Questions extends Component {

  constructor(props) {
    super(props);
    this.state = {
        'plusOneVisible' : false,
        'plusTwoVisible' : false,
        'plusThreeVisible' : false,
        'panel' : '',
        'tabicon':''
    }
}

  handleSidebar = (event) =>
    {
        event.preventDefault();
        
        this.setState({plusOneVisible: false});
        this.setState({plusTwoVisible: false});
        this.setState({plusThreeVisible: false});
        var last_active =  document.getElementsByClassName("active");
        console.clear();
        
        last_active[0].classList.remove('active');
        last_active[0].classList.remove('active');

          var attribute = event.target.attributes.getNamedItem('data-id').value;
          var menu_bar = document.getElementById(attribute);
              menu_bar.classList.add("active");
              
          var li = event.target.attributes.getNamedItem('data-current').value;
          var li_bar = document.getElementById(li);
            li_bar.classList.add("active");
      
    }

    togglePopup(type, event) {
      event.preventDefault();    
      this.setState({panel: type});
      if(type === 'collapseOne')
      {
          this.setState({plusOneVisible: !this.state.plusOneVisible});
          this.setState({plusTwoVisible: false});
          this.setState({plusThreeVisible: false});
      }
      else if(type === 'collapseTwo')
      {
          this.setState({plusTwoVisible: !this.state.plusTwoVisible});
          this.setState({plusOneVisible: false});
          this.setState({plusThreeVisible: false});
      }
      else if(type === 'collapseThree') 
      {
          this.setState({plusThreeVisible: !this.state.plusThreeVisible});
          this.setState({plusOneVisible: false});
          this.setState({plusTwoVisible: false});
      }
  }
    render()
    {
        return(
            <section className="questions">
            <div className="container">
               <div className="video visible-sm">
                <img className="img-responsive" src="assets/images/home-video-thumbnail.png" alt="" />
              </div> 
              <div className="top">
                <div className="text-container">
                  <h2>Have any Questions?</h2>
                  <h4>We tried to answer the most common questions, however we are always here to help with your special case</h4>
                  <ul className="button-group h-group">
                      <li> <a href="javascript:void(0)" className="btn btn-brand">Find Answer</a> </li>
                      <li> <a href="javascript:void(0)" className="btn btn-default">Ask Question</a> </li>
                  </ul>
                </div>
                <div className="video">
                  <img className="img-responsive" src="assets/images/home-video-thumbnail.png" alt="" />
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="inner">
                <div className="tabbable-panel">
                  <div className="tabbable-line">
                    <ul className="nav nav-tabs aside_bar " onClick={this.handleSidebar}>
                      <li className="active" id="generals">
                        <a href="#general" data-id="general" data-current="generals"  data-toggle="tab">General</a></li>
                      <li  className="" id="benefits">
                        <a href="#benefit" data-id="benefit" data-current="benefits" data-toggle="tab">Benefit</a>
                      </li>
                      <li  className="" id="payments">
                        <a href="#payment" data-id="payment" data-current="payments" data-toggle="tab">Payment</a>
                      </li>
                      <li  className="" id="claim">
                        <a href="#claims" data-id="claims" data-current="claim" data-toggle="tab">Claims</a>
                      </li>
                    </ul>
                  <div className="tab-content">
                    <form className="search-field">
                      <div className="form-group search-icon">
                        <input type="text" className="form-control" placeholder="Search" />
                        <button type="button" className="btn btn-brand"><span className="visible-lg">Search</span>
                          <img className="hidden-lg" src="assets/images/icons/icon-search-doctors.svg" alt="" /></button>
                      </div>
                    </form>
                    <div className="tab-pane active" id="general">
                      <div className="panel-group accordion" id="accordionOne">
                        <h2>General</h2><div className="clearfix"></div>
                        <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseOne')}  data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                            <span className="icon"><span  className={this.state.panel === 'collapseOne' && this.state.plusOneVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What does my expat policy cover?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseOne' && this.state.plusOneVisible === true  ?
                                <div id="collapseOne" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                 : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseTwo')} data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                            <span className="icon"><span className={this.state.panel === 'collapseTwo' && this.state.plusTwoVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            Where am I covered with my policy?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseTwo' && this.state.plusTwoVisible === true  ?
                                <div id="collapseTwo" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle"  onClick={this.togglePopup.bind(this, 'collapseThree')} data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                            <span className="icon"><span className={this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What is an expat?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true  ?
                                <div id="collapseThree" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                </div>
                            </div>
                            : ''}
                        </div>
                      </div> 
                    </div>
                    <div className="tab-pane" id="benefit">
                        <div className="panel-group accordion" id="accordionTwo">
                            <h2>Benefit</h2>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseOne')}  data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                            <span className="icon"><span  className={this.state.panel === 'collapseOne' && this.state.plusOneVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What does my expat policy cover?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseOne' && this.state.plusOneVisible === true  ?
                                <div id="collapseOne" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                 : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseTwo')} data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                            <span className="icon"><span className={this.state.panel === 'collapseTwo' && this.state.plusTwoVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            Where am I covered with my policy?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseTwo' && this.state.plusTwoVisible === true  ?
                                <div id="collapseTwo" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle"  onClick={this.togglePopup.bind(this, 'collapseThree')} data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                            <span className="icon"><span className={this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What is an expat?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true  ?
                                <div id="collapseThree" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                </div>
                            </div>
                            : ''}
                        </div>
                          </div>
                    </div>
                    <div className="tab-pane " id="payment">
                        <div className="panel-group accordion" id="">
                            <h2>Payment</h2>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseOne')}  data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                            <span className="icon"><span  className={this.state.panel === 'collapseOne' && this.state.plusOneVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What does my expat policy cover?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseOne' && this.state.plusOneVisible === true  ?
                                <div id="collapseOne" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                 : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseTwo')} data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                            <span className="icon"><span className={this.state.panel === 'collapseTwo' && this.state.plusTwoVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            Where am I covered with my policy?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseTwo' && this.state.plusTwoVisible === true  ?
                                <div id="collapseTwo" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle"  onClick={this.togglePopup.bind(this, 'collapseThree')} data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                            <span className="icon"><span className={this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What is an expat?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true  ?
                                <div id="collapseThree" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                </div>
                            </div>
                            : ''}
                        </div>
                          </div> 
                    </div> 
                    <div className="tab-pane" id="claims">
                        <div className="panel-group accordion" id="">
                            <h2>Claims</h2>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseOne')}  data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                            <span className="icon"><span  className={this.state.panel === 'collapseOne' && this.state.plusOneVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What does my expat policy cover?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseOne' && this.state.plusOneVisible === true  ?
                                <div id="collapseOne" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                 : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle" onClick={this.togglePopup.bind(this, 'collapseTwo')} data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                            <span className="icon"><span className={this.state.panel === 'collapseTwo' && this.state.plusTwoVisible == true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            Where am I covered with my policy?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseTwo' && this.state.plusTwoVisible === true  ?
                                <div id="collapseTwo" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                    </div>
                                </div>
                                : '' }
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle"  onClick={this.togglePopup.bind(this, 'collapseThree')} data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                            <span className="icon"><span className={this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true ? 'glyphicon glyphicon-minus' : 'glyphicon glyphicon-plus'}></span></span>
                                            What is an expat?
                                        </a>
                                    </h4>
                                </div>
                                {this.state.panel === 'collapseThree' && this.state.plusThreeVisible === true  ?
                                <div id="collapseThree" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        Our policy is a full medical plan. This means it covers all of your medical needs from preventative care, to any injuries or illnesses that occur at your new home. 
                                </div>
                            </div>
                            : ''}
                        </div>
                          </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                </div> 
              </div> 
            </div>
          </section> 

        
        );
    }
}