import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleBlur, handleChangeRadio } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";


export default class XPQE extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'previouse': '',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        setTimeout(
            function() {
                const prev = this.props.questionNum-1;
                this.setState({prevQuestion : prev});     
           
    }
    .bind(this),
    5
   );
       
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            
        const forms_info = { 'pending_quote_status': 'pendind' };
        localStorageData[this.props.questionNum] = forms_info;
        if(this.props.questionNum >= localStorageData.current_step)
            localStorageData.current_step = parseInt(this.props.questionNum)+1;
        localStorageData = JSON.stringify(localStorageData);
        localStorage.setItem('xpatquote', localStorageData);
        this.setState({ redirectToReferrer: true });

    }
    
    render() {
       
        if (this.state.redirectToReferrer){
        //   /  if(this.props.questionNum)
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                
                    <div className="inner">
                        <h3 className="main">If you are agree please click next button</h3>
                        <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                                <NextButton btntext="Next" />
                            </div>
                         </form>
                        </div>
                </div>
            </Fragment>
        );
    }
} 