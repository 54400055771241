import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class NextButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			'previoues_question_number': this.props.prevQuestion,
			'redirectToReferrer': false
		}
		this.handleBackClick = this.handleBackClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	static getDerivedStateFromProps(props, state) {
		if (props.prevQuestion !== state.previoues_question_number)
			return { previoues_question_number: props.prevQuestion };
		else
			return null;
	}

	handleBackClick(event) {
		event.preventDefault();
		this.setState({ redirectToReferrer: true });
	}

	handleSubmit(event) {
		event.preventDefault();
	}
	render() {
		if (this.state.redirectToReferrer && this.state.previoues_question_number) {
			return (<Redirect to={this.state.previoues_question_number} />)
		}
		return (
			<button type="submit" onSubmit={this.handleSubmit} className="btn btn-brand btn-block">{this.props.btntext}</button>
		);
	}
}
export default NextButton;