import React, { Component} from 'react';
export default class ArticleInfo extends Component {
    render()
    {
      var handImage = {
        backgroundImage: 'url(assets/images/contact-image.png)',
    };
        return(
            <section className="helping contact-area">
            <div className="left">
              <div className="image" style={handImage}></div>
            </div>
  
            <div className="right">
              <h1>Contact us</h1>
              <ul>
                <li>
                  <div className="icon"><img src="assets/images/icons/icon-phone.svg" alt="" /></div>
                  <div className="text">
                    <h4>Phone</h4>
                    <a href="tel:+14087034911">+1 (408) 703 4911</a>
                  </div>
                </li>
                <li>
                  <div className="icon"><img src="assets/images/icons/icon-email.svg" alt="" /></div>
                  <div className="text">
                    <h4>Email</h4>
                    <a href="mailto:support@insure123.com">support@insure123.com</a>
                  </div>
                </li>
                <li>
                  <div className="icon"><img src="assets/images/icons/icon-address.svg" alt="" /></div>
                  <div className="text">
                    <h4>Address</h4>
                    <p>1494 Hamilton Ave, San Jose, CA 95125</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </section>
        );
    }
}