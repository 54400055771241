import React, { Component} from 'react';
export default class topSection extends Component {
    render()
    {
      var handImage = {
        backgroundImage: 'url(assets/images/affiliates-main-image.png)',
    };
        return(
          <section className="hero">
          <div className="left">
            <div className="image" style={handImage} ></div>
          </div>

          <div className="right">
            <h1>Partner with us and start selling Xpat insurance instantly</h1>
            <div className="list">
              <p>1. Sign up with Xpat coverage</p>
              <p>2. Get your personalized referral link</p>
              <p>3. Start selling!</p>
            </div>
            <a href="/create-account/1" className="btn btn-brand">Sign Up</a>
          </div>
          <div className="clearfix"></div>
        </section> 
        );
    }
}