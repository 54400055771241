import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

function Sidebar() {
	return (
        <div className="col-md-5 left hidden-sm hidden-xs">
            <div className="login-logo">
                <a className="navbar-brand" href="/"><img src="/assets/images/logo.png" alt=""></img></a>
            </div>
            <h3>See how Xpat makes your insurance usage easier. </h3>
            <ul>
                <li>Get personal referral link to invite new clients.</li>
                <li>See all clients information and survey results at one place.</li>
                <li>Track your comissions.</li>
                <li>Add co-producers.</li>
            </ul>
        </div>
    );
}
export default Sidebar;
