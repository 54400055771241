import React, { Component, Fragment } from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';

export default class XPQ16 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'e_signature' : '',
            'currency' : 'US Dollars',
            'currency_sign' : '$',
            'add_cover_usa' : 'WW',
            'payment_period_factor' : 1.00,


            
            'outpatient_price' : '$ 0.00',
            'health_wellbeing_price' : '$ 0.00',
            'medical_evacuation_price' : '$ 0.00',
            'vision_dental_price' : '$ 0.00',


            'outpatient_deductible_show' : false,
            'outpatient_text' : 'Add',
            'outpatient_font' : 'fa-plus-circle',

            'medical_evacuation_deductible_show' : false,
            'medical_evacuation_text' : 'Add',
            'medical_evacuation_font' : 'fa-plus-circle',

            'health_wellbeing_deductible_show' : false,
            'health_wellbeing_text' : 'Add',
            'health_wellbeing_font' : 'fa-plus-circle',

            'vision_dental_deductible_show' : false,
            'vision_dental_text' : 'Add',
            'vision_dental_font' : 'fa-plus-circle',


            'core_deductible_outpatient' : '$500',
            'coi_outpatient' : '20% CI - $3,000 OOP',

            'nationality' : '',
            'dob' : '',
            'living_country' : '',
            'selected_plan' : '',
            'additional_benefits_payment_outpatient': '',
            'additional_benefits_payment_medical_evacuation': '',
            'additional_benefits_payment_health_wellbeing' : '',
            'additional_benefits_payment_vision_dental' : '',

            'is_load' : true,


            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }


    convertCurrency = (amount, fromCurrency, toCurrency, cb) => {
        var https = require('https');
        var apiKey = '37a16d27d139e4f43f0a';
        fromCurrency = encodeURIComponent(fromCurrency);
        toCurrency = encodeURIComponent(toCurrency);
        var query = fromCurrency + '_' + toCurrency;
        var url = 'https://free.currconv.com/api/v7/convert?q='
            + query + '&compact=ultra&apiKey=' + apiKey;
        https.get(url, function(res){
        var body = '';
        res.on('data', function(chunk){
            body += chunk;
        });
        res.on('end', function(){
            try 
            {
                var jsonObj = JSON.parse(body);
                var val = jsonObj[query];
                if (val) {
                    var total = val * amount;
                    cb(null, Math.round(total * 100) / 100);
                } else {
                    var err = new Error("Value not found for " + query);
                    console.log(err);
                    cb(err);
                }
            } catch(e) {
                console.log("Parse error: ", e);
                cb(e);
            }
        });
    }).on('error', function(e){
        console.log("Got an error: ", e);
        cb(e);
    });
}

    changeTwoDecimal =  (num) => {
        return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    }


    updateOutpatientRatesPrice = () => {
        // update Outpatient Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';
        if(this.state.currency !=='US Dollars')
        {
            var value = this.state.outpatient_price_orignal === undefined ? 0.00 : this.state.outpatient_price_orignal;
            this.convertCurrency(value, 'USD', symbol, function(err, amount) {
                this.setState({ outpatient_price : this.state.currency_sign + ' ' + this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
            
        }
        else{
            this.setState({ outpatient_price : this.state.currency_sign + ' ' + this.changeTwoDecimal(this.state.outpatient_price_orignal * this.state.payment_period_factor )});
        }
    }


    updateHealthWellbeingRatesPrice = () => {
        // update Health Wellbeing Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';
        if(this.state.currency !=='US Dollars')
        {
            var value = this.state.health_wellbeing_price_orignal === undefined ? 0.00 : this.state.health_wellbeing_price_orignal;
            this.convertCurrency(value, 'USD', symbol, function(err, amount) {
                this.setState({ health_wellbeing_price : this.state.currency_sign + ' ' + this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
            
        }
        else{
            this.setState({ health_wellbeing_price : this.state.currency_sign + ' ' + this.changeTwoDecimal(this.state.health_wellbeing_price_orignal * this.state.payment_period_factor )});
        }
    }


    updateMedicalEvacuationRatesPrice = () => {
        // update Medical Evacuation Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';
        if(this.state.currency !=='US Dollars')
        {
            var value = this.state.medical_evacuation_price_orignal === undefined ? 0.00 : this.state.medical_evacuation_price_orignal;
            this.convertCurrency(value, 'USD', symbol, function(err, amount) {
                this.setState({ medical_evacuation_price : this.state.currency_sign + ' ' + this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
            
        }
        else{
            this.setState({ medical_evacuation_price : this.state.currency_sign + ' ' + this.changeTwoDecimal(this.state.medical_evacuation_price_orignal * this.state.payment_period_factor )});
        }
    }

    updateVisionDentalRatesPrice = () => {
        // update Vision Dental Rates
        var symbol = 'USD';
        if(this.state.currency === 'Euro')
            symbol = 'EUR';
        else if(this.state.currency === 'Sterling')
            symbol = 'GBP';
        if(this.state.currency !=='US Dollars')
        {
            var value = this.state.vision_dental_price_orignal === undefined ? 0.00 : this.state.vision_dental_price_orignal;
            this.convertCurrency(value, 'USD', symbol, function(err, amount) {
                this.setState({ vision_dental_price : this.state.currency_sign + ' ' + this.changeTwoDecimal(amount * this.state.payment_period_factor) });
            }.bind(this));
            
        }
        else{
            this.setState({ vision_dental_price : this.state.currency_sign + ' ' + this.changeTwoDecimal(this.state.vision_dental_price_orignal * this.state.payment_period_factor )});
        }
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            
            this.setState({ additional_benefits_payment_outpatient: localStorageData[this.props.questionNum].additional_benefits_payment_outpatient });
            this.setState({ additional_benefits_payment_health_wellbeing: localStorageData[this.props.questionNum].additional_benefits_payment_health_wellbeing });
            this.setState({ additional_benefits_payment_medical_evacuation: localStorageData[this.props.questionNum].additional_benefits_payment_medical_evacuation });
            this.setState({ additional_benefits_payment_vision_dental: localStorageData[this.props.questionNum].additional_benefits_payment_vision_dental });


            this.setState({ coi_outpatient: localStorageData[this.props.questionNum].coi_outpatient });
            this.setState({ core_deductible_outpatient: localStorageData[this.props.questionNum].core_deductible_outpatient });
            this.setState({ health_wellbeing_price: localStorageData[this.props.questionNum].health_wellbeing_price });
            this.setState({ health_wellbeing_price_orignal: localStorageData[this.props.questionNum].health_wellbeing_price_orignal });
            this.setState({ medical_evacuation_price: localStorageData[this.props.questionNum].medical_evacuation_price });
            this.setState({ medical_evacuation_price_orignal: localStorageData[this.props.questionNum].medical_evacuation_price_orignal });

            this.setState({ outpatient_deductible_show: localStorageData[this.props.questionNum].outpatient_deductible_show });
            this.setState({ outpatient_font: localStorageData[this.props.questionNum].outpatient_font });
            this.setState({ outpatient_text: localStorageData[this.props.questionNum].outpatient_text });

            this.setState({ health_wellbeing_deductible_show: localStorageData[this.props.questionNum].health_wellbeing_deductible_show });
            this.setState({ health_wellbeing_font: localStorageData[this.props.questionNum].health_wellbeing_font });
            this.setState({ health_wellbeing_text: localStorageData[this.props.questionNum].health_wellbeing_text });


            this.setState({ medical_evacuation_deductible_show: localStorageData[this.props.questionNum].medical_evacuation_deductible_show });
            this.setState({ medical_evacuation_font: localStorageData[this.props.questionNum].medical_evacuation_font });
            this.setState({ medical_evacuation_text: localStorageData[this.props.questionNum].medical_evacuation_text });


            this.setState({ vision_dental_deductible_show: localStorageData[this.props.questionNum].vision_dental_deductible_show });
            this.setState({ vision_dental_font: localStorageData[this.props.questionNum].vision_dental_font });
            this.setState({ vision_dental_text: localStorageData[this.props.questionNum].vision_dental_text });

            
            
            this.setState({ outpatient_price: localStorageData[this.props.questionNum].outpatient_price });
            this.setState({ outpatient_price_orignal: localStorageData[this.props.questionNum].outpatient_price_orignal });
            
            
            
            this.setState({ vision_dental_price: localStorageData[this.props.questionNum].vision_dental_price });
            this.setState({ vision_dental_price_orignal: localStorageData[this.props.questionNum].vision_dental_price_orignal });
        }

        if(this.state.is_load === true){
            if(localStorageData && localStorageData['2'])
                this.setState({ nationality : localStorageData['2'].nationality});
            if(localStorageData && localStorageData['3'])
                this.setState({ dob : localStorageData['3'].dob});
            if(localStorageData && localStorageData['4'])
                this.setState({ living_country : localStorageData['4'].destination_country});
        }

        var prev_index = this.props.questionNum-1;
        var level_plan = 'silver';
        if(localStorageData && localStorageData[prev_index])
        {
            this.setState({level_plan : localStorageData[prev_index].level_plan});
            level_plan = localStorageData[prev_index].level_plan;

            this.setState({ currency : localStorageData[prev_index].currency});
            this.setState({ currency_sign : localStorageData[prev_index].currency_sign});


            this.setState({add_cover_usa : localStorageData[prev_index].add_cover_usa});
        }

        setTimeout(function()
        {
            var formData = new FormData();
            formData.append('nationality', this.state.nationality);
            formData.append('dob', this.state.dob);
            formData.append('living_country', this.state.living_country);
            formData.append('aoc', this.state.add_cover_usa);
            formData.append('currency', this.state.currency);
            var base_url = process.env.REACT_APP_HOME_API_URL;
            axios({
                method: "post",
                url: base_url+'get_quote/get_additional_benfits_'+ level_plan +'_price_ajax',
                data : formData,
                headers: { "content-type": "application/json" },
            })
            .then(result => {
                if (result.data.success === true){
                    this.setState({dob : result.data.age});

                    this.setState({ outpatient_price_orignal : this.changeTwoDecimal(result.data.data.outpatient)});
                    if(result.data.data.other_products != null)
                    {
                        this.setState({ health_wellbeing_price_orignal : this.changeTwoDecimal(result.data.data.other_products.hw)});
                        this.setState({ medical_evacuation_price_orignal : this.changeTwoDecimal(result.data.data.other_products.ev)});
                        this.setState({ vision_dental_price_orignal : this.changeTwoDecimal(result.data.data.other_products.dv)});
                    }
                    setTimeout(function(){
                        this.updateOutpatientRatesPrice();
                        // if(result.data.data.other_products != null)
                        // {
                            this.updateHealthWellbeingRatesPrice();
                            this.updateMedicalEvacuationRatesPrice();
                            this.updateVisionDentalRatesPrice();
                        // }
                    }.bind(this),5);
                }
            })
            .catch(error => this.setState({ error: error.message }));
        }.bind(this),5);



        setTimeout(function ()
        {
            if(this.state.currency === 'US Dollars')
            {
                this.setState({ core_deductible_outpatient : '$500'});
                this.setState({ coi_outpatient : '20% CI - $3,000 OOP'});
            }
            else if(this.state.currency === 'Euro')
            {
                this.setState({ core_deductible_outpatient : 'â‚¬370'});
                this.setState({ coi_outpatient : '20% CI - â‚¬2,200 OOP'});
            }
            else if(this.state.currency === 'Sterling')
            {
                this.setState({ core_deductible_outpatient : 'Â£335'});
                this.setState({ coi_outpatient : '20% CI - Â£2,000 OOP'});
            }
        }.bind(this),5);
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 
                'additional_benefits_payment_outpatient': this.state.additional_benefits_payment_outpatient,
                'additional_benefits_payment_health_wellbeing': this.state.additional_benefits_payment_health_wellbeing,
                'additional_benefits_payment_medical_evacuation': this.state.additional_benefits_payment_medical_evacuation,
                'additional_benefits_payment_vision_dental': this.state.additional_benefits_payment_vision_dental,

                'coi_outpatient' : this.state.coi_outpatient,
                'core_deductible_outpatient' : this.state.core_deductible_outpatient,
                'health_wellbeing_price' : this.state.health_wellbeing_price,
                'health_wellbeing_price_orignal' : this.state.health_wellbeing_price_orignal,
                'medical_evacuation_price' : this.state.medical_evacuation_price,
                'medical_evacuation_price_orignal' : this.state.medical_evacuation_price_orignal,


                'outpatient_deductible_show' : this.state.outpatient_deductible_show,
                'outpatient_font' : this.state.outpatient_font,
                'outpatient_text' : this.state.outpatient_text,


                'health_wellbeing_deductible_show' : this.state.health_wellbeing_deductible_show,
                'health_wellbeing_font' : this.state.health_wellbeing_font,
                'health_wellbeing_text' : this.state.health_wellbeing_text,


                'medical_evacuation_deductible_show' : this.state.medical_evacuation_deductible_show,
                'medical_evacuation_font' : this.state.medical_evacuation_font,
                'medical_evacuation_text' : this.state.medical_evacuation_text,

                'vision_dental_deductible_show' : this.state.vision_dental_deductible_show,
                'vision_dental_font' : this.state.vision_dental_font,
                'vision_dental_text' : this.state.vision_dental_text,

                'outpatient_price' : this.state.outpatient_price,
                'outpatient_price_orignal' : this.state.outpatient_price_orignal,
                
                'vision_dental_price' : this.state.vision_dental_price,
                'vision_dental_price_orignal' : this.state.vision_dental_price_orignal
            };
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    updateOutPatientRates = () => {
        var formData = new FormData();
        formData.append('age', this.state.dob);
        formData.append('nationality', this.state.nationality);
        formData.append('living_country', this.state.living_country);
        formData.append('aoc', this.state.add_cover_usa);
        formData.append('deductible', this.state.core_deductible_outpatient);
        formData.append('currency', this.state.currency);
        formData.append('coi', this.state.coi_outpatient);
        var base_url = process.env.REACT_APP_HOME_API_URL;
        axios({
            method: "post",
            url: base_url+'get_quote/get_outpatient_'+ this.state.level_plan +'_price_ajax',
            data : formData,
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            if (result.data){
                // this.setState({silver_price_orignal : result.data}); 
                this.setState({outpatient_price_orignal : result.data });
                setTimeout(function ()
                {
                    this.updateOutpatientRatesPrice();
                }.bind(this),5);
                
            }
            else if(result.data == 0)
            {
                this.setState({outpatient_price_orignal : '0.00' });
                this.setState({outpatient_price : this.state.currency_sign+' '+ '0.00' });
            }
        })
        .catch(error => this.setState({ error: error.message }));
    }


    handlePlanSelection(plan, event){
        this.setState({e_signature : plan});
    }

    handleChangeOutPatientPlan = (event) => {
        this.handleChange(event);
        setTimeout(
            function() {
                this.updateOutPatientRates();
            }
            .bind(this),
            5
        );

    } 
    handleAddOutPatient = (event) => {
        if(this.state.outpatient_deductible_show === true)
        {
            this.setState({outpatient_deductible_show : false});
            this.setState({outpatient_text : 'Add'});
            this.setState({outpatient_font : 'fa-plus-circle'});
            this.setState({additional_benefits_payment_outpatient : ''});
        }
        else
        {
            this.setState({ outpatient_deductible_show : true});
            this.setState({ outpatient_text : 'Remove'});
            this.setState({ outpatient_font : 'fa-minus-circle'});
            this.setState({additional_benefits_payment_outpatient : 'yes'});
        }
    }

    handleAddHealthWellbeing = (event) => {
        if(this.state.health_wellbeing_deductible_show === true)
        {
            this.setState({health_wellbeing_deductible_show : false});
            this.setState({health_wellbeing_text : 'Add'});
            this.setState({health_wellbeing_font : 'fa-plus-circle'});
            this.setState({additional_benefits_payment_health_wellbeing : ''});
        }
        else
        {
            this.setState({ health_wellbeing_deductible_show : true});
            this.setState({ health_wellbeing_text : 'Remove'});
            this.setState({ health_wellbeing_font : 'fa-minus-circle'});
            this.setState({additional_benefits_payment_health_wellbeing : 'yes'});
        }
    }

    handleAddMedicalEvacuation = (event) => {
        if(this.state.medical_evacuation_deductible_show === true)
        {
            this.setState({ medical_evacuation_deductible_show : false});
            this.setState({ medical_evacuation_text : 'Add'});
            this.setState({ medical_evacuation_font : 'fa-plus-circle'});
            this.setState({ additional_benefits_payment_medical_evacuation : ''});
        }
        else
        {
            this.setState({ medical_evacuation_deductible_show : true});
            this.setState({ medical_evacuation_text : 'Remove'});
            this.setState({ medical_evacuation_font : 'fa-minus-circle'});
            this.setState({ additional_benefits_payment_medical_evacuation : 'yes'});
        }
    }

    handleAddVisionDental = (event) => {
        if(this.state.vision_dental_deductible_show === true)
        {
            this.setState({ vision_dental_deductible_show : false});
            this.setState({ vision_dental_text : 'Add'});
            this.setState({ vision_dental_font : 'fa-plus-circle'});
            this.setState({ additional_benefits_payment_vision_dental : ''});
        }
        else
        {
            this.setState({ vision_dental_deductible_show : true});
            this.setState({ vision_dental_text : 'Remove'});
            this.setState({ vision_dental_font : 'fa-minus-circle'});
            this.setState({ additional_benefits_payment_vision_dental : 'yes'});
        }
    }
    render() {
        var deductible = [];
        var cost_share = [];
        if(this.state.currency === 'US Dollars')
        {
            deductible.push(
                <Fragment key={1}>
                    <option value="$0">$0</option>
                    <option value="$150">$150</option>
                    <option value="$500">$500</option>
                    <option value="$1,000">$1000</option>
                    <option value="$1,500">$1500</option>
                </Fragment>
            );
            cost_share.push(
                <Fragment key={2}>
                    <option value="0% CI - $0 OOP">0% Cost share $0 OOP Max</option>
                    <option value="10% CI - $3,000 OOP">10% Cost share $3000 OOP Max</option>
                    <option value="20% CI - $3,000 OOP">20% Cost share $3000 OOP Max</option>
                    <option value="30% CI - $3,000 OOP">30% Cost share $3000 OOP Max</option>
                </Fragment>
            );
        }
        else if(this.state.currency === 'Euro')
        {
            deductible.push(
                <Fragment key={1}>
                    <option value="â‚¬0">€0</option>
                    <option value="â‚¬110">€110</option>
                    <option value="â‚¬370">€370</option>
                    <option value="â‚¬700">€700</option>
                    <option value="â‚¬1,100">€1100</option>
                </Fragment>
            );
            cost_share.push(
                <Fragment key={2}>
                    <option value="0% CI - â‚¬0 OOP">0% Cost share €0 OOP Max</option>
                    <option value="10% CI - â‚¬2,200 OOP">10% Cost share €2200 OOP Max</option>
                    <option value="20% CI - â‚¬2,200 OOP">20% Cost share €2200 OOP Max</option>
                    <option value="30% CI - â‚¬2,200 OOP">30% Cost share €2200 OOP Max</option>
                </Fragment>
            );
        }
        else if(this.state.currency === 'Sterling')
        {
            deductible.push(
                <Fragment key={1}>
                    <option value="Â£0">£0</option>
                    <option value="Â£100">£100</option>
                    <option value="Â£335">£335</option>
                    <option value="Â£600">£600</option>
                    <option value="Â£1,000">£1000</option>
                </Fragment>
            );
            cost_share.push(
                <Fragment key={2}>
                    <option value="0% CI - Â£0 OOP">0% Cost share £0 OOP Max</option>
                    <option value="10% CI - Â£2,000 OOP">10% Cost share £2000 OOP Max</option>
                    <option value="20% CI - Â£2,000 OOP">20% Cost share £2000 OOP Max</option>
                    <option value="30% CI - Â£2,000 OOP">30% Cost share £2000 OOP Max</option>
                </Fragment>
            );
        }
        if (this.state.redirectToReferrer){
            const next = this.props.questionNum+1;
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <BackButton lsKey="xpatquote" stepNumber={this.props.questionNum} progressArray={this.props.progressArray} />
                <div className="mainContentWrapper" id="wrapper_1">
                    <div className="kc-elm kc-css-835716 kc-title-wrap heading-margin-spacing">
                        <h3 id="title_description" className="kc_title purple-color">Select your additional benefits</h3>
                    </div>
                    <form onSubmit={this.handleSubmit} name="group_medical_form_1" className="flat-contact-form style2 bg-dark height-small validateForm new_application_form" method="post">
                        {/* <div className="mc4wp-form_custom-fields"> */}
                            <div className="margin-bottom-20">
                            </div>
                            {/* Start pasted here */}

                            <div className="kc-css-816641 kc_column_inner col-md-6 plan_highlight">
                                <div className="kc_wrapper kc-col-inner-container">	
                                    <div className="em-service rotate-service service-container kc-elm kc-css-656986 margin-bottom-20 plan_service_pack" onClick={this.handlePlanSelection.bind(this,'silver')}>
                                        <div className="em_single_service_text service-box ">
                                            <div className='service-front plan_service_pack bksbtn front-overly'>
                                                <div className="service_top_text full_width">
                                                    <div className="em-service-icon">
                                                        <i className="fas fa-stethoscope"></i>
                                                    </div>
                                                    <div className="em-service-parcent">
                                                        <span>{this.state.outpatient_price}</span>
                                                    </div>
                                                    <div className="em-service-title">
                                                        <h2>Outpatient</h2>
                                                        <div className="mc4wp-form-fields plan_button-right">
                                                            <button className="flat-button bg-theme right" type="button" onClick={this.handleAddOutPatient}><i className={'fa ' + this.state.outpatient_font}></i>{this.state.outpatient_text}</button>
                                                        </div>
                                                    </div>
                                                    {this.state.outpatient_deductible_show === true ?
                                                        <Fragment>
                                                            <div className="mc4wp-form_custom-fields plan_input_form">
                                                                <div className="new-form-field plan_new_form_field">
                                                                    <label htmlFor="core_deductible_outpatient">Core deductible:</label>
                                                                    <div className="select-list">
                                                                        <select className="form-control" id="core_deductible_outpatient" name="core_deductible_outpatient" value={this.state.core_deductible_outpatient} onChange={this.handleChangeOutPatientPlan}>
                                                                            {deductible}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mc4wp-form_custom-fields plan_input_form">
                                                                <div className="new-form-field plan_new_form_field">
                                                                    <label htmlFor="coi_outpatient">Cost share after deductible:</label>
                                                                    <div className="select-list">
                                                                        <select className="form-control" id="coi_outpatient" name="coi_outpatient" value={this.state.coi_outpatient} onChange={this.handleChangeOutPatientPlan}>
                                                                        {cost_share}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    : ''}
                                                    <div className="service-btn">
                                                        <a href="#">View Benefits</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="service-back plan_service_pack bkdf">
                                                <div className="service_top_text full_width">
                                                    <div className="em-service-icon">
                                                        <i className="fas fa-stethoscope"></i>
                                                    </div>			
                                                    <div className="em-service-parcent">
                                                        <span>{this.state.outpatient_price}</span>
                                                    </div>
                                                    <div className="em-service-title">
                                                        <h2>Outpatient</h2>
                                                        <div className="mc4wp-form-fields plan_button-right">
                                                            <button className="flat-button bg-theme right" type="button" onClick={this.handleAddOutPatient}><i className={'fa ' + this.state.outpatient_font}></i>{this.state.outpatient_text}</button>
                                                        </div>
                                                    </div>
                                                    {this.state.outpatient_deductible_show === true ?
                                                        <Fragment>
                                                            <div className="mc4wp-form_custom-fields plan_input_form">
                                                                <div className="new-form-field plan_new_form_field">
                                                                    <label htmlFor="core_deductible_outpatient">Core deductible:</label>
                                                                    <div className="select-list">
                                                                        <select className="form-control" id="core_deductible_outpatient" name="core_deductible_outpatient" value={this.state.core_deductible_outpatient}  onChange={this.handleChangeOutPatientPlan}>
                                                                            {deductible}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mc4wp-form_custom-fields plan_input_form">
                                                                <div className="new-form-field plan_new_form_field">
                                                                    <label htmlFor="coi_outpatient">Cost share after deductible:</label>
                                                                    <div className="select-list">
                                                                        <select className="form-control" id="coi_outpatient" name="coi_outpatient" value={this.state.coi_outpatient}  onChange={this.handleChangeOutPatientPlan}>
                                                                            {cost_share}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    : '' }
                                                    <div className="service-btn">
                                                        <a href="#">View Benefits</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="margin-bottom-20"></div> */}
                                    {this.state.medical_evacuation_price !== '$ 0.00' && this.state.medical_evacuation_price !== '$ 0' && this.state.medical_evacuation_price !== '€ 0.00'
                                    && this.state.medical_evacuation_price !== '€ 0' && this.state.medical_evacuation_price !== '£ 0.00' && this.state.medical_evacuation_price !== '£ 0' ?
                                    <div className="em-service rotate-service service-container kc-elm kc-css-329014 plan_box_height" onClick={this.handlePlanSelection.bind(this,'platinum')}>
                                        <div className="em_single_service_text service-box">
                                            <div className="service-front plan_service_pack bksbtn front-overly">
                                                <div className="service_top_text full_width">
                                                    <div className="em-service-icon">
                                                        <i className="fa fa-ambulance"></i>
                                                    </div>
                                                    <div className="em-service-parcent">
                                                        <span>{this.state.medical_evacuation_price}</span>
                                                    </div>
                                                    <div className="em-service-title">
                                                        <h2>Medical Evacuation</h2>
                                                        <div className="mc4wp-form-fields plan_button-right">
                                                            <button className="flat-button bg-theme right" type="button" onClick={this.handleAddMedicalEvacuation}><i className={'fa ' + this.state.medical_evacuation_font}></i>{this.state.medical_evacuation_text}</button>
                                                        </div>
                                                    </div>
                                                    <div className="service-btn">
                                                        <a href="#">View Benefits</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="service-back plan_service_pack bkdf">
                                                <div className="service_top_text full_width">
                                                    <div className="em-service-icon">
                                                        <i className="fa fa-ambulance"></i>
                                                    </div>
                                                    <div className="em-service-parcent">
                                                        <span>{this.state.medical_evacuation_price}</span>
                                                    </div>
                                                    <div className="em-service-title">
                                                        <h2>Medical Evacuation</h2>
                                                        <div className="mc4wp-form-fields plan_button-right">
                                                            <button className="flat-button bg-theme right" type="button" onClick={this.handleAddMedicalEvacuation}><i className={'fa ' + this.state.medical_evacuation_font}></i>{this.state.medical_evacuation_text}</button>
                                                        </div>
                                                    </div>
                                                    <div className="service-btn">
                                                        <a href="#">View Benefits</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>	
                                    : ''}
                                </div>
                            </div>

                            {/* End pasted here */}

                            {/*Start Second part */}

                            <div className="kc-css-816641 kc_column_inner col-md-6 plan_highlight">
                                <div className="kc_wrapper kc-col-inner-container">	
                                    {this.state.health_wellbeing_price !== '$ 0.00' && this.state.health_wellbeing_price !== '$ 0' && this.state.health_wellbeing_price !== '€ 0.00'
                                    && this.state.health_wellbeing_price !== '€ 0' && this.state.health_wellbeing_price !== '£ 0.00' && this.state.health_wellbeing_price !== '£ 0' ?
                                    <div className="em-service rotate-service service-container kc-elm kc-css-656986 margin-bottom-20 plan_service_pack" onClick={this.handlePlanSelection.bind(this,'silver')}>
                                        <div className="em_single_service_text service-box ">
                                            <div className="service-front plan_service_pack bksbtn front-overly">
                                                <div className="service_top_text full_width">
                                                    <div className="em-service-icon">
                                                        <i className="fas fa-ambulance"></i>
                                                    </div>
                                                    <div className="em-service-parcent">
                                                        <span>{this.state.health_wellbeing_price}</span>
                                                    </div>
                                                    <div className="em-service-title">
                                                        <h2>Health and Wellbeing</h2>
                                                        <div className="mc4wp-form-fields plan_button-right">
                                                            <button className="flat-button bg-theme right" type="button" onClick={this.handleAddHealthWellbeing}><i className={'fa ' + this.state.health_wellbeing_font}></i>{this.state.health_wellbeing_text}</button>
                                                        </div>
                                                    </div>
                                                    <div className="service-btn">
                                                        <a href="#">View Benefits</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="service-back plan_service_pack bkdf">
                                                <div className="service_top_text full_width">
                                                    <div className="em-service-icon">
                                                        <i className="fas fa-ambulance"></i>
                                                    </div>			
                                                    <div className="em-service-parcent">
                                                        <span>{this.state.health_wellbeing_price}</span>
                                                    </div>
                                                    <div className="em-service-title">
                                                        <h2>Health and Wellbeing</h2>
                                                        <div className="mc4wp-form-fields plan_button-right">
                                                            <button className="flat-button bg-theme right" type="button" onClick={this.handleAddHealthWellbeing}><i className={'fa ' + this.state.health_wellbeing_font}></i>{this.state.health_wellbeing_text}</button>
                                                        </div>
                                                    </div>
                                                    <div className="service-btn">
                                                        <a href="#">View Benefits</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : '' }
                                    {/* <div className="margin-bottom-20"></div> */}
                                    {this.state.vision_dental_price !== '$ 0.00' && this.state.vision_dental_price !== '$ 0' && this.state.vision_dental_price !== '€ 0.00'
                                    && this.state.vision_dental_price !== '€ 0' && this.state.vision_dental_price !== '£ 0.00' && this.state.vision_dental_price !== '£ 0' ?
                                    <div className="em-service rotate-service service-container kc-elm kc-css-329014 plan_box_height" onClick={this.handlePlanSelection.bind(this,'platinum')}>
                                        <div className="em_single_service_text service-box">
                                            <div className="service-front plan_service_pack bksbtn front-overly">
                                                <div className="service_top_text full_width">
                                                    <div className="em-service-icon">
                                                        <i className="fas fa-ambulance"></i>
                                                    </div>
                                                    <div className="em-service-parcent">
                                                        <span>{this.state.vision_dental_price}</span>
                                                    </div>
                                                    <div className="em-service-title">
                                                        <h2>Vision and Dental</h2>
                                                        <div className="mc4wp-form-fields plan_button-right">
                                                            <button className="flat-button bg-theme right" type="button" onClick={this.handleAddVisionDental}><i className={'fa ' + this.state.vision_dental_font}></i>{this.state.vision_dental_text}</button>
                                                        </div>
                                                    </div>
                                                    <div className="service-btn">
                                                        <a href="#">View Benefits</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="service-back plan_service_pack bkdf">
                                                <div className="service_top_text full_width">
                                                    <div className="em-service-icon">
                                                        <i className="fas fa-ambulance"></i>
                                                    </div>
                                                    <div className="em-service-parcent">
                                                        <span>{this.state.vision_dental_price}</span>
                                                    </div>
                                                    <div className="em-service-title">
                                                        <h2>Vision and Dental</h2>
                                                        <div className="mc4wp-form-fields plan_button-right">
                                                            <button className="flat-button bg-theme right" type="button" onClick={this.handleAddVisionDental}><i className={'fa ' + this.state.vision_dental_font}></i>{this.state.vision_dental_text}</button>
                                                        </div>
                                                    </div>
                                                    <div className="service-btn">
                                                        <a href="#">View Benefits</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>	
                                    : '' }
                                </div>
                            </div>
                            <div className="row"></div>
                        <br></br>
                        <NextButton btntext="Next" />
                    </form>
                </div>
        </Fragment>
        );
    }
} 