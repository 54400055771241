import React, { Component, Fragment} from 'react'
import NextButton from '../../../../component/NextButton';
import { handleChange, handleChangeRadio, handleBlur } from '../../../../../common_function/common_function';
import BackButton from '../../../../component/BackButton';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
export default class XPQ12 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'neurological' : 'No',
            'skin_problems' : 'No',
            'blood_infective' : 'No',
            'urinary_or_reproductive' : 'No',
            'anxiety' : 'No',
            'ear_nose_throat' : 'No',
            'neurological_none_of_above': 'neurological_none_of_above',
            'redirectToReferrer': false
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleChangeRadio = handleChangeRadio.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }

    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData[this.props.questionNum]) {
            this.setState({ neurological: localStorageData[this.props.questionNum].neurological });
            this.setState({ skin_problems: localStorageData[this.props.questionNum].skin_problems });
            this.setState({ blood_infective: localStorageData[this.props.questionNum].blood_infective });
            this.setState({ urinary_or_reproductive: localStorageData[this.props.questionNum].urinary_or_reproductive });
            this.setState({ anxiety: localStorageData[this.props.questionNum].anxiety });   
            this.setState({ ear_nose_throat: localStorageData[this.props.questionNum].ear_nose_throat });   
            this.setState({ neurological_none_of_above: localStorageData[this.props.questionNum].neurological_none_of_above });   
            
        }
    }
    handleSubmit = (event) => {
        if(event){
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
            const forms_zip = { 
                '': this.state.neurological,
                'skin_problems' : this.state.skin_problems,
                'blood_infective' : this.state.blood_infective,
                'urinary_or_reproductive' : this.state.urinary_or_reproductive,
                'anxiety' : this.state.anxiety,
                'ear_nose_throat' : this.state.ear_nose_throat,
                'neurological_none_of_above' : this.state.neurological_none_of_above

            }; 
            localStorageData[this.props.questionNum] = forms_zip;
            if(this.props.questionNum >= localStorageData.current_step)
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
            localStorageData = JSON.stringify(localStorageData);
            localStorage.setItem('xpatquote', localStorageData);
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleChangeMedicalRadio(disease, event)
    {
        this.state[disease] === '' || this.state[disease] === 'No' 
            ? this.setState({[disease] : 'Yes'}) 
            : this.setState({[disease] : 'No'});    
    }


    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <Fragment>
                <div className="quote-page">
                    <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} url="/xpatquote/" totalQuestion={this.props.totalQuestion} />
                    <div className="inner radio-box">
                        <h2 className="main">Has any applicant received treatment, tests or investigations for, or been diagnosed with, or had any signs or symptoms of: <br />(select all that apply)</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="margin-bottom-20">
                                {/* {this.validator.message('neurological', this.state.neurological, 'required')}
                                {this.validator.message('skin_problems', this.state.skin_problems, 'required')}
                                {this.validator.message('blood_infective', this.state.blood_infective, 'required')}
                                {this.validator.message('urinary_or_reproductive', this.state.urinary_or_reproductive, 'required')}
                                {this.validator.message('anxiety', this.state.anxiety, 'required')}
                                {this.validator.message('ear_nose_throat', this.state.ear_nose_throat, 'required')} */}
                            </div>
                            <div className="radio">
                                <input type="radio" name="neurological" id="neurological" value="Yes" checked={this.state.neurological === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'neurological')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="neurological">Brain or neurological disorders <br/>
                                    <small>(e.g. multiple sclerosis, epilepsy or seizures, stroke, migraines, recurring or severe headaches, meningitis, shingles or nerve pain.)</small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="skin_problems" id="skin_problems" value="Yes" checked={this.state.skin_problems === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'skin_problems')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="skin_problems">Skin problems<br/>
                                    <small>(e.g. eczema, acne, moles, rashes, allergic reactions, cysts, dermatitis or psoriasis) </small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="blood_infective" id="blood_infective" value="blood_infective" value="Yes" checked={this.state.blood_infective === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'blood_infective')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="blood_infective">Blood, infective or immune disorders<br/>
                                    <small>(e.g. high cholesterol, anaemia, malaria, HIV or systemic lupus erythematosus.) </small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="urinary_or_reproductive" id="urinary_or_reproductive" value="urinary_or_reproductive" value="Yes" checked={this.state.urinary_or_reproductive === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'urinary_or_reproductive')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="urinary_or_reproductive">Urinary or reproductive disorders<br/>
                                    <small>(e.g. urinary tract infections, kidney problems, fibroids, painful, irregular or heavy periods, fertility problems, polycystic ovarian syndrome, endometriosis, testicular or prostate problems.) </small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="anxiety" id="anxiety" value="anxiety" value="Yes" checked={this.state.anxiety === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'anxiety')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="anxiety">Anxiety, depression, psychiatric or mental health issues including eating disorders, post-traumatic stress disorder, alcohol or drug issues.<br/>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="ear_nose_throat" id="ear_nose_throat" value="ear_nose_throat" value="Yes" checked={this.state.ear_nose_throat === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'ear_nose_throat')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="ear_nose_throat">Ear, nose, throat, eye or dental problems<br/>
                                    <small>(e.g. ear infections, sinus problems, tonsils and adenoids, cataracts, glaucoma, wisdom teeth problems.) </small>
                                </label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="neurological_none_of_above" id="neurological_none_of_above" value="neurological_none_of_above" value="Yes" checked={this.state.neurological_none_of_above === 'Yes'} onClick={this.handleChangeMedicalRadio.bind(this,'neurological_none_of_above')} onChange={this.handleChangeRadio}></input>
                                <label htmlFor="neurological_none_of_above">None of the above<br/>
                                    <small> &nbsp;&nbsp; </small>
                                </label>
                            </div>
                            <NextButton btntext="Next"/>
                        </form>
                    </div>
                    <div className="inner"></div>
                </div>
            </Fragment>
        );
    }
} 