import React, { Component} from 'react';
export default class secondSection extends Component {
    render()
    {
      
        return(
        <section className="main-section">
            <div className="container">
                <div className="text-container">
                  <h2 className="with-underline">Bloggers</h2>
                  <h4>Why partner with Xpat Coverage? </h4>
                  <p>Partnering with Xpat coverage allows you to refer your international travelers and expatriates so they get a top notch medical insurance plan while living abroad. You get paid for each traveler that signs up and qualifies for a quote!</p>
                  <a href="/create-account/1" className="btn btn-brand">Sign Up</a>
                </div>
                <div className="video no-bg">
                  <img className="img-responsive" src="assets/images/bloggers-right-image.png" alt="" />
                </div>
                <div className="clearfix"></div>
            </div>
  
          </section>
      
        );
    }
}