import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import NextButton from '../../../../component/NextButton';
import { handleChange, BootstrapTooltip, handleBlur } from '../../../../../common_function/common_function';
import SimpleReactValidator from 'simple-react-validator';
import BackButton from '../../../../component/BackButton';
import axios from 'axios';
export default class XPQ1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'first_name': '',
            'last_name': '',
            'redirectToReferrer': false,
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = handleChange.bind(this);
        this.handleBlur = handleBlur.bind(this);
    }
    componentDidMount() {
        const prev = this.props.questionNum-1;
        this.setState({prevQuestion : prev});
        // this.props.functionPrev.handleUpdatePrevious(prev);
        const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
        if (localStorageData && localStorageData["1"]) {
            let first_name = localStorageData["1"].first_name;
            let last_name = localStorageData["1"].last_name;
            this.setState({ first_name: first_name });
            this.setState({ last_name: last_name });
        }

    const quoateId = this.props.pendingQuote;
     if(quoateId){
        var base_url = process.env.REACT_APP_API_URL;
       const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));

        axios({
            method: "post",
            url: base_url+'leads/get_xpat_quote_ajax/'+quoateId,
            headers: { "content-type": "application/json" },
        })
        .then(result => {
            var josan_data = result.data[0].form_json_array;
            
            localStorage.setItem('pending_xpat_id', result.data[0].id);
            localStorage.setItem('xpatquote', josan_data);
            this.setState({first_name : result.data[0].first_name});
            this.setState({last_name : result.data[0].last_name});
       
        })
        .catch(error => this.setState({ error: error.message }));
       }
    }
    handleEvent = (event) => {
        this.setState({first_name : event});
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            var forms_info = { 
                'first_name': this.state.first_name,
                'last_name': this.state.last_name
            };
            if(localStorage.getItem('xpatquote')){
                let localStorageData = JSON.parse(localStorage.getItem('xpatquote'));
                localStorageData[this.props.questionNum] = forms_info;
                localStorageData.current_step = parseInt(this.props.questionNum)+1;
                localStorageData = JSON.stringify(localStorageData);
                localStorage.setItem('xpatquote', localStorageData);
            }
            else
            {
                var url_forms_info = { 'current_step': 2 ,'1': forms_info };
                url_forms_info = JSON.stringify(url_forms_info);
                localStorage.setItem('xpatquote', url_forms_info);
            }
            this.setState({ redirectToReferrer: true });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (this.state.redirectToReferrer){
            const next = parseInt(this.props.questionNum)+parseInt(1);
            return (<Redirect to={"/xpatquote/"+next} />)
        }
        return (
            <div className="quote-page">
                <BackButton lsKey="xpatquote" prevQuestion={this.props.questionNum} totalQuestion={this.props.totalQuestion} />
                <div className="inner">
                    <h2 className="main">Hi, it's nice to meet you! What's your name?</h2>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            {this.validator.message('first_name', this.state.first_name, 'required')}
                            {this.validator.message('last_name', this.state.last_name, 'required')}
                        </div>
                        <div className="small-width-container">
                            <div className="form-group small-width">
                                <label>First Name</label>
                                <input type="text" className="form-control" placeholder="First Name" name="first_name" value={this.state.first_name} onChange={this.handleChange} autoFocus={true} onBlur={this.handleBlur}/>
                            </div>
                            <div className="form-group small-width">
                                <label>Last Name</label>
                                <input type="text" className="form-control" placeholder="Last Name" name="last_name" value={this.state.last_name} onChange={this.handleChange} onBlur={this.handleBlur}/>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <NextButton btntext="Next"/>
                        <div className="space_section"></div>
                    </form>
                </div>
            </div>
        );
    }
}