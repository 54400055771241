import React, { Component} from 'react';
export default class Helping extends Component {
    render()
    {
        var handImage = {
            backgroundImage: 'url(assets/images/helping-hand.png)',
        };
        return(
            <section className="helping">
                <div className="left">
                {/* style="background-image: url(assets/images/helping-hand.png);" */}
                    <div className="image" style={handImage}></div>
                </div>
                <div className="right">
                    <h1>Our helping hand</h1>
                    <p>Home is behind, the world ahead and there are many paths to tread through shadows to the edge.</p>
                    <ul>
                        <li>
                            <img src="assets/images/logo-chariry1.png" alt=""></img>
                        </li>
                        <li>
                            <img src="assets/images/logo-chariry2.png" alt=""></img>
                        </li>
                        <li>
                            <img src="assets/images/logo-chariry3.png" alt=""></img>
                        </li>
                    </ul>
                </div>
                <div className="clearfix"></div>
            </section>
        );
    }
}