import React, { Component} from 'react';
export default class working extends Component {
    render()
    {
        var handImage = {
            backgroundImage: 'url(assets/images/faq-help.png)',
        };
        return(
          <section className="goal">
          <h2>How it works?</h2>
          <div className="goal-row">
            <div className="goal-col">
              <div className="icon">
                <img src="assets/images/icons/icon-heart.svg" alt="" />
              </div>
              <div className="text">
                <h4>Easy Quote Process</h4>
                <p>Send customers your referral link or quote them easily in your portal</p>
              </div>
            </div> 

            <div className="goal-col">
              <div className="icon">
                <img src="assets/images/icons/icon-heart.svg" alt="" />
              </div>
              <div className="text">
                <h4>Partner Portal</h4>
                <p>Track your quotes, sales, & commissions in one place</p>
              </div>
            </div> 

            <div className="goal-col">
              <div className="icon">
                <img src="assets/images/icons/icon-heart.svg" alt="" />
              </div>
              <div className="text">
                <h4>Get paid monthly</h4>
                <p>You get $20 for every traveler that receives a quote </p>
              </div>
            </div> 
            <div className="clearfix"></div>
          </div>
        </section>               
        );
    }
}