import React, { Fragment, useState } from 'react';
import HeaderQuestion from '../../common/component/HeaderQuestion';
import { Redirect } from 'react-router-dom';
import XPQ1 from '../pages/ode/component/questions/XPQ1';
import XPQ2 from '../pages/ode/component/questions/XPQ2';
import XPQ3 from '../pages/ode/component/questions/XPQ3';
import XPQ3A from '../pages/ode/component/questions/XPQ3A';
import XPQ4 from '../pages/ode/component/questions/XPQ4';
import XPQ5 from '../pages/ode/component/questions/XPQ5';
import XPQ6 from '../pages/ode/component/questions/XPQ6';
import XPQ7 from '../pages/ode/component/questions/XPQ7';
import XPQ8 from '../pages/ode/component/questions/XPQ8';
import XPQ9 from '../pages/ode/component/questions/XPQ9';
import XPQ10 from '../pages/ode/component/questions/XPQ10';
import XPQ11 from '../pages/ode/component/questions/XPQ11';
import XPQ12 from '../pages/ode/component/questions/XPQ12';
import XPQ13 from '../pages/ode/component/questions/XPQ13';
import XPQ14 from '../pages/ode/component/questions/XPQ14';

import XPQ15 from '../pages/ode/component/questions/XPQ15';
import XPQ15A from '../pages/ode/component/questions/XPQ15A';

import XPQ16 from '../pages/ode/component/questions/XPQ16';
import XPQ16A from '../pages/ode/component/questions/XPQ16A';
import XPQ17 from '../pages/ode/component/questions/XPQ17';
import XPQE from '../pages/ode/component/questions/XPQE';

import Thanks from '../../common/component/Thanks';
import Loader from '../../../loader/Loader';

function QuestionContainerODE(props) {

	let number = props.match.params.num;
	let pendingQuoteId = props.match.params.genreId;
	const [loader, setLoader] = useState(false);
	const handleUpdateLoader = (ajaxState) =>{
		setLoader(ajaxState);
	}
	
	
	// const [prev, setPrev] = useState(0);
	const localStorageData = JSON.parse(localStorage.getItem('xpatquote'));

	
	// const handleUpdatePrevious = (number) =>{
	// 	setPrev(number);
	// }
	// const progressArray = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1];
	
	if(number !== '1' && !localStorageData && number!== 'thanks')
		return (<Redirect to={"/xpatquote/1"} />)
	let component = <XPQ1 questionNum={number} pendingQuote={pendingQuoteId} totalQuestion="18" />;
	if(number == 'thanks')
		component=<Thanks></Thanks>
	if(number === '1')
		component = <XPQ1 questionNum={number} pendingQuote={pendingQuoteId} totalQuestion="18" />;
	else if(number === '2')
		component = <XPQ2 questionNum={number} totalQuestion="18"  />;
	else if(number === '3')
		component = <XPQ3 questionNum={number} totalQuestion="18" />;
	else if(number === '4')
		component = <XPQ3A questionNum={number} totalQuestion="18"/>;
	else if(number === '5')
		component = <XPQ4 questionNum={number} totalQuestion="18"/>;
	else if(number === '6')
		component = <XPQ5 questionNum={number} totalQuestion="18"/>;
	else if(number === '7')
		component = <XPQ6 questionNum={number} totalQuestion="18"/>;
	else if(number === '8')
		component = <XPQ7 questionNum={number} totalQuestion="18"/>;	
	else if(number === '9')
		component = <XPQ8 questionNum={number} totalQuestion="18"/>;
	else if(number === '10')
		component = <XPQ9 questionNum={number} totalQuestion="18"/>;
	else if(number === '11')
		component = <XPQ10 questionNum={number} totalQuestion="18"/>;
	else if(number === '12')
		component = <XPQ11 questionNum={number} totalQuestion="18"/>;
	else if(number === '13')
		component = <XPQ12 questionNum={number} totalQuestion="18"/>;
	else if(number === '14')
		component = <XPQ13 questionNum={number} totalQuestion="18"/>;
	else if(number === '15')
	{
		if(localStorageData['12'].asthma_allergies === 'No' && localStorageData['12'].cancer_tumours === 'No' &&
            localStorageData['12'].diabetes_endocrine === 'No' && localStorageData['12'].gall_bladder === 'No' &&
            localStorageData['12'].heart_or_circulatory === 'No' && localStorageData['12'].muscle_skeletal === 'No' &&
            localStorageData['13'].anxiety === 'No' && localStorageData['13'].blood_infective === 'No' &&
            localStorageData['13'].ear_nose_throat === 'No' && localStorageData['13'].neurological === 'No' &&
			localStorageData['13'].skin_problems === 'No' && localStorageData['13'].urinary_or_reproductive === 'No' &&
			localStorageData['14'].any_one_have_illness === 'No' && localStorageData['14'].take_mediction === 'No'
		)
			component = <XPQ15 questionNum={number}  totalQuestion="18" functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;
		else
		    component = <XPQ14 questionNum={number} totalQuestion="18" functionLoader={{ handleUpdateLoader:handleUpdateLoader  }}/>;
	}

	// else if(number === '16')
	// {
	// 	component = <XPQ15A questionNum={number} totalQuestion="18"/>;
	// }
	else if(number === '16')
	{
		if(localStorageData['12'].asthma_allergies === 'No' && localStorageData['12'].cancer_tumours === 'No' &&
            localStorageData['12'].diabetes_endocrine === 'No' && localStorageData['12'].gall_bladder === 'No' &&
            localStorageData['12'].heart_or_circulatory === 'No' && localStorageData['12'].muscle_skeletal === 'No' &&
            localStorageData['13'].anxiety === 'No' && localStorageData['13'].blood_infective === 'No' &&
            localStorageData['13'].ear_nose_throat === 'No' && localStorageData['13'].neurological === 'No' &&
			localStorageData['13'].skin_problems === 'No' && localStorageData['13'].urinary_or_reproductive === 'No' &&
			localStorageData['14'].any_one_have_illness === 'No' && localStorageData['14'].take_mediction === 'No'
		)
			component = <XPQ15A questionNum={number} totalQuestion="18" functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;
		else
			component = <XPQ15 questionNum={number} totalQuestion="18"  functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;

	}
	else if(number === '17')
	{
		if(localStorageData['12'].asthma_allergies === 'No' && localStorageData['12'].cancer_tumours === 'No' &&
            localStorageData['12'].diabetes_endocrine === 'No' && localStorageData['12'].gall_bladder === 'No' &&
            localStorageData['12'].heart_or_circulatory === 'No' && localStorageData['12'].muscle_skeletal === 'No' &&
            localStorageData['13'].anxiety === 'No' && localStorageData['13'].blood_infective === 'No' &&
            localStorageData['13'].ear_nose_throat === 'No' && localStorageData['13'].neurological === 'No' &&
			localStorageData['13'].skin_problems === 'No' && localStorageData['13'].urinary_or_reproductive === 'No' &&
			localStorageData['14'].any_one_have_illness === 'No' && localStorageData['14'].take_mediction === 'No'
		)
			component = <XPQ17 questionNum={number} totalQuestion="18" functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;

		else
			component = <XPQ15A questionNum={number} totalQuestion="18"  functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;

	}
	else if(number === '18')
	{
		component = <XPQ17 questionNum={number} totalQuestion="18" functionLoader={{ handleUpdateLoader:handleUpdateLoader  }} />;
		
	}
	// else if(number === '19')
	// 	component = <XPQ17 questionNum={number} totalQuestion="18" functionLoader={{handleUpdateLoader:handleUpdateLoader }} />;
	if(localStorageData && localStorageData.current_step && number > localStorageData.current_step){
		return (<Redirect to={"/xpatquote/"+localStorageData.current_step} />)	
	}
	return (
		<Fragment>
				<Loader isLoading={loader}></Loader>
			<div className="wrapper">
				<div className="container">
					<HeaderQuestion prevQuestion={number} url="/xpatquote/" pendingQuote={pendingQuoteId} lsKey="xpatquote" functionLoader={{handleUpdateLoader:handleUpdateLoader}} ></HeaderQuestion>
					{component}
				</div>
			</div>
		</Fragment>
	);
}
export default QuestionContainerODE;